<template>
  <v-app-bar
      
        color="primary"
        prominent
        scroll-behavior="fade-image"
       
      >
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title style="text-align: left;">{{ DadosCT.nome }}</v-toolbar-title>
        <v-toolbar-title style="text-align: left;">
          
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu>
           


            <template v-slot:activator="{ props }">
              <v-badge :content="comunicados.length" color="error">
                <v-icon v-bind="props">mdi-bell-outline</v-icon>
              </v-badge>
            </template> 
            <v-list>
              <v-list-item
                v-for="(item, i) in comunicados"
                :key="i"
              >
                <v-list-item-title @click="entrarComunicado(item)" style="cursor: pointer">
                  <v-alert color="info">
                    {{item.mensagem}}
                  </v-alert>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        <!-- <v-btn variant="text" icon="mdi-dots-vertical" v-on:click="this.$router.push('perfil')"></v-btn> -->
          <div class="fotoProfessorPerfil" v-on:click="this.$router.push('mestre')" style="cursor: pointer">
            <img v-bind:src="professor.foto" />
          </div>
         <div class="nomeProfessor" v-on:click="this.$router.push('mestre')" style="cursor: pointer">{{ professor.nome }}</div> 
      </v-app-bar>

      <v-navigation-drawer
      v-model="drawer"
        permanent
        theme="dark"
      >
        <v-list nav>
          <v-list-item style="text-align: left;" prepend-icon="mdi-finance" title="Estatisticas" value="home" v-on:click="this.$router.push('estatisticas')"></v-list-item>
          <v-list-item style="text-align: left;" prepend-icon="mdi-card-account-details" title="Academia" value="perfil" v-on:click="this.$router.push('perfil')" :disabled="professor.adm == 0 ? true : false"></v-list-item>
          <v-list-item style="text-align: left;" prepend-icon="mdi-account" title="Mestre" value="mestre" v-on:click="this.$router.push('mestre')"></v-list-item>
          <v-list-item style="text-align: left;" prepend-icon="mdi-account-group" title="Alunos" value="alunos" v-on:click="this.$router.push('alunos')"></v-list-item>
          <v-list-item style="text-align: left;" prepend-icon="mdi-cash" title="" value="contas" v-on:click="this.$router.push('contas')">
            Faturamento <v-icon color="warning">mdi-new-box</v-icon>
          </v-list-item>
          <v-list-item style="text-align: left;" prepend-icon="mdi-calendar" title="Eventos" value="eventos" v-on:click="this.$router.push('eventos')"></v-list-item>
          <v-list-item style="text-align: left;" prepend-icon="mdi-karate" title="Aulas" value="aulas" v-on:click="this.$router.push('aulas')"></v-list-item>
          <v-list-item style="text-align: left;" prepend-icon="mdi-account-group" title="Experimental" value="experimentais" v-on:click="this.$router.push('experimentais')"></v-list-item>
          <v-list-item style="text-align: left;" prepend-icon="mdi-check-all" title="CheckIns" value="checkins" v-on:click="this.$router.push('checkins')"></v-list-item>
          <v-list-item style="text-align: left;" prepend-icon="mdi-video-account" title="Videos" value="videos" v-on:click="this.$router.push('videos')"></v-list-item>
          <v-list-item style="text-align: left;" prepend-icon="mdi-cart" title="Loja" value="loja" v-on:click="this.$router.push('loja')"></v-list-item>
        </v-list>
        
        <template v-slot:append>
          <h4>PlayList MestreDOJO</h4>
          <iframe title="deezer-widget" src="https://widget.deezer.com/widget/dark/playlist/11209971344?tracklist=false" width="210" height="150" frameborder="0" allowtransparency="true" allow="encrypted-media; clipboard-write"></iframe>
          <div class="pa-2">
            <v-btn block v-on:click="sair()">
              sair
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    <Home v-if="paginaAtual == 'Home'" />
    <Alunos msg="Alunos" v-if="paginaAtual == 'Alunos'" />
    
</template>
  
<script>

//import Home from '../Pages/Estatisticas.vue'
import Alunos from '../Pages/Alunos.vue'
import {func} from '../assets/functions.js';

  export default {
    name: 'SideBar',
    components: {
        Alunos,
    },
    props: {
      msg: String
    },
    data() {
        return {
          comunicados: [],
            paginaAtual: null,
            drawer: true,
            DadosCT: {
              logo: "",
              nome: ""
            },
            rail: true,
            professor: {
              nome: null,
              pix: null,
              foto: null,
              celular: null,
              materias: [],
              linkAlunos: null,
              tokenPublico: null,
              linkAlunos: null, 
              adm: 0
            }
        }
    },
    created: function () {
      func.CarregarDados('academias/getAcademia').then(data => this.carregaDadosCT(data));
      
      this.carregarComunicados();  
    },
    methods: {
      carregarDadosProfessor(dados){  
        this.professor = dados;
        if(this.professor.foto == null){
          this.professor.foto = 'https://d3c1e34umh9o6d.cloudfront.net/image/filename/789691/md_Screenshot_3.png'
        }
      },
      carregaDadosCT(data){
        this.DadosCT = data.corpo
        func.CarregarDados('professor/getProfessor').then(data => this.carregarDadosProfessor(data.corpo) );
      },
      sair(){
        localStorage.removeItem('DojoMasterToken');
        localStorage.removeItem('DojoMasterProfessor');
        
        window.location.replace("/login");
      },
      carregarComunicados(){
        func.CarregarDados('academias/getComunicados').then(data => this.comunicados = data.corpo);
      },
      entrarComunicado(item){
        var post = {
          idComunicado: item.id
        }
        func.CarregarDados('academias/vistoComunicados', post).then(data => this.carregarComunicados());
        this.$router.push(item.url)
      }
    }
  }

</script>


  