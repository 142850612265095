<template>
  
</template>
  
<script>


import {func} from '../assets/functions.js';

  export default {
    name: 'Pagamento',
    components:{ 
      
    },
    props: {
      
    },
    async created(){
      
    },
    data() {
      return {
        
      }
    },
    mounted: function () {
      alert()
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://sdk.mercadopago.com/js/v2')
      document.head.appendChild(recaptchaScript);
      this.pagar();
    },
    methods: {
      pagar(){
        func.CarregarDados('jobs/create_preference').then(data => 
          this.irPaginaPagamento(data)
        );
      },
      async irPaginaPagamento(data){
        window.location.assign(data.id.init_point);
      }
    }
  }

</script>
  

  