<template>
  <v-btn block color="primary" @click="aprovarComprovante()" v-if="dadosPagamento.filter(x => x.aprovado == 0).length > 0"><b>Aprovar</b></v-btn>
    <h3 v-if="dadosPagamento.filter(x => x.aprovado == 0).length == 0" style="margin-top: 20px"><b>COMPROVANTE JÁ APROVADO</b></h3>

  
  
  <div style=" width: 100%; padding: 20px" v-for="item in dadosPagamento" :key="item.idpagamento">
    <h3 v-if="item.aprovado == 1" style="margin-top: 20px"><b>COMPROVANTE JÁ APROVADO</b></h3>

    <h4  style="margin-top: 20px"><b>{{item.modalidade}} - Mês {{item.mes}}</b></h4>
    <img v-if="item.pdf == false"   :src="item.comprovanteConvertido" v-bind:style="item.aprovado == 1  ? 'width: 100%; padding: 10px; margin: auto; opacity: 0.2' : 'width: 100%; padding: 10px; margin: auto; opacity: 1;box-shadow: 2px 2px 2px 2px #ccc' "/> 
    <vue-pdf-embed v-if="item.pdf == true" :source="item.comprovanteConvertido" />
  </div>
    

</template>
  
<script>


import {func} from '../assets/functions.js';
import VuePdfEmbed from 'vue-pdf-embed'
  export default {
    name: 'verComprovante',
    components:{ 
        VuePdfEmbed
    },
    props: {
      
    },
    async created(){
      this.carregarComprovante();
    },
    data() {
      return {
        comprovante: [],
        pdf: false,
        token: null,
        aprovado: [],
        dadosPagamento: [],
        clicouAprovar: 0
      }
    },
    methods: {  
      carregarComprovante(){
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('comprovante');
        const myParam2 = urlParams.get('token');
        this.token = myParam;
        var post = {
          comprovante: myParam,
          tokenprofessor: myParam2
        }
        func.CarregarDados('alunos/verComprovante', post).then(data => 
          this.carregarVariaveisComprovante(data)
        )
      },
      async carregarVariaveisComprovante(dados){
        if(dados.sucesso){
            for(var comprovante in dados.corpo) {
                var imagem = dados.corpo[comprovante].comprovante;
                if(dados.corpo[comprovante].comprovante.includes('octet-stream')){
                  imagem = dados.corpo[comprovante].comprovante.replace('application/octet-stream', 'application/pdf');
                  dados.corpo[comprovante]['pdf'] = true;
                }
                else{
                  dados.corpo[comprovante]['pdf'] = false;
                }
                dados.corpo[comprovante]['comprovanteConvertido'] = imagem;
                this.dadosPagamento.push(dados.corpo[comprovante]);

                //this.comprovante.push(imagem);
                //this.aprovado.push(dados.corpo[comprovante].aprovado);
            }

         


        }
      },
      aprovarComprovante(){
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('comprovante');
        const myParam2 = urlParams.get('token');
        var comprovantes = []


        for(var comprovante in this.dadosPagamento) {
          comprovantes.push(this.dadosPagamento[comprovante].idpagamento)
        }

        var post = {
          idpagamento: comprovantes
        }
        func.CarregarDados('alunos/aprovarComprovante', post).then(data => 
          this.dadosPagamento = []
        )
      }
    }
  }

</script>
  

  