<template>
  <v-snackbar
      v-model="snackVisivel"
    >
      {{snackMensagem}}
      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="snackVisivel = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>


    <tr v-bind:style="objetoAluno.status.id == 1 ? 'opacity: 1;' : 'opacity: 0.2;' ">
        <td>
            <div :style="{'background-image':'url('+objetoAluno.foto+')'}" class="divAvatar"></div>
        </td>
        <td>{{ objetoAluno.matricula }}</td>
        <td nowrap="true">{{ objetoAluno.nome }}</td>
        <td>
         <span v-if="objetoAluno.tipoAluno == 0">
            {{ objetoAluno.diaPagamento }}
          </span>
          <span v-if="objetoAluno.tipoAluno == 1">
             <img width="20" src="https://firebasestorage.googleapis.com/v0/b/lutas-984a9.appspot.com/o/gympass-logo.png?alt=media&token=a762c13d-9960-43d8-9f2d-e4ea091cfc51" />
          </span>
          <span v-if="objetoAluno.tipoAluno == 2">
             <img width="30" src="https://firebasestorage.googleapis.com/v0/b/lutas-984a9.appspot.com/o/staff.png?alt=media&token=40169c7a-85e7-4daf-986e-5dc75bc90c80" />
          </span>
          <span v-if="objetoAluno.tipoAluno == 3">
             <img width="30" src="https://firebasestorage.googleapis.com/v0/b/lutas-984a9.appspot.com/o/totalpass.png?alt=media&token=1f19e062-5112-4b67-95de-d6a9185b194c" />
          </span>
        </td>


        <td>
          <div v-if="objetoAluno.tipoAluno == 0">
                <span v-for="item in objetoAluno.pagamentos.filter(x => x.status != 3 && x.status != null)"   v-bind:style="item.status == 0  ? 'color: red;font-weight: 400' : 'color: black;' ">
                  <div> 
                    <!-- {{item.materia}}  {{"("+ item.nome + ")"}}  -->
                  <span v-if="item.status == 0">
                    <span v-if="item.informadoPeloWhats > 0">
                        <v-icon
                          size="small"
                          color="primary"
                          icon="mdi-chat-processing"
                        >
                        </v-icon>
                        <v-tooltip
                          activator="parent"
                          location="start"
                        >Aluno informado via WhatsApp</v-tooltip>
                    </span>
                    <span v-if="item.informadoPeloWhats == 0">
                        <v-icon
                          size="small"
                          color="primary"
                          icon="mdi-chat-remove"
                        >
                        </v-icon>
                        <v-tooltip
                          activator="parent"
                          location="start"
                        >Aluno não informado via WhatsApp</v-tooltip>
                    </span>
                    <span v-if="item.informadoPeloWhats == null">
                        <v-icon
                          size="small"
                          color="alert"
                          icon="mdi-chat-question"
                        >
                        </v-icon>
                        <v-tooltip
                          activator="parent"
                          location="start"
                        >Informações sobre cobranças disponiveis após o primeiro pagamento do aluno</v-tooltip>
                    </span>
                  </span> 
                <span v-if="item.status > 0">
                  <span v-if="item.informadoPeloWhats > 0">
                      <v-icon
                        size="small"
                        color="success"
                        icon="mdi-chat-processing"
                      >
                      </v-icon>
                      <v-tooltip
                        activator="parent"
                        location="start"
                      >Aluno regularizado após cobrança via WhatsApp</v-tooltip>
                  </span>
                </span>
                </div>
              </span>
          </div>
          <div v-if="objetoAluno.tipoAluno > 0">
            <span v-for="item in objetoAluno.pagamentos">
              <!-- <div>{{item.materia}}</div> -->
            </span>
            
          </div>
         
          



        </td>
        <td>

         <v-chip v-for="materia in objetoAluno.materias" :style="{ backgroundColor: materia.corFundo, color: materia.corLetra }" v-show="materia.imagemURL == null || materia.imagemURL == ''">
            {{ materia.nome }} 
            <span v-if="objetoAluno.pagamentos.filter(x => x.idMateria == materia.idMateria).length > 0 && objetoAluno.tipoAluno == 0">&nbsp;- <b>{{objetoAluno.pagamentos.filter(x => x.idMateria == materia.idMateria)[0].nome}}</b></span>
          </v-chip>

          <v-chip v-for="materia in objetoAluno.materias" v-show="materia.imagemURL != null && materia.imagemURL != ''">
            <div  style="width: 100%">
              <img style="width: 40px;height: 20px; vertical-align: middle;" v-bind:src="materia.imagemURL" />
            </div>
            {{ materia.nome }} 
            <span v-if="objetoAluno.pagamentos.filter(x => x.idMateria == materia.idMateria).length > 0 && objetoAluno.tipoAluno == 0">&nbsp;- <b>{{objetoAluno.pagamentos.filter(x => x.idMateria == materia.idMateria)[0].nome}}</b></span>
          </v-chip>
        </td>
        <td>
          <v-btn
              v-if="objetoAluno.tipoAluno == 0"
              color="purple-lighten-2"
              variant="outlined"
              @click="carregarPagamentosAluno(objetoAluno.id, objetoAluno.materias)"
            >
              Ver
          </v-btn>
        </td>
        <td>
          <v-btn
            color="purple-lighten-2"
            variant="outlined"
           
            @click="dialogCardALuno = true; carregarEdicao(this) "
          >
            Editar
          </v-btn>
        </td>
      </tr>


      <v-dialog
      v-model="dialogConfirm"
      width="400"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Regularizar Manualmente</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                Deseja regularizar a situação do aluno {{ alunoRegularizar.nome }}
              </v-col>
            </v-row>
              
          </v-container>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogConfirm = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="regularizar()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="dialogAprovarPagamento"
      width="400"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Aprovar pagamento</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                Deseja aprovar o pagamento de {{ alunoAprovar.nome }}
              </v-col>
            </v-row>
              
          </v-container>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogAprovarPagamento = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="aprovarDeFato()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>



    <v-dialog
      v-model="dialogCardPagamentos"
      width="900"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Ultimos pagamentos</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
            <div v-for="materia in materiasProfessor" :key="materia.id">
              <br />
              <br />
              <div style="text-align: center">{{materia.nome}}</div>
              <span class="text-h5">
                <span class="text-h5" style="font-size: 15px !important">Adicione um pagamento manualmente:</span>
                <v-btn
                  v-if="materia.pagamentos.length == 0 || (new Date(materia.pagamentos[0].dataReferencia).getMonth() < new Date().getMonth() || new Date(materia.pagamentos[0].dataReferencia).getFullYear() < new Date().getFullYear())"
                  color="primary"
                  variant="text"
                  @click="regularizar(materia.id, true)"
                >
                  Mês atual
                </v-btn>
                 
                <v-btn
                  v-if="materia.pagamentos.length == 0 || (new Date(materia.pagamentos[0].dataReferencia).getMonth() < new Date().getMonth() || new Date(materia.pagamentos[0].dataReferencia).getFullYear() < new Date().getFullYear())"
                  color="primary"
                  variant="text"
                  @click="regularizar(materia.id, false)"
                >
                  Mês anterior
                </v-btn>
              </span>
              <br />
              <v-table density="compact" >
                <thead>
                  <tr>
                    <th class="text-left">
                      Mês referencia
                    </th>
                    <th class="text-left">
                      Ano referencia
                    </th>
                    <th class="text-left">
                      Data de pagamento
                    </th>
                    <th class="text-left">
                      Aprovado
                    </th>
                    <th class="text-left">
                      Comprovante
                    </th>
                    <th class="text-left">
                      Aprovar
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in materia.pagamentos"
                    :key="item.mes"
                  >
                    
                    <td>{{ new Date(item.dataReferencia).getMonth() + 1 }}</td>
                    <td>{{ new Date(item.dataReferencia).getFullYear() }}</td>
                    <td>{{ formatarDatas(item.dataPagamento) }}</td>
                    <td>
                      <span>{{ item.aprovado == 1 ? 'Aprovado' : '--'}}</span>
                    </td>
                    <td>
                      <a v-if="item.comprovante != null && item.aprovado == 0" :download="objetoAluno.nome" :href="item.comprovante">download</a>
                      <span v-if="item.comprovante == null && item.aprovado == 0 && item.pagamentoDeMatricula == 0">Pagamento informado</span>
                      <span v-if="item.pagamentoDeMatricula == 1">Pagamento na Matricula</span>
                    </td>
                    <td>
                      <span v-if="item.aprovado == 1">Regularizado</span>
                      <span v-if="item.aprovado == 0">
                        <v-btn
                          color="primary"
                          variant="text"
                          @click="aprovarDeFato(item.id)"
                        >
                          Aprovar
                        </v-btn>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
              
            </v-row>
              
          </v-container>
          <v-card-actions>
          <v-spacer></v-spacer>

        </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>


<v-dialog
      v-model="dialogExcluirAluno"
      width="auto"
    >
      <v-card>
        <v-card-text>
          Tem certeza que deseja excluir o aluno: {{nomeAlunoExcluir}} e todo seu histórico ?
        </v-card-text>
         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="dialogExcluirAluno = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="excluirAlunoDefinitivo()"
          >
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogCardALuno"
      width="800"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogInfos.nome }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Matricula: {{ dialogInfos.matricula }} - Cadastro em: <label>{{ dialogInfos.dataMatricula.slice(0, 10) }}</label> 
                <v-btn color="primary" variant="plan" @click="dialogExcluirAluno = true; idExcluirAluno = dialogInfos.id; nomeAlunoExcluir = dialogInfos.nome">Excluir</v-btn>
                <hr />
              </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Pessoal
                <hr />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <div class="logoAcademiaPerfil">
                <img v-bind:src="dialogInfos.foto" />
                <input type="file" @change="funcaoUploadImage" />
              </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nome completo"
                  v-model="dialogInfos.nome"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                v-if="dialogInfos.responsavel != null && dialogInfos.responsavel != ''"
                  label="Responsável"
                  v-model="dialogInfos.responsavel"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Data de nascimento"
                  v-model="dialogInfos.dataNascimento"
                  hint=""
                  v-mask="'##/##/####'"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Celular"
                  hint=""
                  v-model="dialogInfos.celular"
                  persistent-hint
                  required
                  v-mask="'(##) #####-####'"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12">
                <v-text-field
                  label="CPF"
                  v-model="dialogInfos.cpf"
                  v-mask="'###.###.###-##'"
                  required
                ></v-text-field>
              </v-col> -->
              <v-col cols="9">
                <v-text-field
                  label="Email"
                  v-model="dialogInfos.email"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  :items="sexo"
                  label="Sexo"
                  v-model="dialogInfos.sexo"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >

            <!-- <v-data-table
              hide-default-footer
              disable-pagination
              :headers="headers"
              :items="materiasDoAluno"
              :sort-by="[{ key: 'nome', order: 'asc' }]"
              class="elevation-1"
              > -->
    <!-- <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Modalidades</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="props"
            >
              Nova Modalidade
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                  <v-autocomplete
                            :items="materiasAcademia"
                            v-model="editedItem.nome"
                            item-title="nome"
                            item-value="nome"
                            label="Modalidade"
                            :disabled="editedIndex < 0 ? false: true"
                          ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                  <v-autocomplete
                    :items="niveisFiltrados"
                    v-model="editedItem.nivel"
                    item-title="nome"
                    item-value="nivel"
                    label="Nivel"
                    :disabled="editedIndex < 0 ? true: false"
                  ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="close"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="save"
              >
                Inserir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Você deseja realmente excluir esta modalidade ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template> -->
    <!-- <template v-slot:item.actions="{ item }">
      <v-icon
        size="small"
        class="me-2"
        @click="editItem(item.raw)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        size="small"
        @click="deleteItem(item.raw)"
      >
        mdi-delete
      </v-icon>
    </template> -->



  <!-- </v-data-table> -->


 <v-dialog
      v-model="dialogNovaModalidade"
      width="300"
    >
      <v-card>
        <v-card-text>
          <v-autocomplete
              :items="materiasProfessor"
              v-model="materiaAlunoAdicionar"
              item-title="nome"
              item-value="id"
              label="Modalidade"
              :disabled="editedIndex < 0 ? false: true"
            ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="addAsModalidadesDoAluno()">Adicionar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  <table class="table table-striped table-bordered" style="width: 100%">
      <thead>
        <tr>
          <th scope="col" class="text-left">Modalidades</th>
          <th scope="col" class="text-left"></th>
          <th scope="col" class="text-left">
             <v-btn
              color="primary"
              variant="text"
              v-on:click="addModalidade()"
            >
              Nova Modalidade
            </v-btn>
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th scope="col" class="text-left">Modalidade</th>
          <th scope="col" class="text-left">Nivel</th>
          <th scope="col" class="text-left"></th>
          
        </tr>
      </thead>
      <tbody>
        <tr v-for="nivel in modalidadesDoAluno" :key="nivel.id" >
          <td><span>{{nivel.nome}}</span></td>
          <td>
            <select class="form-control" v-model="nivel.idNivel" v-on:change="setAndSortData(nivel)" v-if="modalidadesProfessor.filter(x => x.idMateria == nivel.idMateria).length > 0">
              <option v-for="nivel2 in modalidadesProfessor.filter(x => x.idMateria == nivel.idMateria)" :key="nivel2.id" :value="nivel2.idNivel">{{nivel2.nivel}}</option>
            </select>
          </td>
          <td>
              <v-btn
               v-if="modalidadesProfessor.filter(x => x.idMateria == nivel.idMateria).length > 0"
               color="primary"
               v-on:click="deletarModalidade(nivel)"
               variant="plain"
            >
              Excluir
            </v-btn>
          </td>
        </tr>
      </tbody>
    </table>

              </v-col>
              <v-col 
                  cols="3" 
                  sm="3"
                >
                <v-autocomplete
                  :items="diasPagamento"
                  label="Dia de pagamento"
                  v-model="dialogInfos.diaPagamento"
                ></v-autocomplete>
              </v-col>
              <v-col 
                  cols="12" 
                  sm="12"
                >
                <v-autocomplete
                  :items="statusAluno"
                  label="Status"
                  v-model="dialogInfos.status"
                  item-title="nome"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Endereço (Opcional)
                <hr />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="CEP"
                  v-model="dialogInfos.cep"
                  required
                  @change="procurarCEP($event)"
                  v-mask="'#####-###'"
                ></v-text-field>
              </v-col>

              <v-col cols="10">
                <v-text-field
                  label="Rua"
                  v-model="dialogInfos.rua"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Numero"
                  v-model="dialogInfos.numero"
                  type="number"
                  id="campoNumero"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Bairro"
                  v-model="dialogInfos.bairro"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Cidade"
                  v-model="dialogInfos.cidade"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Estado"
                  v-model="dialogInfos.estado"
                  required
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogCardALuno = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="editarAluno"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



</template>
  
<script>

import {func} from '../assets/functions.js';
import { VDataTable } from 'vuetify/labs/VDataTable'
import { ref } from "vue";
export default {
    name: 'CardAluno',
    components: {VDataTable},
    props: ['objetoAluno', 'carregarAlunos', 'Niveis', 'modalidadesProfessor'],
    data() {
      return {
        dialogExcluirAluno: false,
        idExcluirAluno: null,
        nomeAlunoExcluir: null,
        modalidadesDoAluno: [],
        modalidadesProfessor: [],
        materiaAlunoAdicionar: null,
        niveisFiltrados: [],
        materiasAcademia: [],
        dialogCardPagamentos: false,
        alunoRegularizar: {
          idAluno: null
        },
        
        alunoAprovar: {
          nome: null,
          id: null
        },
        dialogAprovarPagamento: false,
        dialogConfirm: false,
        dataAtual: null,
        dialog: false,
        dialogDelete: false,
        headers: [
          { title: 'Modalidade', key: 'nome' },
          { title: 'Nivel', key: 'nivel' },
          { title: '', key: 'actions', sortable: false },
        ],
        materiasDoAluno: [],
        editedIndex: -1,
        editedItem: {
          
        },
        defaultItem: {
        
        },
    
        tabelaPagamentos: [],
        check: true,
        snackVisivel: null,
        snackMensagem: null,
        sexo: [],
        diasPagamento: [],
        statusAluno: [],
        materiasDoAlunoSelect: [],
        dialogInfos: {
            id: null,
            foto: null,
            matricula: null,
            nome: null,
            dataNascimento: null,
            celular: null,
            email: null,
            sexo: null,
            materias: null,
            diaPagamento: null,          
            cep: null,
            rua: null,
            numero: null,
            bairro: null,
            cidade: null,
            estado: null,
            dataMatricula: null,
            status: null
        },
        dialogCardALuno: false,
        materiasProfessor: [],
        dialogNovaModalidade: false
      }
    },
    mounted: function () {
      var date = new Date();
      this.dataAtual = date;
    },

    methods: {
      addAsModalidadesDoAluno(){
        this.dialogNovaModalidade = false;
        var add = {
          idMateria: this.materiaAlunoAdicionar,
          nome: this.materiasProfessor.filter(x => x.id == this.materiaAlunoAdicionar)[0].nome,
          idNivel: null
        }

        this.modalidadesDoAluno.push(add);
      },
      addModalidade(){
        func.CarregarDados('professor/getMateriasProfessor').then(data => 
          this.materiasProfessor = data.corpo
        );
        this.dialogNovaModalidade = true
      },
      editarAluno(){ 
        // var materiasDoAlunoFinal = [];
        // for (var materia of this.materiasDoAluno) 
        // {
        //   var idNivel = this.Niveis.filter(x => x.nome == materia.nivel && x.idMateria == materia.id)[0].id;

        //   var idMateria =  this.materiasAcademia.filter(x => x.nome == materia.nome)[0].id;
        //   materiasDoAlunoFinal.push(
        //     {
        //       idNivel: idNivel,
        //       idMateria: idMateria
        //     }
        //   )
        // }
        //this.dialogInfos.materias = materiasDoAlunoFinal;

        this.dialogInfos.materias = this.modalidadesDoAluno;
        this.dialogInfos.dataNascimento = func.formatarDataPadraoBD(this.dialogInfos.dataNascimento, false); 
        var ignorar = ['foto', 'responsavel'];
        var erros = func.NovoVerificarJSONNull(this.dialogInfos, ignorar);
        if(erros.length > 0){
           this.snackMensagem = erros;
            this.snackVisivel = true;
            return
        }
        this.converterStatus(this.dialogInfos.status);
        func.resizedataURL(this.dialogInfos.foto).then(data => {
            this.dialogInfos.foto =  data;
            this.dialogCardALuno = false;
            func.CarregarDados('alunos/alterAluno', this.dialogInfos).then(data => 
              this.recarregarAlunos(data)
            );
          }
        );
      },
      deletarModalidade(modalidade){
        this.modalidadesDoAluno = this.modalidadesDoAluno.filter(x => x.idmateriasXalunos != modalidade.idmateriasXalunos)
      },
      carregarDadosTabelaModalidade(data, professor){
        this.modalidadesProfessor = professor;

        var groubedByTeam = this.groupBy(data, 'nomeMateria');
        var propriedades = Object.getOwnPropertyNames(groubedByTeam);
        var novoArray = [];
        for(var i = 0; i < propriedades.length; i++){
          novoArray.push(
            {
              materia: propriedades[i],
              niveis: groubedByTeam[propriedades[i]]
            }
          )
          
        }
        this.graduacaoAgrupada = novoArray;
        this.records = data;
      },
      criardadosTabela(data){
        func.CarregarDados('professor/getConfigGraduacao').then(professor => 
          this.carregarDadosTabelaModalidade(data, professor.corpo)
        );
      },
      groupBy(xs, key){
          return xs.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
      },
      sortData: function () {
        this.modalidadesDoAluno.sort((a, b) => a.id - b.id);
      },
      setAndSortData: function (record) {
        this.sortData();
        //const lastRecord = this.modalidadesDoAluno[this.modalidadesDoAluno.length - 1];
        // console.log(this.modalidadesDoAluno);
        // console.log(lastRecord);
        // console.log(record)
        // if(record.idmateriasXalunos == lastRecord.idmateriasXalunos) {
        //   // this.modalidadesDoAluno.push({id: lastRecord.idmateriasXalunos + 1})
        //   // lastRecord = 
        // }
      },



      filtrarNivel(){
        if(this.materiasAcademia.filter(x => x.nome == this.editedItem.nome).length > 0){
          var idMateria = this.materiasAcademia.filter(x => x.nome == this.editedItem.nome)[0].id;
          this.niveisFiltrados = this.Niveis.filter(x => x.idMateria == idMateria);
        }
        else{
          this.niveisFiltrados = []
        }
      },
      aprovarPagamento(item){
        this.alunoAprovar.id = item.id;
        this.alunoAprovar.nome = item.nome;
        this.dialogAprovarPagamento = true
      },
      aprovarDeFato(idPagamento){
        var post = {
          id: idPagamento
        }
        func.CarregarDados('alunos/aprovarPagamento', post).then(data => 
          this.recarregarAlunos(data)
        );
        this.dialogCardPagamentos = false
      },
      carregarPagamentosAluno(aluno, materias){
        var post = {
          idAluno: aluno
        }
        func.CarregarDados('alunos/getUltimosPagamentos', post).then(data => 
          this.pegarMateriasProfessor(data, aluno, materias)
        );
        
        this.dialogCardPagamentos = true;
      },
      
      pegarMateriasProfessor(data1, aluno, materias){
        func.CarregarDados('professor/getMateriasProfessor').then(data => 
          this.organizarMateriasPagamentos(data1, aluno, materias, data)
        );
      },

      organizarMateriasPagamentos(pagamentos, aluno, materias, materiasProfessor){
        var materiasAlunos = [];
        for(var i = 0; i < materiasProfessor.corpo.length; i++){
          for(var y = 0; y < materias.length; y++){
            if(materiasProfessor.corpo[i].id == materias[y].id){
              materiasAlunos.push(materias[y])
            }
          }
        }

        for(var i = 0; i < materiasAlunos.length; i++){
           materiasAlunos[i]['pagamentos'] = pagamentos.corpo.filter(x => x.idMateria == materiasAlunos[i].id)
        }
       
        
        this.alunoRegularizar = {
          idAluno: aluno
        };
        this.materiasProfessor = materiasAlunos;
        
      },
      regularizar(materia, mesAtual = true){
        var post = {
          idAluno: this.alunoRegularizar.idAluno,
          idMateria: materia, 
          mesAtual: mesAtual
        }
        func.CarregarDados('alunos/regularizar', post).then(data => 
          this.recarregarAlunos(data)
        );
        this.dialogCardPagamentos = false
      },
      // editItem (item) {
      //   this.editedIndex = this.materiasDoAluno.indexOf(item)
      //   this.editedItem = Object.assign({}, item);
       
      //   this.dialog = true
      // },

      // deleteItem (item) {
      //   this.editedIndex = this.materiasDoAluno.indexOf(item)
      //   this.editedItem = Object.assign({}, item)
      //   this.dialogDelete = true
      // },

      // deleteItemConfirm () {
      //   this.materiasDoAluno.splice(this.editedIndex, 1)
      //   this.closeDelete()
      // },

      // close () {
      //   this.dialog = false
      //   this.$nextTick(() => {
      //     this.editedItem = Object.assign({}, this.defaultItem)
      //     this.editedIndex = -1
      //   })
      // },

      // closeDelete () {
      //   this.dialogDelete = false
      //   this.$nextTick(() => {
      //     this.editedItem = Object.assign({}, this.defaultItem)
      //     this.editedIndex = -1
      //   })
      // },

      // save () {
      //   if (this.editedIndex > -1) {
          
      //     Object.assign(this.materiasDoAluno[this.editedIndex], this.editedItem)
      //   } else {
      //     this.materiasDoAluno.push(this.editedItem)
      //   }
      //   this.close()
      // },

      excluirLinhaMateria(idLinha){
        this.materiasDoAluno = this.materiasDoAluno.filter(x => x.idmateriasXalunos != idLinha);
      },
      adicionarTableMateria(materia){
        // var escolherMateria = this.materiasAcademia.filter(x => x.id == materia)[0];
        // var jatem = false;
        // for(var i = 0; i < this.materiasDoAlunoSelect.length; i++){
        //   if(this.materiasDoAlunoSelect[i].id == escolherMateria.id){
        //     jatem = true;
        //   }
        // }
       
        // if(jatem == false){
        //   this.materiasDoAlunoSelect.push(escolherMateria)
        // }
        // if(jatem ==  true){
        //   //this.materiasDoAlunoSelect.push(escolherMateria)
        // }
      },
      
      recarregarAlunos(dados){
        if(dados.sucesso){
          this.snackVisivel = true;
          this.snackMensagem = "Aluno editado com sucesso";
          this.carregarAlunos();
        }  
        else{
          this.snackVisivel = true;
          this.snackMensagem = "Falha ao editar aluno - " + dados.erros;
        }    
      },
      funcaoUploadImage(e){
        func.onFileChange(e).then(data => this.dialogInfos.foto = data);
      },
      procurarCEP(event){
        func.CarregarCEP(this.dialogInfos.cep).then(data => this.carregarDadosCEP(data));
      },
      carregarDadosCEP(dados) {
        this.dialogInfos.rua = dados.logradouro
        this.dialogInfos.bairro = dados.bairro
        this.dialogInfos.cidade = dados.localidade
        this.dialogInfos.estado = dados.uf;
        document.getElementById("campoNumero").focus();
      },
      carregarMateriasProfessor(){
        func.CarregarDados('professor/getMateriasProfessor').then(data => 
          this.materiasAcademia = data.corpo
        );
      },
      carregarEdicao(event) {
        
        this.carregarMateriasProfessor();
        this.statusAluno = func.CarregarStatusAluno();
        this.diasPagamento = func.CarregarDiasPagamento();
        this.sexo = func.CarregarSexos();

        var materias = [];
        this.dialogInfos.id = event.objetoAluno.id;
        this.dialogInfos.matricula = event.objetoAluno.matricula;
        this.dialogInfos.nome = event.objetoAluno.nome;
        this.dialogInfos.dataNascimento = func.formatarDataPadraoBR(event.objetoAluno.dataNascimento, false);
        this.dialogInfos.celular = event.objetoAluno.celular;
        this.dialogInfos.email = event.objetoAluno.email;
        this.dialogInfos.sexo = event.objetoAluno.sexo;

        this.converterMaterias(event.objetoAluno.materias);


        if(event.objetoAluno.tipoAluno == 0){
          this.dialogInfos.diaPagamento = event.objetoAluno.diaPagamento;
        }
        if(event.objetoAluno.tipoAluno == 1){
          this.dialogInfos.diaPagamento = 'Gympass';
        }
        if(event.objetoAluno.tipoAluno == 2){
          this.dialogInfos.diaPagamento = 'Staff';
        }
        if(event.objetoAluno.tipoAluno == 3){
          this.dialogInfos.diaPagamento = 'Totalpass';
        }
        
        this.dialogInfos.cep = event.objetoAluno.cep;
        this.dialogInfos.rua = event.objetoAluno.rua;
        this.dialogInfos.numero = event.objetoAluno.numero;
        this.dialogInfos.bairro = event.objetoAluno.bairro;
        this.dialogInfos.cidade = event.objetoAluno.cidade;
        this.dialogInfos.estado = event.objetoAluno.estado;
        this.dialogInfos.foto = event.objetoAluno.foto;
        this.dialogInfos.dataMatricula = event.objetoAluno.dataMatricula;
        this.dialogInfos.status = event.objetoAluno.status;
        this.dialogInfos.responsavel = event.objetoAluno.responsavel;
        //this.dialogInfos.cpf = event.objetoAluno.cpf;
        this.tabelaPagamentos = event.objetoAluno.ultimosPagamentos;
      },
      converterMaterias(dados){  
        this.modalidadesDoAluno = dados;
        this.criardadosTabela(this.modalidadesDoAluno);
      },
      excluirAlunoDefinitivo(){
        var post = {
          idAluno: this.idExcluirAluno
        }
        func.CarregarDados('alunos/excluirAluno', post).then(data => 
         this.alunoExcluido(data)
        );
      },
      alunoExcluido(data){
        this.dialogExcluirAluno = false;
        this.dialogCardALuno = false;
        this.recarregarAlunos(data);
      },
      converterStatus(dados){
        if(dados.id != undefined){
          this.dialogInfos.status = dados.id;
        }
        else{
          this.dialogInfos.status = dados;
        }
      },
      formatarDatas(data){
        return func.formatarDataPadraoBR(data, true)
      }
    }
  }

</script>


  