<template>

<v-app-bar
        color="primary"
        prominent
        scroll-behavior="fade-image"
       
      >
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ DadosCT.nome }}</v-toolbar-title>
        <v-spacer></v-spacer>
        
        <v-btn variant="text" icon="mdi-dots-vertical" v-on:click="this.$router.push('perfil')"></v-btn>
      </v-app-bar>
</template>
  
<script>
import {func} from '../assets/functions.js';
export default {
    name: 'Header',
    components: {},
    props: {
     
    },
    data() {
      return {
        DadosCT: {},
       
      }
    },
    mounted: function () {
        func.CarregarDados('academias/getAcademia').then(data => this.DadosCT = data.corpo);
    },
  }

</script>


  