<template>
  <div  class="contentPlacar" id="App-Conteudo" style="border: 1px solid black">
    <div class="quadradoVermelho">
     
      <div class="pontuacaoVermelho">{{pontoVermelho}}</div>
      <div class="fotoVermelho" id="fotoVermelho"></div>
    </div>
    <div class="quadradoAzul">
      
      <div class="pontuacaoAzul">{{pontoAzul}}</div>
      <div class="fotoAzul"></div>
    </div>
  </div>
</template>
  
<script>


import {func} from '../assets/functions.js';

  export default {
    name: 'Exemplo',
    components:{ 
      
    },
    props: {
      
    },
    async created(){

    },
    data() {
      return {
        snackVisivel: null,
        snackMensagem: null,
        pontoVermelho: 0,
        pontoAzul: 0
      }
    },
    mounted: function () {

      setInterval(this.getValores, 5000);

    },
    methods: {
      getValores(){
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('sala');
        var post = {
          codigoDaSala: myParam
        }
          func.CarregarDados('placar/getPlacar', post).then(data => 
            this.verificarPontuacao(data.corpo[0])
          );
      },
      verificarPontuacao(dados){
        console.log(dados)
        if(this.pontoVermelho != dados.pontosVermelho){
           
            document.getElementById('fotoVermelho').style.animation = 'treme  0.3s forwards'
            document.getElementById('fotoVermelho').style.animation = null;
        }
        if(this.pontoAzul != dados.pontosAzul){

        }

        this.pontoVermelho = dados.pontosVermelho;
        this.pontoAzul = dados.pontosAzul
      },
    }
  }

</script>
  

  