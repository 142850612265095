<!-- <style>
#app {
  position: absolute; 
  overflow: hidden;
}
</style> -->
<template>

  <v-theme-provider theme="dark" with-background class="pa-10" style="height: 100%; width: 100%;">
  <v-snackbar
      v-model="snackbar"
      multi-line
    >
      {{snackMensagem}}

      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  
    <div class="formAlunoCheck">
      <h3>{{ academia.nome }}</h3>
      <img style="width: 50px;height: 50px;border-radius:200px;margin: auto" v-bind:src="academia.logo" />
      <br /><br />
      <div v-if="formProcuraCPF">
        <span>Digite seu email:</span>
        <v-text-field
          
          v-model="aluno.email"
          label="Email"
          @change="localizarAluno()"
        ></v-text-field>
      </div>
      
      

      <div v-if="aluno.id != 0 && aluno.id != null">
        <h4>{{aluno.nome}}</h4>
        <div  v-for="item in aluno.materias" :key="item.id" >
          <h5>{{item.nome}}</h5>
        </div>
        
        <!-- <h4 v-if="aluno.pagamentos.filter(x => x.status == 0).length > 0">Status pagamento:</h4>   
        <div v-for="pagamento in aluno.pagamentos.filter(x => x.status == 0)" :key="pagamento.id">
          <div>
            <h5>{{pagamento.materia}}: {{pagamento.nome}}</h5>
          </div>
        </div> -->

       

        <!-- <div v-if="aluno.pagamentos.filter(x => x.status == 0).length > 0">
          Faça o PIX para os professor(es) responsaveis e anexe os comprovantes
        </div>
        <br v-if="aluno.pagamentos.filter(x => x.status == 0).length > 0"/> -->

        <div>
          <!-- <div v-for="pagamento in aluno.pagamentos" :key="pagamento.id"> -->
            <!-- <div v-if="pagamento.status == 0" v-bind:style="pagamento.contadorPendencias != null && pagamento.contadorPendencias > 1  ? 'opacity: 0.3; pointer-events: none;' : 'opacity: 1' ">
              <span v-if="pagamento.contadorPendencias != null && pagamento.contadorPendencias > 1">Faça o primeiro pagamento e envie o comprovante para liberar os demais</span>
              
              <h5>PIX: {{pagamento.pixProfessor}} <br /></h5>
              <h5>{{pagamento.nomeProfessor}} ({{pagamento.materia}})</h5>
              <input type="file" @change="upload" /><br /><br />
              <v-btn
                  color="purple-lighten-2"
                  variant="outlined"
                  @click="enviarComprovante(pagamento)"
                >
                  Enviar comprovante
              </v-btn>
            </div> -->

            <div v-if="aluno.pagamentos.length > 0 && aluno.pagamentos.filter(x => x.status == 0).length > 0">
              <span>teste</span>
              <h5>PIX: {{aluno.pagamentos.filter(x => x.status == 0)[0].pixProfessor}} <br /></h5>
              <h5>{{aluno.pagamentos.filter(x => x.status == 0)[0].nomeProfessor}}</h5>
              <input type="file" @change="upload" /><br /><br />
              <v-btn
                  color="purple-lighten-2"
                  variant="outlined"
                  @click="enviarComprovante(aluno.pagamentos)"
                >
                  Enviar comprovante
              </v-btn>
            </div>
            <br />
          <!-- </div> -->
        </div>
        

        
        <br v-if="aluno.pagamentos.filter(x => x.status == 0).length > 0"/>
        <br v-if="aluno.pagamentos.filter(x => x.status == 0).length > 0"/>       


        <div v-if="aluno.id != null && aluno.eventos.length > 0" >
          Convites Pendentes ({{ aluno.eventos.length }})
        </div>
        <v-card  v-for="item in aluno.eventos" :key="item.id" style="padding: 10px">
          <h4>{{item.nome}}</h4>
          <h6>{{item.descricao}}</h6><br />
          <h5>{{item.data}}</h5>
          <v-btn
              color="purple-lighten-2"
              variant="outlined"
            
              @click="confirmarEvento(item)"
            >
              Confirmar
            </v-btn>
        </v-card>


        <div v-if="aluno.id != null && aluno.infoMateria.length > 0" style="margin-top: 20px">
          Escolha a aula para dar checkIN ({{ aluno.infoMateria.length }})
        </div>
        <v-card  v-for="item in aluno.infoMateria" :key="item.id" style="padding: 10px">
          <h4>{{item.nome}}</h4>
          <h6>{{item.horario}}</h6><br />
          <v-btn
              color="purple-lighten-2"
              variant="outlined"
            
              @click="checkIn(item)"
            >
              CheckIn
            </v-btn>
        </v-card>
      </div>
      <br v-if="aluno.id != null && aluno.id != ''"/>
      <v-btn v-if="aluno.id != null && aluno.id != ''" 
        color="primary"
        variant="text"
        @click="verVideos()">Videos
      </v-btn>

      <v-expansion-panels>
      <v-expansion-panel
        v-for="item in playlists" 
        :key="item"
        :title="item.nome"
      >
      <v-expansion-panel-text>
          <YouTube 
             v-for="video in item.videos"
            :src="video.urlVideo" 
            :key="video"
            ref="youtube" 
            width="100%"
            height="300"
            style="float: left; margin-right: 10px; margin-top: 10px"
            />
      </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

      <div v-if="aluno.id == null">
        <h2>Cadastrar</h2>
        <v-container>
            <v-row>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Pessoal
                <hr />
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <div class="logoAcademiaPerfil" style="overflow: hidden; padding-right: 110px; margin: initial;">
                <img v-bind:src="InfosAluno.foto" style="position: absolute"/>
                <input type="file" @change="funcaoUploadImage" style="position: absolute"/>
              </div>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nome completo"
                  v-model="InfosAluno.nome"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Data de nascimento"
                  v-model="InfosAluno.dataNascimento"
                  hint=""
                  v-mask="'##/##/####'"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Celular"
                  v-model="InfosAluno.celular"
                  hint=""
                  persistent-hint
                  required
                  v-mask="'(##) #####-####'"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12">
                <v-text-field
                  label="CPF"
                  disabled="true"
                  v-model="InfosAluno.cpf"
                  v-mask="'###.###.###-##'"
                  required
                ></v-text-field>
              </v-col> -->
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  v-model="InfosAluno.email"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  :items="sexo"
                  v-model="InfosAluno.sexo"
                  label="Sexo"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-autocomplete
                  :items="materias"
                  v-model="InfosAluno.materias"
                  label="Matérias"
                  item-title="nome"
                  item-value="id"
                  multiple
                ></v-autocomplete>
              </v-col>
              <v-col 
                  cols="12" 
                  sm="12"
                >
                <v-autocomplete
                  :items="diasPagamento"
                  label="Dia de pagamento"
                  v-model="InfosAluno.diaPagamento"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Endereço
                <hr />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="CEP"
                  v-model="InfosAluno.cep"
                  @change="procurarCEP($event)"
                  required
                  v-mask="'#####-###'"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="InfosAluno.rua"
                  label="Rua"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                v-model="InfosAluno.numero"
                  label="Numero"
                  type="number"
                  id="campoNumero"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                v-model="InfosAluno.bairro"
                  label="Bairro"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                v-model="InfosAluno.cidade"
                  label="Cidade"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                v-model="InfosAluno.estado"
                  label="Estado"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-btn
            color="blue-darken-1"
            variant="text"
            @click="addAluno()"
          >
            Cadastrar
          </v-btn>
          </v-container>
      </div>
    </div>

    <v-dialog
        v-model="dialogEscolhaOCadastro"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title>
            Escolha o cadastro que deseja utilizar agora:
          </v-card-title>
          <v-card-text v-for="cadastro in encontradosDoisCadastros" :key="cadastro.id">
            <v-btn  
                color="primary"
                @click="utilizarCadastro(cadastro)"
            >
              <div v-for="materia in cadastro.materias" :key="materia.id">
               <span> - {{materia.nome}} - </span> 
              </div> 
            </v-btn>
          </v-card-text>
          <br />

        </v-card>
      </v-dialog>
  </v-theme-provider>
</template>
  
<script>


import {func} from '../assets/functions.js';
import YouTube from 'vue3-youtube'

  export default {
    name: 'AlunoCheck',
    components:{ 
       YouTube 
    },
    props: {
      
    },
    async created(){
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('t');
      
      if(myParam){
        var post = {
          token: myParam
        }
        
        func.CarregarDados('academias/getAcademiaTokenPublico', post).then(data => 
          this.carregarVariaveisAcademia(data.corpo)
        );
      }
    },
    data() {
      return {
        contadorPagamentos: 1,
        comprovante: null,
        formProcuraCPF: true,
        dialogEscolhaOCadastro: false,
        encontradosDoisCadastros: [],
        CadastroPreselecionado: null,
        InfosAluno: {
            matricula: null,
            nome: null,
            dataNascimento: null,
            celular: null,
            email: null,
            sexo: null,
            materias: null,
            diaPagamento: null,
            cep: null,
            rua: null,
            numero: null,
            bairro: null,
            cidade: null,
            estado: null,
            foto: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png',
            status: null,
            //cpf: null,
            idAcademia: null
        },
        aluno: {
          //cpf: null,
          nome: null,
          id: 0,
          idAcademia: null,
          eventos: [],
          infoMateria: [],
          email: '',
          pagamento: {
            situacao: null,
            dataPagamento: null
          }
        },
        professoresAluno: [],
        academia: {
          id: null,
          nome: null,
          logo: null,
          materias: [],
          pix: null,
          email: null
        },
        snackbar: false,
        infosLogin: {
          email: null,
          senha: null
        },
        snackVisivel: null,
        snackMensagem: null,
        mostrarheader: true,
        materias: [],
        sexo: [],
        diasPagamento: [],
        excedeuTamanhoArquivo: false,
        playlists: [],
        materiasPlayList: []
      }
    },
    
    methods: {  
      verVideos(){
        for (var materia of this.aluno.materias) 
        {
          this.materiasPlayList.push(materia.id)
        }

        var idProfessores = [];
        for (var materia of this.aluno.pagamentos) 
        {
          idProfessores.push(materia.idProfessor)
        }
        var post = {
          idProfessores: idProfessores
        }
        func.CarregarDados('playlist/getPlayListsporMateria', post).then(data => 
          this.carregarPlayList(data.corpo)
        );
      },
      carregarPlayList(data){
        var refPlaylist = [];
        for (var play of data) 
        {
          for (var materia of play.materias) 
          {
            if(this.materiasPlayList.includes(materia.id)){
              if(refPlaylist.filter(x => x.id == play.id).length == 0){
                refPlaylist.push(play)
              }
            }
          }
        }
        this.playlists = refPlaylist
      },
      upload(img) {
        var tamanhoPermitido = 1000;
        var tamanhoComprovante = img.target.files[0].size / 1000
        if(tamanhoComprovante > tamanhoPermitido){
          this.excedeuTamanhoArquivo = true;
          this.snackbar = true;
          this.snackMensagem = "Arquivo muito grande, tamanho máximo permitido 1MB";
        }
        else{
          this.excedeuTamanhoArquivo = false;
          func.onFileChange2(img).then(data => this.addComprovantes(data));
        }
      },
      addComprovantes(dados){
        this.comprovante = dados;
        
      },
      enviarComprovante(pagamentos){  
        for(var i = 0; i < pagamentos.length; i++){
          if(!this.excedeuTamanhoArquivo){
              if(this.comprovante != null){
                  var post = {
                    nomeAluno:  this.aluno.nome,
                    id: this.aluno.id,
                    comprovante: this.comprovante,
                    emailAcademia: this.academia.email,
                    idMateria: pagamentos[i].idMateria
                  }

                  func.CarregarDados('alunos/enviarComprovante', post).then(data => 
                    this.localizarAluno()
                  );
              }
              else{
                this.snackbar = true;
                this.snackMensagem = "Necessário anexar um comprovante";
              }
              
            }
            else{
              this.snackbar = true;
              this.snackMensagem = "Arquivo muito grande, tamanho máximo permitido 1MB";
            }
        }
        
        if(this.CadastroPreselecionado != null){
          for(var i = 0; i < this.CadastroPreselecionado.corpo[0].pagamentos.length; i++){
            this.CadastroPreselecionado.corpo[0].pagamentos[i].status = 1
            this.CadastroPreselecionado.corpo[0].pagamentos[i].nome = 'Aguardando aprovação'
          }
        }
      }, 
      carregarVariaveisAcademia(dados){
        this.academia = dados
        var postMateriasAcademia = {
          idAcademia: dados.id
        }
        func.CarregarDados('academias/getMateriasAcademiaPorCPF', postMateriasAcademia).then(data => 
          this.carregarDadosCard(data.corpo)
        );
      },  
      addAluno(){
        this.InfosAluno.matricula = 'Temporaria';
        this.InfosAluno.idAcademia = this.academia.id;
        this.InfosAluno.status = 1;
        
        var ignorar = ['cep', 'rua', 'numero', 'bairro', 'cidade', 'estado', 'foto'];
        var erros = func.NovoVerificarJSONNull(this.InfosAluno, ignorar);
        if(erros.length > 0){ 
          this.snackMensagem = erros;
          this.snackbar = true;
          return
        }

        this.InfosAluno.dataNascimento = func.formatarDataPadraoBD(this.InfosAluno.dataNascimento, false);
        this.InfosAluno.matricula = this.construirmatricula();
        func.resizedataURL(this.InfosAluno.foto).then(data => {
            this.InfosAluno.foto =  data;
            func.CarregarDados('alunos/addAlunoPorCPF', this.InfosAluno).then(data => 
              this.recarregarAposCadastro()
            );
          }
        );
      },
      recarregarAposCadastro(){
        this.snackMensagem = "Cadastro feito com sucesso !"
        this.snackbar = true;
        this.aluno.email = this.InfosAluno.email;
        this.localizarAluno();
      },
      procurarCEP(event){
        func.CarregarCEP(this.InfosAluno.cep).then(data => this.carregarDadosCEP(data));
      },
      carregarDadosCEP(dados) {
        this.InfosAluno.rua = dados.logradouro
        this.InfosAluno.bairro = dados.bairro
        this.InfosAluno.cidade = dados.localidade
        this.InfosAluno.estado = dados.uf;
        document.getElementById("campoNumero").focus();
      },
      
      funcaoUploadImage(e){
        func.onFileChange(e).then(data => this.InfosAluno.foto = data);
      },
      construirmatricula(){
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = ' ';
        const charactersLength = 6;
        for ( let i = 0; i < charactersLength; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 6));
        }
        return result.replace(/\s+/g, '');
      },
      carregarDadosCard(dados){
        
          this.statusAluno = func.CarregarStatusAluno();
          this.converterMaterias(dados);
          this.diasPagamento = func.CarregarDiasPagamento();
          this.sexo = func.CarregarSexos();
          
          this.filtroEmAtraso = false;
          this.filtroRegular = false;

          this.filtroAtivo = false;
          this.filtroDesativado = false;
      },
      converterMaterias(dados){
        this.materias = dados;
      },
     
      confirmarEvento(evento){
        var post = {
          idAluno: this.aluno.id,
          idEvento: evento.id,
        }
        if(this.CadastroPreselecionado != null){
          for(var i = 0; i < this.CadastroPreselecionado.corpo[0].eventos.filter(x => x.id == evento.id).length; i++){
            this.CadastroPreselecionado.corpo[0].eventos = this.CadastroPreselecionado.corpo[0].eventos.filter(x => x.id != evento.id);
          }
        }
        func.CarregarDados('eventos/aceitarConvite', post).then(data => 
          this.localizarAluno()
        );
      },
      checkIn(check){
        var post = {
          idAluno: this.aluno.id,
          idAcademia: this.aluno.idAcademia,
          idAula: check.id
        }

        if(this.CadastroPreselecionado != null){
          for(var i = 0; i < this.CadastroPreselecionado.corpo[0].infoMateria.filter(x => x.id == check.id).length; i++){
            this.CadastroPreselecionado.corpo[0].infoMateria = this.CadastroPreselecionado.corpo[0].infoMateria.filter(x => x.id != check.id);
          }
        }
        func.CarregarDados('checkIns/addCheckIn', post).then(data => 
          this.localizarAluno()
        );
      },
      localizarAluno(){ 
        if(this.aluno.email.includes('@')){
          var post = {
            idAcademia: this.academia.id,
            email: this.aluno.email
          }
          func.CarregarDados('alunos/getAlunoPorCPF', post).then(data => 
            this.carregarVariaveisAluno(data)
          );
        }
      },

      utilizarCadastro(cadastro){
        var corpo = [];
        corpo.push(cadastro)
        var novo = {
          sucesso: true,
          corpo: corpo
        }
        this.dialogEscolhaOCadastro = false;
        this.carregarVariaveisAluno(novo);
        this.CadastroPreselecionado = novo;
      },

      carregarVariaveisAluno(dados){
        if(this.CadastroPreselecionado != null){
            this.formProcuraCPF = false;
            this.aluno.id = this.CadastroPreselecionado.corpo[0].id;
            this.aluno.nome = this.CadastroPreselecionado.corpo[0].nome;
            this.aluno.idAcademia = this.CadastroPreselecionado.corpo[0].idAcademia; 
            this.aluno.materias = this.CadastroPreselecionado.corpo[0].materias;
            this.aluno.eventos = this.CadastroPreselecionado.corpo[0].eventos;
            this.aluno.infoMateria =  this.CadastroPreselecionado.corpo[0].infoMateria;
            this.aluno.pagamentos = this.CadastroPreselecionado.corpo[0].pagamentos;

            // for(var i = 0; i <= this.aluno.eventos.length; i++){
            //   if(this.aluno.eventos.length > 0){
            //     this.aluno.eventos[i].data = func.formatarDataPadraoBR(this.aluno.eventos[i].data, true);
            //   }
            // }
          return
        }

        if(dados.sucesso){
          if(dados.corpo.length > 1){
            this.dialogEscolhaOCadastro = true;
            for ( let i = 0; i < dados.corpo.length; i++ ) {
                this.encontradosDoisCadastros.push(dados.corpo[i])
            }
          }
          else{
            this.formProcuraCPF = false;
            this.aluno.id = dados.corpo[0].id;
            this.aluno.nome = dados.corpo[0].nome;
            this.aluno.idAcademia = dados.corpo[0].idAcademia; 
            this.aluno.materias = dados.corpo[0].materias;
            this.aluno.eventos = dados.corpo[0].eventos;
            this.aluno.infoMateria =  dados.corpo[0].infoMateria;
            this.aluno.pagamentos = dados.corpo[0].pagamentos;

            // for(var i = 0; i <= dados.corpo[0].eventos.length; i++){
            //   if(dados.corpo[0].eventos.length > 0){
            //     this.aluno.eventos[i].data = func.formatarDataPadraoBR(this.aluno.eventos[i].data, true);
            //   }
            // }
          }
        }
        else{
          this.formProcuraCPF = false;
          this.InfosAluno.cpf = this.aluno.email;
          this.aluno =  {
            email: this.aluno.email,
            nome: null,
            id: null,
            eventos: [],
            infoMateria: [],
            pagamento: {
              situacao: null,
              dataPagamento: null
            }
          }
        }
      }
    }
  }

</script>
  

  