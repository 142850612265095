<template>
  <v-toolbar>
        <v-toolbar-title class="text-grey" style="text-align: left;">
          Playlists
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-on:click="dialogCriarPlayList = true">
          <v-icon class="pulse">mdi-video-account</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
  <div  class="App-Conteudo" id="App-Conteudo">
    <div class="App-Conteudo-Comp">
      <div class="App-Conteudo-Comp-Box">
      <v-snackbar
          v-model="snackbar"
          multi-line
        >
        {{ text }}

        <template v-slot:actions>
          <v-btn
            color="red"
            variant="text"
            @click="snackbar = false"
          >
            Fechar
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog
      v-model="dialogCriarPlayList"
      persistent
      width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Criar PlayList</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nome"
                  v-model="novaPlayList.nome"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-textarea
                  label="Descrição"
                   v-model="novaPlayList.descricao"
                  hint=""
                ></v-textarea>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  v-model="novaPlayList.materias"
                  :items="materias"
                  item-title="nome"
                  item-value="id"
                  label="Selecione a matéria"
                  multiple
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogCriarPlayList = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="criarPlayist()"
          >
            Criar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


<v-dialog
      v-model="dialogAddVideo"
      persistent
      width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Adicionar video</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="URL"
                  v-model="addVideo.url"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Ordem"
                  type="number"
                  v-model="addVideo.ordem"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogAddVideo = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="adicionarVideoDeFato()"
          >
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<v-expansion-panels>
      <v-expansion-panel
        v-for="item in playlists" 
        :key="item"
        :title="item.nome"
        
      >
      <v-expansion-panel-text>
          <h4>Restritro:</h4> <v-chip v-for="materia in item.materias" >{{materia.nome}}</v-chip>
          <v-btn
            color="blue-darken-1"
            @click="dialogAdicionarVideo(item)"
            style="float: right"
          >
            + video
          </v-btn><br /><br />
          <div v-for="video in item.videos" style="width: 300px; text-align: center; float: left; margin-right: 10px; margin-top: 10px;">
            <v-btn color="blue-darken-1" @click="excluirVideo(video)" variant="text" style="margin: auto; margin-bottom: 10px">
             Excluir
            </v-btn><br />
            <YouTube 
              :src="video.urlVideo" 
              :key="video"
              @ready="onReady"
              ref="youtube" 
              width="300"
              height="300"
             
              />
            </div>
      </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    </div>
          
    </div>
  </div>
</template>
  
<script>


import {func} from '../assets/functions.js';

import YouTube from 'vue3-youtube'
  export default {
    name: 'CheckInsConf',
    components:{ 
       YouTube 
    },
    props: {
      
    },
    async created(){

    },
    data() {
      return {
        snackbar: false,
        text: null,
        dialogCriarPlayList: false,
        dialogAddVideo: false,
        novaPlayList: {
          nome: null,
          descricao: null,
          materias: []
        },
        materias: [],
        playlists: [],
        addVideo: {
          idPlayList: null,
          url: null,
          ordem: null
        }
      }
    },
    mounted: function () {
        this.carregarTela()
    },
    watch: {
        
    },
    methods: {
      carregarTela(){
        this.dialogCriarPlayList = false;
        func.CarregarDados('professor/getMateriasProfessor').then(data => 
          this.materias = data.corpo
        ); 
        func.CarregarDados('playlist/getPlayLists').then(data => 
          this.playlists = data.corpo
          //console.log(data)
        ); 
      },
      criarPlayist(){
        func.CarregarDados('playlist/addPlayList', this.novaPlayList).then(data => 
          this.carregarTela()
        );
      },
      excluirVideo(video){
        func.CarregarDados('playlist/excluirVideo', video).then(data => 
          this.carregarTela()
        );
      },
      dialogAdicionarVideo(item){
        this.addVideo.idPlayList = item.id
        this.dialogAddVideo = true
      },
      adicionarVideoDeFato(){
        func.CarregarDados('playlist/addVideoPlayList', this.addVideo).then(data => 
          this.carregarTela()
        );
      }
    }
  }

</script>
  

  