<template>
  <div  class="App-Conteudo" id="App-Conteudo">
    <div class="HeaderPage">
        <label class="HeaderPageLabel">Exemplo</label>
        <button class="button1 flutuarDireita margemDireita" v-on:click="null"><font-awesome-icon icon="save" class="icon2"/>&nbsp; &nbsp;Salvar</button>
    </div>
    <div class="App-Conteudo-Comp">
      <div class="App-Conteudo-Comp-Box">
        <v-snackbar
      v-model="snackVisivel"
    >
      {{snackMensagem}}
      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="snackVisivel = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
      </div>
          
    </div>
  </div>
</template>
  
<script>


import {func} from '../assets/functions.js';

  export default {
    name: 'Exemplo',
    components:{ 
      
    },
    props: {
      
    },
    async created(){

    },
    data() {
      return {
        snackVisivel: null,
        snackMensagem: null,
      }
    },
    mounted: function () {
      func.Carregar()
      setTimeout(() => {  
        func.FinalizarCarregamento(); 
      }, 2000);
    },
    methods: {
      procurarCEP(event){
        
      },
    }
  }

</script>
  

  