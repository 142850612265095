<template>
  <v-dialog
      v-model="dialog"
      width="auto"
      persistent
    >
      <v-card>
        <v-card-text>
          Seu Pagamento está sendo processado e logo será liberado
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="ir()">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
  
<script>


import {func} from '../assets/functions.js';
  export default {
    name: 'PagamentoAnalise',
    components:{ 
      
    },
    props: {
      
    },
    async created(){
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('t');

      func.CarregarDados('jobs/validarPagamento').then(data => 
         console.log(data)
      );
    },
    data() {
      return {
        dialog: true
      }
    },
    mounted: function () {
      
    },
    methods: {
      ir(){
        this.$router.push('perfil')
      }
    }
  }

</script>
  

  