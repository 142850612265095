<!-- <style>
#app {
  position: absolute; 
  overflow: hidden;
}
</style> -->
<template>

  <v-theme-provider theme="dark" with-background class="pa-10" style="height: 100%; width: 100%;">
  <v-snackbar
      v-model="snackbar"
      multi-line
    >
      {{snackMensagem}}

      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  
    <div class="formAlunoCheck">
      <h3>{{ academia.nome }}</h3>
      <img style="width: 50px;height: 50px;border-radius:200px;margin: auto" v-bind:src="academia.logo" />
      <br /><br />
      <div v-if="formProcuraCPF">
        <span>Digite seu email:</span>
        <v-text-field
          
          v-model="aluno.email"
          label="Email"
          @change="localizarAluno()"
        ></v-text-field>
      </div>

    <div v-if="aluno.id == 0 || aluno.id == null">
      <div v-if="produtos.length > 0"><b>Confira nossos produtos e serviços</b></div>
      <v-card v-for="produto in produtos" style="margin-top: 10px;  color: white; font-family: 'Trebuchet MS', sans-serif;" @click="abrirWhats(produto)">
        <div style="margin-top: 10px; font-size: 23px"><b>{{produto.nome}}</b></div>
        <div v-if="produto.foto != null && produto.foto != ''" :style="{'background-image':'url('+produto.foto+')'}" class="divAvatar" style="margin: auto; width: 100px; height: 100px"></div>
        <div style="font-size: 13px">{{produto.descricao}}</div>
        <div><b>R${{produto.preco}}</b></div>
        <div style="text-decoration: underline; margin-bottom: 20px" color="primary">mais informações</div>
      </v-card>
    </div> 
     

      <div v-if="aluno.id != 0 && aluno.id != null">
        <h4>{{aluno.nome}}</h4>
        <img style="width: 50px; height: 50px;border-radius:200px; margin-left: 0px;" v-bind:src="aluno.foto" />
        <div style="margin-left: 0px; margin-top: 5px" v-for="item in aluno.materias" :key="item.id" >
          
          <h5 v-if="item.imagemURL == null || item.imagemURL == ''">
            <span v-bind:style="{ background: item.corFundo, color: item.corLetra }">{{item.nome}}</span> <div v-if="item.checkInsTotal > 0">({{item.checkInsFeitos}} checkins)</div>
          </h5>
          <div v-if="item.imagemURL != null && item.imagemURL != ''" style="width: 100%">
              <img style="width: 100px;height: 20px;" v-bind:src="item.imagemURL" /><div v-if="item.checkInsTotal > 0">({{item.checkInsFeitos}} checkins)</div>
          </div>
        </div><br />
        
        <div v-if="aluno.tipoAluno == 0">
          <h4 v-if="aluno.pagamentos.filter(x => x.status == 0).length > 0"><span style="color: red">Status pagamento:</span></h4>   
          <div v-for="pagamento in aluno.pagamentos.filter(x => x.status == 0)" :key="pagamento.id">
            <div>
              <h8>{{pagamento.materia}}: <span style="color: red">{{pagamento.nome}}</span> <br /> PIX: {{pagamento.pixProfessor}}</h8>
              <input type="file" class="inputFile"  @change="upload(pagamento.idMateria)" />
            </div>
            <br />
          </div>

          <div v-if="aluno.pagamentos.filter(x => x.status == 0).length > 0">
                <v-btn
                    color="purple-lighten-2"
                    variant="outlined"
                    @click="enviarComprovante(aluno.pagamentos)"
                  >
                    Enviar comprovante(s)
                </v-btn>
          </div>
        </div>
        <div v-if="aluno.tipoAluno == 1">
          <div  class="divAvatar" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/lutas-984a9.appspot.com/o/gympass-logo.png?alt=media&token=a762c13d-9960-43d8-9f2d-e4ea091cfc51');  margin: auto; width: 30px; height: 30px; margin-top: 10px"></div>
        </div>
        <div v-if="aluno.tipoAluno == 2">
          <div  class="divAvatar" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/lutas-984a9.appspot.com/o/staff.png?alt=media&token=40169c7a-85e7-4daf-986e-5dc75bc90c80');  margin: auto; width: 30px; height: 30px; margin-top: 10px"></div>
        </div>


        <br />
        <div>
          

            <!-- <div v-if="aluno.pagamentos.length > 0 && aluno.pagamentos.filter(x => x.status == 0).length > 0">
              <h5>PIX: {{aluno.pagamentos.filter(x => x.status == 0)[0].pixProfessor}} <br /></h5>
              <h5>{{aluno.pagamentos.filter(x => x.status == 0)[0].nomeProfessor}}</h5>
              
              <input type="file" class="inputFile" @change="upload" /><br /><br />
              <v-btn
                  color="purple-lighten-2"
                  variant="outlined"
                  @click="enviarComprovante(aluno.pagamentos)"
                >
                  Enviar comprovante
              </v-btn>
            </div> -->
            <br />
        </div>
        

        
        <br v-if="aluno.pagamentos.filter(x => x.status == 0).length > 0"/>
        <br v-if="aluno.pagamentos.filter(x => x.status == 0).length > 0"/>       


        <div v-if="aluno.id != null && aluno.eventos.length > 0" >
          Convites Pendentes ({{ aluno.eventos.length }})
        </div>
        <v-card  v-for="item in aluno.eventos" :key="item.id" style="padding: 10px">
          <h4>{{item.nome}}</h4>
          <h6>{{item.descricao}}</h6><br />
          <h5>{{item.data}}</h5>
          <v-btn
              color="purple-lighten-2"
              variant="outlined"
            
              @click="confirmarEvento(item)"
            >
              Confirmar
            </v-btn>
        </v-card>

        <div v-if="algumAtrasado == true && aluno.tipoAluno != 1  && aluno.tipoAluno != 2" style="color: red">
          CheckIN desabilitado até regularização de mensalidade
        </div>
        <div v-if="aluno.id != null && aluno.infoMateria.length > 0 && algumAtrasado == false " style="margin-top: 20px">
          Escolha a aula para dar checkIN ({{ aluno.infoMateria.length }})
        </div>

        <v-card v-if="algumAtrasado == false ||  (aluno.tipoAluno == 1  || aluno.tipoAluno == 2)"  v-for="item in aluno.infoMateria" :key="item.id" style="padding: 10px">
          <h4>{{item.nome}}</h4>
          <h6>{{item.horario}}</h6>
          <h5 v-if="item.unidade != null && item.unidade != ''">({{item.unidade}})</h5>
          <v-btn
              color="purple-lighten-2"
              variant="outlined"
            
              @click="checkIn(item)"
            >
              CheckIn
            </v-btn>
        </v-card>
      </div>
      <br v-if="aluno.id != null && aluno.id != ''"/>
      <v-btn v-if="aluno.id != null && aluno.id != ''" 
        color="primary"
        variant="text"
        @click="verVideos()">Videos
      </v-btn>

      <v-expansion-panels>
      <v-expansion-panel
        v-for="item in playlists" 
        :key="item"
        :title="item.nome"
      >
      <v-expansion-panel-text>
          <YouTube 
             v-for="video in item.videos"
            :src="video.urlVideo" 
            :key="video"
            ref="youtube" 
            width="100%"
            height="300"
            style="float: left; margin-right: 10px; margin-top: 10px"
            />
      </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

      <div v-if="aluno.id == null">
        <h2>Cadastrar</h2>
        <v-container>
            <v-row>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Pessoal
                <hr />
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <div class="logoAcademiaPerfil" style="overflow: hidden; padding-right: 110px; margin: initial;">
                <img v-bind:src="InfosAluno.foto" style="position: absolute"/>
                <input type="file" @change="funcaoUploadImage" style="width: 200px !important"/>
              </div>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nome completo"
                  v-model="InfosAluno.nome"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Data de nascimento"
                  v-model="InfosAluno.dataNascimento"
                  hint="01/01/1998"
                  persistent-hint
                  v-mask="'##/##/####'"
                  @change="ativarResponsavel()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-if="campoResponsavel"
                  label="Nome do responsavel"
                  v-model="InfosAluno.responsavel"
                  hint="Nome do responsável (menor de idade)"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Celular"
                  v-model="InfosAluno.celular"
                  hint="(11) 99999-9999"
                  persistent-hint
                  required
                  v-mask="'(##) #####-####'"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12">
                <v-text-field
                  label="CPF"
                  disabled="true"
                  v-model="InfosAluno.cpf"
                  v-mask="'###.###.###-##'"
                  required
                ></v-text-field>
              </v-col> -->
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  v-model="InfosAluno.email"
                  required
                  :disabled="true"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  :items="sexo"
                  v-model="InfosAluno.sexo"
                  label="Sexo"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-autocomplete
                  :items="materias"
                  v-model="InfosAluno.materias"
                  label="Matérias"
                  item-title="nome"
                  item-value="id"
                  multiple
                ></v-autocomplete>
              </v-col>
              <v-col 
                  cols="12" 
                  sm="12"
                >
                <v-autocomplete
                  :items="diasPagamento"
                  label="Dia de pagamento"
                  v-model="InfosAluno.diaPagamento"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Endereço
                <hr />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="CEP"
                  v-model="InfosAluno.cep"
                  @change="procurarCEP($event)"
                  required
                  v-mask="'#####-###'"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="InfosAluno.rua"
                  label="Rua"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                v-model="InfosAluno.numero"
                  label="Numero"
                  type="number"
                  id="campoNumero"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                v-model="InfosAluno.bairro"
                  label="Bairro"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                v-model="InfosAluno.cidade"
                  label="Cidade"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                v-model="InfosAluno.estado"
                  label="Estado"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-btn
            color="blue-darken-1"
            variant="text"
            @click="addAluno()"
          >
            Cadastrar
          </v-btn>
          </v-container>
      </div>
    </div>

    <v-dialog
        v-model="dialogEscolhaOCadastro"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title>
            Escolha o cadastro que deseja utilizar agora:
          </v-card-title>
          <v-card-text v-for="cadastro in encontradosDoisCadastros" :key="cadastro.id">
            <v-btn  
                color="primary"
                @click="utilizarCadastro(cadastro)"
            >
              <div v-for="materia in cadastro.materias" :key="materia.id">
               <span> - {{materia.nome}} - </span> 
              </div> 
            </v-btn>
          </v-card-text>
          <br />

        </v-card>
      </v-dialog>
  </v-theme-provider>
</template>
  
<script>


import {func} from '../assets/functions.js';
import YouTube from 'vue3-youtube'

  export default {
    name: 'AlunoCheck',
    components:{ 
       YouTube 
    },
    props: {
      
    },
    async created(){
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('t');

      if(myParam){
        var post = {
          token: myParam
        }
        
        func.CarregarDados('academias/getAcademiaTokenPublico', post).then(data => 
          this.carregarVariaveisAcademia(data.corpo)
        );
      }
    },
    data() {
      return {
        algumAtrasado: false,
        contadorPagamentos: 1,
        comprovante: null,
        formProcuraCPF: true,
        dialogEscolhaOCadastro: false,
        encontradosDoisCadastros: [],
        CadastroPreselecionado: null,
        InfosAluno: {
            matricula: null,
            nome: null,
            dataNascimento: null,
            celular: null,
            email: null,
            sexo: null,
            materias: null,
            diaPagamento: null,
            cep: null,
            rua: null,
            numero: null,
            bairro: null,
            cidade: null,
            estado: null,
            foto: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png',
            status: null,
            //cpf: null,
            idAcademia: null,
            responsavel: null
        },
        aluno: {
          //cpf: null,
          nome: null,
          id: 0,
          foto: null,
          idAcademia: null,
          eventos: [],
          infoMateria: [],
          email: '',
          pagamento: {
            situacao: null,
            dataPagamento: null
          }
        },
        professoresAluno: [],
        academia: {
          id: null,
          nome: null,
          logo: null,
          materias: [],
          pix: null,
          email: null
        },
        snackbar: false,
        infosLogin: {
          email: null,
          senha: null
        },
        snackVisivel: null,
        snackMensagem: null,
        mostrarheader: true,
        materias: [],
        sexo: [],
        diasPagamento: [],
        excedeuTamanhoArquivo: false,
        playlists: [],
        materiasPlayList: [],
        campoResponsavel: false,
        arquivosComprovante: [],
        produtos: []
      }
    },
    
    methods: {  
      abrirWhats(produto){
        var formatarWhats = produto.whats.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
        var url = "https://wa.me/55" + formatarWhats + "?text=Olá gostaria de amis informações sobre: " + produto.nome;
        window.open(url, "_blank")
      },
      ativarResponsavel(){
        if(this.InfosAluno.dataNascimento.length == 10){
          var hoje = new Date();
          var nascimento = new Date(this.InfosAluno.dataNascimento);
          let difference = hoje.getTime() - nascimento.getTime();
          let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
          var TotalAnos = TotalDays / 366
          if(TotalAnos < 18){
            this.campoResponsavel = true;
          }
          else{
            this.campoResponsavel = false;
          }
        }
        
      },
      verVideos(){
        for (var materia of this.aluno.materias) 
        {
          this.materiasPlayList.push(materia.id)
        }

        var idProfessores = [];
        for (var materia of this.aluno.pagamentos) 
        {
          idProfessores.push(materia.idProfessor)
        }
        var post = {
          idProfessores: idProfessores
        }
        func.CarregarDados('playlist/getPlayListsporMateria', post).then(data => 
          this.carregarPlayList(data.corpo)
        );
      },
      carregarPlayList(data){
        var refPlaylist = [];
        for (var play of data) 
        {
          for (var materia of play.materias) 
          {
            if(this.materiasPlayList.includes(materia.id)){
              if(refPlaylist.filter(x => x.id == play.id).length == 0){
                refPlaylist.push(play)
              }
            }
          }
        }
        this.playlists = refPlaylist
      },
      upload(idMateria) {
        var tamanhoPermitido = 1000;
        var tamanhoComprovante = event.target.files[0].size / 1000
        if(tamanhoComprovante > tamanhoPermitido){
          this.excedeuTamanhoArquivo = true;
          this.snackbar = true;
          this.snackMensagem = "Arquivo muito grande, tamanho máximo permitido 1MB";
        }
        else{
          this.excedeuTamanhoArquivo = false;
          func.onFileChange2(event).then(data => this.addComprovantes(data, idMateria));
        }
      },
      addComprovantes(dados, idMateria){
        if(this.arquivosComprovante.filter(x => x.idMateria == idMateria).length > 0){
          this.arquivosComprovante.filter(x => x.idMateria == idMateria)[0].dados = dados
        }
        else{
          this.arquivosComprovante.push(
            {
              idMateria: idMateria,
              dados: dados
            }
          )
        }
      },
      enviarComprovante(pagamentos){  
        var qtdDevendo = pagamentos.filter(x => x.status == 0).length;
        var qtdComprovantes = this.arquivosComprovante.length;
        if(qtdDevendo == qtdComprovantes){
            for(var i = 0; i < this.arquivosComprovante.length; i++){
              var post = {
                nomeAluno:  this.aluno.nome,
                id: this.aluno.id,
                comprovante: this.arquivosComprovante[i].dados,
                emailAcademia: pagamentos.filter(x => x.idMateria == this.arquivosComprovante[i].idMateria)[0]['emailProfessor'],
                idMateria: this.arquivosComprovante[i].idMateria,
                nomeMateria: pagamentos.filter(x => x.idMateria == this.arquivosComprovante[i].idMateria)[0]['materia'],
                idChatTelegram: pagamentos.filter(x => x.idMateria == this.arquivosComprovante[i].idMateria)[0]['idChatTelegram'],
              }
              func.CarregarDados('alunos/enviarComprovante', post).then(data => 
                this.localizarAlunoAposEnviarComprovante()
              );
            }

            // var envioTelegram = [];
            // console.log(pagamentos);
            // for(var i = 0; i < pagamentos.length; i++){ 
            //   if(envioTelegram.filter(x => x.idProfessor == pagamentos[i].idProfessor).length == 0){
            //     var objeto = {
            //       pixProfessor: pagamentos[i].pixProfessor,
            //       nomeProfessor: pagamentos[i].nomeProfessor,
            //       idProfessor: pagamentos[i].idProfessor,
            //       materia: pagamentos[i].materia,
            //       tokenPagamento: 
            //     }
            //     envioTelegram.push(objeto)
            //   }
            // }
            // console.log(envioTelegram);
        }
        else{
            this.snackbar = true;
            this.snackMensagem = "Necessário anexar os "+qtdDevendo+" comprovante(s)";
        }
      }, 
      localizarAlunoAposEnviarComprovante(){
          this.localizarAluno();
          this.algumAtrasado = false;
      },
      carregarVariaveisAcademia(dados){
        this.academia = dados
        var postMateriasAcademia = {
          idAcademia: dados.id
        }
        func.CarregarDados('academias/getMateriasAcademiaPorCPF', postMateriasAcademia).then(data => 
          this.carregarDadosCard(data.corpo)
        );

        //carregarProdutosLoja
        this.getProdutos(dados.id);
      },  
       getProdutos(idAcademia){
          var post = {
            idAcademia: idAcademia
          }
          func.CarregarDados('loja/getProdutosAcademia', post).then(data => 
            this.produtos = data.corpo
          );
      },
      addAluno(){
        this.InfosAluno.matricula = 'Temporaria';
        this.InfosAluno.idAcademia = this.academia.id;
        this.InfosAluno.status = 1;
        
        var ignorar = ['foto'];
        if(!this.campoResponsavel){
          ignorar.push('responsavel')
        }
        

        if(this.InfosAluno.dataNascimento != undefined && this.InfosAluno.dataNascimento != null && this.InfosAluno.dataNascimento.length < 10){
            this.snackMensagem = "Data de nascimento inválida, formato correto ##/##/####";
            this.snackbar = true;
            return
        }

        var erros = func.NovoVerificarJSONNull(this.InfosAluno, ignorar);
        if(erros.length > 0){ 
          this.snackMensagem = erros;
          this.snackbar = true;
          return
        }

        

        this.InfosAluno.dataNascimento = func.formatarDataPadraoBD(this.InfosAluno.dataNascimento, false);
        this.InfosAluno.matricula = this.construirmatricula();
        func.resizedataURL(this.InfosAluno.foto).then(data => {
            this.InfosAluno.foto =  data;
            func.CarregarDados('alunos/addAlunoPorCPF', this.InfosAluno).then(data => 
              this.recarregarAposCadastro()
            );
          }
        );
      },
      recarregarAposCadastro(){
        this.snackMensagem = "Cadastro feito com sucesso !"
        this.snackbar = true;
        this.aluno.email = this.InfosAluno.email;
        this.localizarAluno();
      },
      procurarCEP(event){
        func.CarregarCEP(this.InfosAluno.cep).then(data => this.carregarDadosCEP(data));
      },
      carregarDadosCEP(dados) {
        this.InfosAluno.rua = dados.logradouro
        this.InfosAluno.bairro = dados.bairro
        this.InfosAluno.cidade = dados.localidade
        this.InfosAluno.estado = dados.uf;
        document.getElementById("campoNumero").focus();
      },
      
      funcaoUploadImage(e){
        func.onFileChange(e).then(data => this.InfosAluno.foto = data);
      },
      construirmatricula(){
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = ' ';
        const charactersLength = 6;
        for ( let i = 0; i < charactersLength; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 6));
        }
        return result.replace(/\s+/g, '');
      },
      carregarDadosCard(dados){
          this.statusAluno = func.CarregarStatusAluno();
          this.converterMaterias(dados);

          if(this.academia.diaFixoPagamento != null && this.academia.diaFixoPagamento != ''){
            this.diasPagamento = [this.academia.diaFixoPagamento]
          }
          else{
            this.diasPagamento = func.CarregarDiasPagamentoAreaAluno();
          }
          
          this.sexo = func.CarregarSexos();
          
          const urlParams = new URLSearchParams(window.location.search);
          const emailAluno = urlParams.get('email');
          if(emailAluno != null){
            this.aluno.email = emailAluno;
            this.localizarAluno()
          }


          this.filtroEmAtraso = false;
          this.filtroRegular = false;

          this.filtroAtivo = false;
          this.filtroDesativado = false;
      },
      converterMaterias(dados){
        this.materias = dados;
      },
     
      confirmarEvento(evento){
        var post = {
          idAluno: this.aluno.id,
          idEvento: evento.id,
        }
        if(this.CadastroPreselecionado != null){
          for(var i = 0; i < this.CadastroPreselecionado.corpo[0].eventos.filter(x => x.id == evento.id).length; i++){
            this.CadastroPreselecionado.corpo[0].eventos = this.CadastroPreselecionado.corpo[0].eventos.filter(x => x.id != evento.id);
          }
        }
        func.CarregarDados('eventos/aceitarConvite', post).then(data => 
          this.localizarAluno()
        );
      },
      checkIn(check){
        var post = {
          idAluno: this.aluno.id,
          idAcademia: this.aluno.idAcademia,
          idAula: check.id
        }

        if(this.CadastroPreselecionado != null){
          for(var i = 0; i < this.CadastroPreselecionado.corpo[0].infoMateria.filter(x => x.id == check.id).length; i++){
            this.CadastroPreselecionado.corpo[0].infoMateria = this.CadastroPreselecionado.corpo[0].infoMateria.filter(x => x.id != check.id);
          }
        }
        func.CarregarDados('checkIns/addCheckIn', post).then(data => 
          this.localizarAluno()
        );
      },
      localizarAluno(){ 
        if(this.aluno.email.includes('@')){
          var post = {
            idAcademia: this.academia.id,
            email: this.aluno.email
          }
          func.CarregarDados('alunos/getAlunoPorCPF', post).then(data => 
            this.carregarVariaveisAluno(data)
          );
        }
      },

      utilizarCadastro(cadastro){
        var corpo = [];
        corpo.push(cadastro)
        var novo = {
          sucesso: true,
          corpo: corpo
        }
        this.dialogEscolhaOCadastro = false;
        this.carregarVariaveisAluno(novo);
        this.CadastroPreselecionado = novo;
      },

      carregarVariaveisAluno(dados){
        // if(this.CadastroPreselecionado != null){
        //     this.formProcuraCPF = false;
        //     this.aluno.id = this.CadastroPreselecionado.corpo[0].id;
        //     this.aluno.nome = this.CadastroPreselecionado.corpo[0].nome;
        //     this.aluno.idAcademia = this.CadastroPreselecionado.corpo[0].idAcademia; 
        //     this.aluno.materias = this.CadastroPreselecionado.corpo[0].materias;
        //     this.aluno.eventos = this.CadastroPreselecionado.corpo[0].eventos;
        //     this.aluno.infoMateria =  this.CadastroPreselecionado.corpo[0].infoMateria;
        //     this.aluno.pagamentos = this.CadastroPreselecionado.corpo[0].pagamentos;
        //   return
        // }

        if(dados.sucesso){
          // if(dados.corpo.length > 1){
          //   this.dialogEscolhaOCadastro = true;
          //   for ( let i = 0; i < dados.corpo.length; i++ ) {
          //       this.encontradosDoisCadastros.push(dados.corpo[i])
          //   }
          // }
          // else{
            this.formProcuraCPF = false;
            this.aluno.id = dados.corpo[0].id;
            this.aluno.foto = dados.corpo[0].foto;
            this.aluno.nome = dados.corpo[0].nome;
            this.aluno.idAcademia = dados.corpo[0].idAcademia; 
            this.aluno.materias = dados.corpo[0].materias;
           
            this.aluno.eventos = dados.corpo[0].eventos;
            this.aluno.infoMateria =  dados.corpo[0].infoMateria;
            this.aluno.pagamentos = dados.corpo[0].pagamentos;
            this.aluno.tipoAluno = dados.corpo[0].tipoAluno;
            
            for (var pagamento of this.aluno.pagamentos) 
            {
              if(pagamento.status == 0){
                this.algumAtrasado = true
              }
            }

          //}
        }
        else{
          this.InfosAluno.email = this.aluno.email;
          this.formProcuraCPF = false;
          this.InfosAluno.cpf = this.aluno.email;
          this.aluno =  {
            email: this.aluno.email,
            nome: null,
            id: null,
            eventos: [],
            infoMateria: [],
            tipoAluno: null,
            pagamento: {
              situacao: null,
              dataPagamento: null
            }
          }
        }
        
      }
    }
  }

</script>
  

  