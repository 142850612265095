<template>
  <v-toolbar >
        <v-toolbar-title class="text-grey" style="text-align: left;">
          Perfil
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>

  <div  class="App-Conteudo" id="App-Conteudo">
    <v-snackbar
      v-model="snackVisivel"
    >
      {{snackMensagem}}
      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="snackVisivel = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
    <div class="App-Conteudo-Comp">
      <v-snackbar
      v-model="snackbarPerfilSalvo"
    >
      {{snackbarPerfilSalvoMensagem}}
      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="snackbarPerfilSalvo = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

      <div class="App-Conteudo-Comp-Box">
        <v-container>
          <!-- <v-row v-if="pagamentosFaltantes.length > 0">
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
                <v-alert color="info" class="text-center" >
                  Pagamento de mensalidade não identificado, clique no link abaixo para continuar utilizando todas as funcionalidades do app <br />
                  <v-col class="text-center" cols="12" style="color: black;">
                    <v-btn text  v-on:click="pagar()">PAGAR</v-btn>
                  </v-col>
                </v-alert>
            </v-col>
          </v-row> -->
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
              <div class="logoAcademiaPerfil">
                <img v-bind:src="CT.logo" />
                <input type="file" @change="funcaoUploadImage" />
              </div>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
              > 
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="CT.nome"
                  label="Nome"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <v-textarea 
                label="Descrição"  
                v-model="CT.descricao"
                rows="3"
              ></v-textarea>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  readonly 
                  v-model="CT.linkAlunos"
                  label="Link alunos"
                  required
                ></v-text-field>
              </v-col>
            </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Contato
                <hr />
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Email"
                  v-model="CT.email"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Celular"
                  v-model="CT.celular"
                  hint=""
                  persistent-hint
                  required
                  v-mask="'(##) #####-####'"
                ></v-text-field>
              </v-col>

              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Serviços
                <hr />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="PIX"
                  v-model="CT.pix"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-autocomplete
                  v-model="CT.materias"
                  :items="materiasFull"
                  item-title="nome"
                  item-value="id"
                  label="Matérias"
                  multiple
                ></v-autocomplete>
              </v-col>

              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Endereço
                <hr />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="CEP"
                  v-model="CT.cep"
                  required
                  @change="procurarCEP($event)"
                  v-mask="'#####-###'"
                ></v-text-field>
              </v-col>

              <v-col cols="10">
                <v-text-field
                  label="Rua"
                  v-model="CT.rua"
                  required
                  
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Numero"
                  v-model="CT.numero"
                  type="number"
                  id="campoNumero"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Bairro"
                  v-model="CT.bairro"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Cidade"
                  v-model="CT.cidade"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Estado"
                  v-model="CT.estado"
                  required
                ></v-text-field>
              </v-col>

              
              
            </v-row>
            
            <v-btn
              color="primary"
              class="flutuarDireita"
              append-icon="mdi-content-save-all"
              v-on:click="salvarDados()"
            >
              Salvar
            </v-btn>
           
          </v-container>
      </div>
          
    </div>
  </div>
  <v-dialog
        v-model="dialogAreaRestrita"
        
        width="auto"
        persistent
      >
        <v-card>
          <v-card-text>
            Area restrita para usuario administrativo, fale com o administrador do sistema
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="sair()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>
  
<script>


import {func} from '../assets/functions.js';

  export default {
    name: 'Perfil',
    components:{ 
      
    },
    props: {
      
    },
    async created(){
      func.CarregarDados('academias/verificarPagamento').then(data => 
        this.pagamentosFaltantes = data.corpo
      );

      func.CarregarDados('professor/getProfessor').then(data => this.comecarCarregamento(data.corpo) );
    },
    data() {
      return {
        dialogAreaRestrita: false,
        pagamentosFaltantes: [],
        snackVisivel: null,
        snackMensagem: null,
        snackbarPerfilSalvo: false,
        snackbarPerfilSalvoMensagem: "Perfil Salvo",
        materiasFull: [],
        url: null,
        logobase64: null,
        CT: {
          linkAlunos: null,
          id: null,
          logo: 'https://d3c1e34umh9o6d.cloudfront.net/image/filename/789691/md_Screenshot_3.png',
          nome: null,
          descricao: null,
          email: null,
          logo: null,
          materias: [],
          rua: null, 
          numero: null,
          bairro: null,
          cidade: null,
          estado: null,
          cep: null,
          tokenPublico: null,
          pix: null
        }
      }
    },
    methods: {
      sair(){
        this.$router.push('estatisticas')
      },
      comecarCarregamento(dados){
        console.log(dados)
        if(dados.adm == 0){
          this.dialogAreaRestrita = true;
          return
        }
       
        func.CarregarDados('academias/getAcademia').then(data => this.carregarDadosAcademia(data.corpo) );
      },
      pagar(){ 
        console.log(this.pagamentosFaltantes[0].split('T')[0])
        var post = {
          dataReferencia:  this.pagamentosFaltantes[0].split('T')[0]
        }
        
        func.CarregarDados('jobs/create_preference', post).then(data => 
          this.irPaginaPagamento(data)
        );
      },
      async irPaginaPagamento(data){
        window.location.assign(data.id.init_point);
      },
      funcaoUploadImage(e){
        func.onFileChange(e).then(data => this.CT.logo = data);
      },
      salvarDados(){
        var ignorar = ['servicoCobranca', 'diaFixoPagamento'];
        var erros = func.NovoVerificarJSONNull(this.CT, ignorar);
        console.log(erros)
        if(erros.length > 0){
          this.snackMensagem = "Todos os campos devem estar preenchidos"
          this.snackVisivel = true;
          return
        }

        func.resizedataURL(this.CT.logo).then(data => {
            this.CT.logo =  data;
            func.CarregarDados('academias/alterAcademia', this.CT).then(data =>
              this.recarregarAcademiaAposSalvar(data)
            );
          }
        );

        
      },
      recarregarAcademiaAposSalvar(dados){
        if(dados.sucesso){
          this.snackbarPerfilSalvoMensagem = "Perfil Salvo";
          this.snackbarPerfilSalvo = true;
          func.CarregarDados('academias/getAcademia').then(data => this.carregarDadosAcademia(data.corpo));
        }
        else{
          this.snackbarPerfilSalvoMensagem = "Falha ao Salvar";
          this.snackbarPerfilSalvo = true;
        }
      },

      carregarDadosAcademia(dados){  
        this.CT = dados;
        if(this.CT.logo == null){
          this.CT.logo = 'https://d3c1e34umh9o6d.cloudfront.net/image/filename/789691/md_Screenshot_3.png'
        }
        func.CarregarDados('dominio/getMaterias').then(data => 
            this.carregarMaterias(data.corpo)
        );
        this.CT.linkAlunos = window.location.origin + "/alunocheck?t=" +  dados.tokenPublico;
      },
      carregarMaterias(dados){
        this.materiasFull = dados;
        func.CarregarDados('academias/getMateriasAcademia').then(data => 
          this.converterMaterias(data.corpo)
        );
      },
      converterMaterias(dados){
        var materiasSelect = [];
        for (var materia of dados) 
        {
          materiasSelect.push(materia.id)
        }
        this.CT.materias = materiasSelect;
      },
      procurarCEP(event){
        func.CarregarCEP(this.CT.cep).then(data => this.carregarDadosCEP(data));
      },
      carregarDadosCEP(dados) {
        this.CT.rua = dados.logradouro
        this.CT.bairro = dados.bairro
        this.CT.cidade = dados.localidade
        this.CT.estado = dados.uf;
        document.getElementById("campoNumero").focus();
      },
    }
  }

</script>
  

  