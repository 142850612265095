<!-- <style>
#app {
  position: absolute; 
  overflow: hidden;
}
</style> -->
<template>
  <v-theme-provider theme="dark" with-background class="pa-10" style="height: 100%; width: 100%;">
  <v-snackbar
      v-model="snackbar"
      multi-line
    >
      {{snackMensagem}}

      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  
<v-dialog v-model="dialogObrigado">
  <v-card>
    <v-card-text class="text-center">
      <h2>Bom treino</h2>
    </v-card-text>
  </v-card>
</v-dialog>

<v-dialog v-model="dialogNaoEncontrado">
  <v-card>
    <v-card-text class="text-center">
      <h2>Email incorreto/não encontrado.</h2>
    </v-card-text>
  </v-card>
</v-dialog>

<v-dialog v-model="dialogDigiteSeuEmail">
  <v-card>
    <v-card-text class="text-center">
      <h2>Digite seu email.</h2>
    </v-card-text>
  </v-card>
</v-dialog>

<v-dialog v-model="dialogDigiteSeuContato">
  <v-card>
    <v-card-text class="text-center">
      <h2>Digite seu nome e contato.</h2>
    </v-card-text>
  </v-card>
</v-dialog>

<h2 v-if="formProcuraCPF != true" @click="voltarTudo()">Voltar tela inicial</h2>
    <div class="formAlunoCheck">
      <h1>{{ academia.nome }}</h1>
      <!-- <img style="width: 50px;height: 50px;border-radius:200px" v-bind:src="academia.logo" /> -->
      <br /><br />
      <div v-if="formProcuraCPF">
        <h3>Digite seu email:</h3><br v-if="aluno.id == 0 || aluno.id == null"/>
        <v-text-field
          v-model="aluno.email"
          style="width: 500px; margin: auto"
          width="500"
          class="centered-input"  
        ></v-text-field>
        <br v-if="aluno.id == 0 || aluno.id == null"/>
        <br v-if="aluno.id == 0 || aluno.id == null"/>
      </div>

      <div v-if="formAulaExperimental">
        <h3>Digite seu nome:</h3>
        <v-text-field
          v-model="experimental.nome"
          style="width: 500px; margin: auto"
          width="500"
          class="centered-input"  
        ></v-text-field>
        <h3>Digite seu celular:</h3>
        <v-text-field
          v-model="experimental.contato"
          style="width: 500px; margin: auto"
          width="500"
          v-mask="'(##) #####-####'"
          class="centered-input"  
        ></v-text-field>
        <h3>Escolha a aula:</h3>

        <v-card color="grey-darken-3" v-for="item in materiasExperimental" :key="item.id" style="padding: 10px">
          <h3>{{item.nome}}</h3>
          <h4>{{item.horario}}</h4>
          <v-btn 
              color="primary"
              height="60"
              min-width="164"
              @click="checkExperimental(item)"
            >
              CHECKIN
            </v-btn>
        </v-card>
      </div>
      
      <v-btn 
        v-if="(aluno.id == 0 || aluno.id == null) && formProcuraCPF == true"
        color="primary"
        height="100"
        size="x-large"
        min-width="500"
        @click="localizarAluno()"
        ><b>Entrar</b>
      </v-btn>

        <br v-if="(aluno.id == 0 || aluno.id == null) && formProcuraCPF == true"/>
        <br v-if="(aluno.id == 0 || aluno.id == null) && formProcuraCPF == true"/>
        <h2 v-if="(aluno.id == 0 || aluno.id == null) && formProcuraCPF == true">ou</h2>
        <br v-if="(aluno.id == 0 || aluno.id == null) && formProcuraCPF == true"/>
      
      <v-btn v-if="(aluno.id == 0 || aluno.id == null) && formProcuraCPF == true"
              color="primary"
              height="100"
              min-width="500"
              @click="habilitarformExperimental()"
              size="x-large"
              ><b>Aula experimental</b></v-btn>
      

      <div v-if="aluno.id != 0 && aluno.id != null">
        <h2>Bem vindo</h2>
        <h2>{{aluno.nome}}</h2><br />
        <v-card color="grey-darken-3"  v-for="item in aluno.infoMateria" :key="item.id" style="padding: 10px">
          <h3>{{item.nome}}</h3>
          <h4>{{item.horario}}</h4>
          <v-btn 
              color="primary"
              height="60"
              min-width="164"
              @click="checkIn(item)"
            >
              CheckIn
            </v-btn>
        </v-card>
        <h1 v-if="aluno.infoMateria.length == 0">Nenhuma aula encontrada</h1>
      </div>
    </div>
    
      
    
  </v-theme-provider>
  
</template>
  
<script>


import {func} from '../assets/functions.js';
  export default {
    name: 'AlunoCheck',
    components:{ 
      
    },
    props: {
      
    },
    async created(){
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('t');
      
      if(myParam){
        var post = {
          token: myParam
        }
        
        func.CarregarDados('academias/getAcademiaTokenPublico', post).then(data => 
          this.carregarVariaveisAcademia(data.corpo)
        );
      }


    },
    data() {
      return {
        dialogDigiteSeuContato: false,
        dialogObrigado: false,
        dialogNaoEncontrado: false,
        dialogDigiteSeuEmail: false,
        contadorPagamentos: 1,
        comprovante: null,
        formProcuraCPF: true,
        formAulaExperimental: false,
        InfosAluno: {
            matricula: null,
            nome: null,
            dataNascimento: null,
            celular: null,
            email: null,
            sexo: null,
            materias: null,
            diaPagamento: null,
            cep: null,
            rua: null,
            numero: null,
            bairro: null,
            cidade: null,
            estado: null,
            foto: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png',
            status: null,
            //cpf: null,
            idAcademia: null
        },
        experimental: {
          nome: null,
          contato: null
        },
        materiasExperimental: [],
        aluno: [],
        professoresAluno: [],
        academia: {
          id: null,
          nome: null,
          logo: null,
          materias: [],
          pix: null,
          email: null
        },
        snackbar: false,
        infosLogin: {
          email: null,
          senha: null
        },
        snackVisivel: null,
        snackMensagem: null,
        mostrarheader: true,
        materias: [],
        sexo: [],
        diasPagamento: [],
        excedeuTamanhoArquivo: false
      }
    },
    
    methods: {  
      habilitarformExperimental(){
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('t');
        var post = {
          tokenPublico: myParam
        }
        func.CarregarDados('academias/getAulasDoDia', post).then(data => 
          this.materiasExperimental = data.corpo
        );

        this.formAulaExperimental = true;
        this.formProcuraCPF = false;


      },
      upload(img) {
        var tamanhoPermitido = 1000;
        var tamanhoComprovante = img.target.files[0].size / 1000
        if(tamanhoComprovante > tamanhoPermitido){
          this.excedeuTamanhoArquivo = true;
          this.snackbar = true;
          this.snackMensagem = "Arquivo muito grande, tamanho máximo permitido 1MB";
        }
        else{
          this.excedeuTamanhoArquivo = false;
          func.onFileChange2(img).then(data => this.addComprovantes(data));
        }
      },
      addComprovantes(dados){
        this.comprovante = dados;
        
      },

      carregarVariaveisAcademia(dados){
        this.academia = dados
        var postMateriasAcademia = {
          idAcademia: dados.id
        }
        func.CarregarDados('academias/getMateriasAcademiaPorCPF', postMateriasAcademia).then(data => 
          this.carregarDadosCard(data.corpo)
        );
      },  
      addAluno(){
        this.InfosAluno.matricula = 'Temporaria';
        this.InfosAluno.idAcademia = this.academia.id;
        this.InfosAluno.status = 1;
       
        if(func.verificarJSONNull(this.InfosAluno)){
          this.snackMensagem = "Todos os campos devem estar preenchidos !"
          this.snackbar = true;
          return
        }

        this.InfosAluno.dataNascimento = func.formatarDataPadraoBD(this.InfosAluno.dataNascimento, false);
        this.InfosAluno.matricula = this.construirmatricula();
        func.resizedataURL(this.InfosAluno.foto).then(data => {
            this.InfosAluno.foto =  data;
            func.CarregarDados('alunos/addAlunoPorCPF', this.InfosAluno).then(data => 
              this.recarregarAposCadastro()
            );
          }
        );
      },
      recarregarAposCadastro(){
        this.snackMensagem = "Cadastro feito com sucesso !"
        this.snackbar = true;
        this.aluno.email = this.InfosAluno.email;
        
        this.localizarAluno();
      },
      procurarCEP(event){
        func.CarregarCEP(this.InfosAluno.cep).then(data => this.carregarDadosCEP(data));
      },
      carregarDadosCEP(dados) {
        this.InfosAluno.rua = dados.logradouro
        this.InfosAluno.bairro = dados.bairro
        this.InfosAluno.cidade = dados.localidade
        this.InfosAluno.estado = dados.uf;
        document.getElementById("campoNumero").focus();
      },
      
      funcaoUploadImage(e){
        func.onFileChange(e).then(data => this.InfosAluno.foto = data);
      },
      construirmatricula(){
        var novaMatricula = '';
        for (var materia of this.InfosAluno.materias) 
        {
            var materiaAcademia = this.materias.filter(x => x.id == materia)[0];
            novaMatricula = novaMatricula + materiaAcademia.nome.charAt(0);
        }
        novaMatricula = novaMatricula + this.InfosAluno.email.substr(0, 3);
        return novaMatricula;
      },
      carregarDadosCard(dados){
          this.statusAluno = func.CarregarStatusAluno();
          this.converterMaterias(dados);
          this.diasPagamento = func.CarregarDiasPagamento();
          this.sexo = func.CarregarSexos();
          
          this.filtroEmAtraso = false;
          this.filtroRegular = false;

          this.filtroAtivo = false;
          this.filtroDesativado = false;
      },
      converterMaterias(dados){
        this.materias = dados;
      },
     
      confirmarEvento(evento){
        var post = {
          idAluno: this.aluno.id,
          idEvento: evento.id,
        }
        func.CarregarDados('eventos/aceitarConvite', post).then(data => 
          this.localizarAluno()
        );
      },
      checkIn(check){
        var post = {
          idAluno: this.aluno.id,
          idAcademia: this.aluno.idAcademia,
          idAula: check.id
        }

        this.voltarTelaInicial()
        func.CarregarDados('checkIns/addCheckIn', post).then(data => 
          this.voltarTelaInicial(data)
        );
      },
      checkExperimental(aula){
        if(
          this.experimental.nome == "" || 
          this.experimental.nome == null ||
          this.experimental.contato == "" || 
          this.experimental.contato == null 
          ){
            this.dialogDigiteSeuContato = true
            return
        }

        var post = {
          idAula: aula.id,
          nome: this.experimental.nome,
          contato: this.experimental.contato
        }
        func.CarregarDados('checkIns/addCheckInExperimental', post).then(data => 
          this.aulaExperimentalCriada()
        );
        
      },
      aulaExperimentalCriada(data){
        this.experimental.nome = null;
        this.experimental.contato = null;
        this.formAulaExperimental = false;
        this.voltarTelaInicial(data)
      },

      voltarTudo(){
        window.location.reload();
      },
      voltarTelaInicial(data, mostrarDialog = false){
          this.aluno = [{
            nome: null,
            id: 0,
            idAcademia: null,
            eventos: [],
            infoMateria: [],
            email: '',
            pagamento: {
              situacao: null,
              dataPagamento: null
            }
          }]
          if(mostrarDialog){
            this.dialogObrigado = true;
          }
          
          this.formProcuraCPF = true;
          //this.localizarAluno()
      },
      localizarAluno(){ 
        this.contadorPagamentos = 1;
        if(this.aluno.email.includes('@')){
          var post = {
            idAcademia: this.academia.id,
            email: this.aluno.email
          }
          func.CarregarDados('alunos/getAlunoPorCPFTotem', post).then(data => 
            this.carregarVariaveisAluno(data)
          );
        }
        else{
          this.dialogDigiteSeuEmail = true
        }
      },
      carregarVariaveisAluno(dados){
        console.log(dados);

        if(dados.sucesso == true){
          this.formProcuraCPF = false;
          for (let i = 0; i < dados.corpo.length; i++ ) {
            var objeto = {
              id: dados.corpo[i].id,
              nome: dados.corpo[i].nome,
              idAcademia: dados.corpo[i].idAcademia,
              materias: dados.corpo[i].materias,
              eventos: dados.corpo[i].eventos,
              infoMateria: dados.corpo[i].infoMateria,
              pagamentos: dados.corpo[i].pagamentos
            }
            this.aluno.push(objeto)
            // this.aluno.id = dados.corpo.id;
            // this.aluno.nome = dados.corpo.nome;
            // this.aluno.idAcademia = dados.corpo.idAcademia; 
            // this.aluno.materias = dados.corpo.materias;
            // this.aluno.eventos = dados.corpo.eventos;
            // this.aluno.infoMateria =  dados.corpo.infoMateria;
            // this.aluno.pagamentos = dados.corpo.pagamentos;
          }
        }
        else{
          this.dialogNaoEncontrado = true
        }
      }
    }
  }

</script>
  

  