
import {configs} from '../../dev.env.js';

function FinalizarCarregamento(){
    try { 
        document.getElementById('carregandoPost').innerHTML = "";
    } catch (error) {
        console.error(error);
    }

    var element =  document.getElementById("conteudoFull");
    element.classList.remove("fundoCarregando");
    document.getElementsByTagName('body')[0].classList.remove('noClick');
}

function Carregar(){
    try { 
        var elemento2 = '<div class="v-overlay-container"><div class="v-overlay v-overlay--active v-theme--light v-locale--is-ltr v-dialog v-overlay--scroll-blocked" aria-modal="true" role="dialog" style="z-index: 2400;"><!----><div class="v-overlay__content" style="width: auto;"><div class="v-card v-theme--light bg-primary v-card--density-default v-card--variant-elevated"><!----><div class="v-card__loader"><div class="v-progress-linear v-theme--light" role="progressbar" aria-hidden="true" aria-valuemin="0" aria-valuemax="100" style="top: 0px; height: 0px; --v-progress-linear-height: 2px; left: 50%; transform: translateX(-50%);"><!----><div class="v-progress-linear__background" style="width: 100%;"></div><div class="v-progress-linear__indeterminate"><div class="v-progress-linear__indeterminate long"></div><div class="v-progress-linear__indeterminate short"></div></div><!----></div></div><!----><!----><div class="v-card-text"> Processando ... <div class="v-progress-linear v-progress-linear--active v-theme--light mb-0" role="progressbar" aria-hidden="false" aria-valuemin="0" aria-valuemax="100" style="top: 0px; height: 4px; --v-progress-linear-height: 4px; left: 50%; transform: translateX(-50%);"><!----><div class="v-progress-linear__background bg-white" style="width: 100%;"></div><div class="v-progress-linear__indeterminate"><div class="v-progress-linear__indeterminate long bg-white"></div><div class="v-progress-linear__indeterminate short bg-white"></div></div><!----></div></div><!----><!----><span class="v-card__underlay"></span></div></div></div><!----><!----></div>';
        document.getElementById('carregandoPost').innerHTML = elemento2;
      } catch (error) {
        console.error(error);
      }

      var element = document.getElementById("conteudoFull");
      element.classList.add("fundoCarregando");
      document.getElementsByTagName('body')[0].classList.add('noClick');
}
function enviarRetorno(retorno){
    FinalizarCarregamento()
    return retorno;
}
function converterBase64(file){
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(
            reader.result
        );
        reader.onerror = error => reject(error);
    });
}

export const func = {
    async resizedataURL(base64Str, width = 150, height = 150)
    {
        //console.log(base64Str.substring(0, 10))
        if(base64Str.substring(0, 10) != 'data:image'){
            console.log('parou')
            return base64Str
        }
        console.log('passou')
        var img = new Image();
        img.src = base64Str;
        var canvas = document.createElement('canvas');
        var MAX_WIDTH = width;
        var MAX_HEIGHT = height;
        var width = img.width;
        var height = img.height;

        if (width > height) {
            if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
            }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        return canvas.toDataURL();  
    },
    formatarDataPadraoBR(data, comHora = true){
        var dia = String(new Date(data).getDate()).padStart(2, '0');
        var mes = String(new Date(data).getMonth() + 1).padStart(2, '0') ;
        var ano = new Date(data).getFullYear();

        var formatada = dia + "/" + mes + "/" + ano;
        if(comHora){
          var hora = String(new Date(data).getHours()).padStart(2, '0') ;
          var minuto = String(new Date(data).getMinutes()).padStart(2, '0') ;
          formatada = formatada + " " + hora + ":" + minuto;
        }

        return formatada
      },
      formatarDataPadraoBD(data, comHora = true) {
        var diaref = data.split('/')[0];
        var mesref = data.split('/')[1];
        var anoref = data.split('/')[2];

        if(comHora){
            anoref = data.split('/')[2].split(' ')[0];
        }
        
        var dia = diaref.padStart(2, '0');
        var mes = mesref.padStart(2, '0') ;
        var ano = anoref;

        
        var formatada = ano + "/" + mes + "/" + dia;
        if(comHora){
            var t =  data.split(' ')[1];
            var hora = t.split(':')[0]
            var minuto = t.split(':')[1]
            formatada = formatada + "T" + hora + ":" + minuto + ":00";
        }
        return formatada
    },
    verificarJSONNull(objeto, ignorar = []) {
        var objetoSemIgnorar = {};
        for (var key in objeto) {
            if (objeto.hasOwnProperty(key)) {
                if(ignorar.includes(key)){
                    
                }
                else{
                    objetoSemIgnorar[key] = objeto[key]
                }
            }
        }

        const values = Object.values(objetoSemIgnorar);
        var nulos = values.filter(x => x == null);
        var vazios = values.filter(x => x == "");
        var nulosText = values.filter(x => x == "null");
        if(nulos.length == 0 && vazios.length == 0 && nulosText.length == 0){
            return false
        }
        else{
            return true
        }
    },
    NovoVerificarJSONNull(objeto, ignorar = []) {
        var erros = [];
        var objetoSemIgnorar = {};
        for (var key in objeto) {
            if (objeto.hasOwnProperty(key)) {
                if(ignorar.includes(key)){
                    
                }
                else{
                    objetoSemIgnorar[key] = objeto[key]
                }
            }
        }

        const values = Object.values(objetoSemIgnorar);
        var keys = Object.keys(objetoSemIgnorar);

        for(var i = 0; i < keys.length; i ++){
            if(objetoSemIgnorar[keys[i]] == null || objetoSemIgnorar[keys[i]] == ""){
                erros.push("Campo " + keys[i] + " não pode estar vazio") 
            }
        }
        return erros;
    },
    onFileChange(e) {
        const file = e.target.files[0];
        if(file != undefined){
            this.url = URL.createObjectURL(file);
            return new Promise((resolve, reject) => {
                converterBase64(file).then(data => resolve (data));
            });
        }
    },
    onFileChange2(e) {
        const file = e.target.files[0];
        if(file != undefined){
            this.url = URL.createObjectURL(file);
            return new Promise((resolve, reject) => {
                converterBase64(file).then(data => resolve (data));
            });
        }
    },
    CarregarDiasPagamento: () => { 
        return ['01', '05', '10', '15', '20', '25', 'Gympass', 'Staff', 'Totalpass'];
    },
    CarregarDiasPagamentoAreaAluno: () => { 
        return ['01', '05', '10', '15', '20', '25', 'Gympass', 'Totalpass'];
    },
    CarregarStatusAluno: () => { 
        return [
            {
              id: 1,
              nome: 'Ativo'
            },
            {
              id: 2,
              nome: 'Desativado (pelo Staff)'
            },
            {
                id: 3,
                nome: 'Desativado (pelo sistema)'
            }
          ];
    },
    CarregarSexos: () => { 
        return ['M', 'F'];
    },
    CarregarCEP: (cep) => {
        var CEPPrefix = "https://viacep.com.br/ws/" + cep + "/json/";
        return fetch(CEPPrefix).then(response => response.json()).then(data => data);
    },
    CarregarDados: (url, postData = null) => {
        Carregar();
        var APIprefix = configs.API;
        APIprefix = APIprefix + url;
        //console.log(APIprefix)
        //console.log(APIprefix);
        return fetch(APIprefix, {
            method: "POST",
            body: JSON.stringify(postData),
            headers: {
                token: localStorage.getItem('DojoMasterToken'),
                tokenProfessor: localStorage.getItem('DojoMasterProfessor'),
            }
        }).then(response => response.json()).then(data => enviarRetorno(data))
    },
    Carregar: (data) => {
        // var element = document.getElementById("App-Conteudo");
        // element.classList.add("fundoCarregando");
        // var elemento = "<div class='carregando'></div>"
        // document.getElementsByTagName('body')[0].classList.add('noClick');

     },
     FinalizarCarregamento: (data) => { 
        var element = document.getElementById("App-Conteudo");
        element.classList.remove("fundoCarregando");
        document.getElementsByTagName('body')[0].classList.remove('noClick');
     },
 }