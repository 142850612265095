<template>

  <v-theme-provider theme="dark" with-background class="pa-10" style="height: 100%; width: 100%;">
  
  </v-theme-provider>
</template>
  
<script>



  export default {
    name: 'Referee',
    components:{ 
       
    },
    props: {
      
    },
    async created(){
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('id');
      if(myParam){
      }
    },
    data() {
      return {
        
      }
    },
    
    methods: {  
      
        
      
    }
  }

</script>
  

  