<template>
  <div  class="App-Conteudo">
    <div class="HeaderPage">
      <label class="HeaderPageLabel">Configurações</label>
    </div>
    <div class="App-Conteudo-Comp">

    </div>
  </div>


</template>
  
<script>

  export default {
    name: 'Home',
    components: {
        
    },
    props: {
      
    },
    async created(){

    },
    data() {
      return {
        
      }
    }
  }

</script>
  

  