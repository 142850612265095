<template>

  <v-btn block color="primary" @click="adicionarPagamento()" v-if="jaCriado == 0 && mespassado == 0"><b>ADICIONAR PAGAMENTO</b></v-btn>
  <div style=" width: 100%; padding: 20px">
    <h3 v-if="jaCriado == 1" style="margin-top: 20px"><b>PAGAMENTO JÁ ADICIONADO</b></h3>
    <h3 v-if="mespassado == 1" style="margin-top: 20px"><b>ESTE LINK É REFERENTE AO MÊS PASSADO, PARA ADICIONAR PAGAMENTOS DO MÊS ANTERIOR, POR FAVOR UTILIZE O SITE</b></h3>
    <h3>{{nomeAluno}}</h3>
    <h4>Dia de pagamento: {{diaPagamento}}</h4>
    <h4>Mes: {{mes}}</h4>
    <h4 v-if="jaCriado == 0" style="color: red">Em atraso</h4>
  </div>
    

</template>
  
<script>


import {func} from '../assets/functions.js';
import VuePdfEmbed from 'vue-pdf-embed'
  export default {
    name: 'adicionarPagamento',
    components:{ 
        VuePdfEmbed
    },
    props: {
      
    },
    async created(){
      this.carregarComprovante();
    },
    data() {
      return {
        matricula: null,
        mes: false,
        token: null,
        jaCriado: false,
        mespassado: false,
        nomeAluno: null,
        diaPagamento: null,
        professor: null
      }
    },
    methods: {  
      carregarComprovante(){
        const urlParams = new URLSearchParams(window.location.search);
        this.matricula = urlParams.get('aluno');
        this.mes = urlParams.get('mes');
        this.token = urlParams.get('tokenAcademia');
        this.nomeAluno = urlParams.get('nomeAluno');
        this.diaPagamento = urlParams.get('diaPagamento');
        this.professor = urlParams.get('professor');

        var post = {
          matricula: this.matricula,
          mes: this.mes,
          token: this.token,
          professor: this.professor
        }
        func.CarregarDados('alunos/verificarPagamentoDoMes', post).then(data => 
          this.carregarVariaveisComprovante(data)
        )
      },
      async carregarVariaveisComprovante(dados){
        if(dados.sucesso){
          if((new Date().getMonth()) + 1 > this.mes){
              this.mespassado = true;
          }
          else{
            if(dados.corpo.length > 0){
              this.jaCriado = true;
            }
            else{
              this.jaCriado = false;
            }
          }
          
        }
      },
      adicionarPagamento(){
         var post = {
          matricula: this.matricula,
          mes: this.mes,
          token: this.token,
          professor: this.professor
        }
        func.CarregarDados('alunos/adicionarPagamento', post).then(data => 
          this.carregarComprovante()
        )
      }
    }
  }

</script>
  

  