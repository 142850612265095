<template>
    <v-toolbar>
        <v-toolbar-title class="text-grey" style="text-align: left;">
          Eventos
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-on:click="dialogEvents = true">
          <v-icon>mdi-calendar-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>

  <div  class="App-Conteudo" id="App-Conteudo">

    <div class="App-Conteudo-Comp">
      <div class="App-Conteudo-Comp-Box">
        
  <v-dialog
      v-model="dialogEvents"
      width="800"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Adicionar Evento</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nome"
                  required
                  v-model="novoEvento.nome"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Descrição"
                  required
                  v-model="novoEvento.descricao"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              
              <div class="bannerEvento" style="display: none;">
                <img v-bind:src="novoEvento.banner" />
                <input type="file" accept="image/png, image/gif, image/jpeg" @change="funcaoUploadImage" />
              </div>
              
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Data e hora"
                  required
                  v-mask="'##/##/#### ##:##:00'"
                  v-model="novoEvento.data"
                ></v-text-field>
              </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Endereço
                <hr />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="CEP"
                  v-model="novoEvento.endereco.cep"
                  @change="procurarCEP($event)"
                  required
                  v-mask="'#####-###'"
                ></v-text-field>
              </v-col>

              <v-col cols="10">
                <v-text-field
                  v-model="novoEvento.endereco.rua"
                  label="Rua"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                v-model="novoEvento.endereco.numero"
                  label="Numero"
                  type="number"
                  id="campoNumero"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                v-model="novoEvento.endereco.bairro"
                  label="Bairro"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                v-model="novoEvento.endereco.cidade"
                  label="Cidade"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="novoEvento.endereco.estado"
                  label="Estado"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogEvents = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="addEvento()"
          >
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-dialog
      v-model="dialogParticipantes"
      width="800"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Participantes</span>
          <br />
          <span class="text-h7">Convidar por Matéria</span>
          <br />
          <v-chip
            class="ma-2 click"
            color="primary"
            label
            v-for="(materia, index) in materias.length"
            @click="convidarMateria(materias[index])"
          >
            <v-icon start icon="mdi-account-group-outline" ></v-icon>
              {{materias[index].nome}}
          </v-chip>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Confirmados ({{ eventoSelecionado.convidados.filter(x => x.confirmado == 1).length }})
                <hr />
             
              <v-chip
                  class="ma-2 click"
                  color="primary"
                  label
                  v-for="(aluno, index) in eventoSelecionado.convidados.filter(x => x.confirmado == 1).length"
                >
                  <v-icon start icon="mdi-account-circle-outline" ></v-icon>
                    {{eventoSelecionado.convidados.filter(x => x.confirmado == 1)[index].nome}}
                </v-chip>
              </v-col>
            <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Convidados ({{ eventoSelecionado.convidados.filter(x => x.confirmado != 1).length }})
                <hr />
                <v-chip
                  class="ma-2 click"
                  color="primary"
                  label
                  v-for="(aluno, index) in eventoSelecionado.convidados.filter(x => x.confirmado != 1).length"
                >
                  <v-icon start icon="mdi-account-circle-outline" ></v-icon>
                    {{eventoSelecionado.convidados.filter(x => x.confirmado != 1)[index].nome}}
                </v-chip>
              </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Alunos
                <hr />
              </v-col>
              <v-chip
                class="ma-2 click"
                color="primary"
                label
                v-for="(aluno, index) in DataAlunos.length"
                @click="convidarAluno(DataAlunos[index])"
              >
                <v-icon start icon="mdi-account-circle-outline" ></v-icon>
                  {{DataAlunos[index].nome}}
              </v-chip> 
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>



  <VCalendar expanded :columns="3"
    :initial-page="{ month: new Date().getMonth(), year: new Date().getFullYear() }"
    
    :attributes="attributes"
  />

  <v-table style="margin-bottom: 100px">
    <thead>
      <tr>
        <th class="text-left">
          Nome
        </th>
        <th class="text-left">
          Descrição
        </th>
        <th class="text-left">
          Data
        </th>
        <th class="text-left">
          Confirmados/Convidados
        </th>
        <th class="text-left">
          Convidar
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in eventos"
        :key="item.id"
      >
        <td>{{ item.nome }}</td>
        <td>{{ item.descricao }}</td>
        <td>{{ formatarDataPadraoBR(item.data, true) }}</td>
        <td><strong>{{ item.convidados.filter(x => x.confirmado == 1).length }}/{{ item.convidados.length }}</strong></td>
        <td>
          <v-btn
            color="purple-lighten-2"
            variant="outlined"
            v-model="dialogParticipantes"
            @click="carregarParticipantesEvento(item); "
          >
            Participantes
          </v-btn>
        </td>
      </tr>
    </tbody>
  </v-table>



    <!-- <v-timeline align="start">
    <v-timeline-item
      v-for="(item, i) in eventos"
      :key="i"
      dot-color="green-lighten-1"
      icon="mdi-star"
      fill-dot
    >
      <v-card>
        <v-card-title :class="['text-h6', `bg-purple-lighten-2`]">
         {{ item.nome }}
        </v-card-title>
        <v-card-text class="bg-white text--primary">
          <br />
          <p><strong>Confirmados: {{ item.convidados.filter(x => x.confirmado == 1).length }}/{{ item.convidados.length }}</strong></p>
          <br />
          <p>{{ item.descricao }}</p>
          <br />
          <p>Data: <strong>{{ formatarDataPadraoBR(item.data, true) }}</strong></p>
          <p>Local: {{ item.endereco }}</p>
          <br />
          <v-btn
            color="purple-lighten-2"
            variant="outlined"
            v-model="dialogParticipantes"
            @click="carregarParticipantesEvento(item); "
          >
            Participantes
          </v-btn>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline> -->

      </div>
          
    </div>
  </div>
</template>
  
<script>


import {func} from '../assets/functions.js';

  export default {
    name: 'Exames',
    components:{ 
      
    },
    props: {
      
    },
    async created(){
      
    },
    
    data() {
      return {
        attributes: [],
        eventoSelecionado: null,
        novoEvento: {
          data: null,
          nome: '',
          descricao: '',
          banner: 'https://img.freepik.com/fotos-premium/dois-homem-musculoso-sem-camisa-lutando-kick-boxing-no-ringue-de-boxe_56854-4555.jpg',
          endereco: {
            cep: '',
            rua: '',
            bairro: '',
            cidade: '',
            numero: '',
            estado: ''
          }
        },
        materias: [],
        tiposEvento: [],
        dialogEvents: false,
        dialogParticipantes: false,
        eventos: [],
        DataAlunos: [],
      }
    },
    mounted: function () {
        func.CarregarDados('academias/getMateriasAcademia').then(data => 
          this.materias = data.corpo
        );
        func.CarregarDados('eventos/getEventos').then(data => 
          this.carregarComponentes(data)
        );
    },
    methods: {
      carregarComponentes(dados){
        this.eventos = dados.corpo;
        var novasdatas = [];
        for(var key in this.eventos) {
          var ano = new Date(this.eventos[key].data).getFullYear();
          var mes = new Date(this.eventos[key].data).getMonth();
          var dia = new Date(this.eventos[key].data).getDate();

          var dado = {
            dates: new Date(ano, mes, dia),
            highlight: true,
            popover: {
              label: this.eventos[key].nome,
            },
          }
          novasdatas.push(dado)
        }
        this.attributes = novasdatas;
      },

      formatarDataPadraoBR(data, comHora = true){
        return func.formatarDataPadraoBR(data, comHora);
      },
      convidarAluno(aluno){ 
        var post = {
          idAluno: aluno.id,
          idEvento: this.eventoSelecionado.id
        }
        func.CarregarDados('eventos/convidar', post).then(data => 
          this.reCarregarEventos()
        );
      },
      convidarMateria(materia){
        var post = {
          idMateria: materia.id,
          idEvento: this.eventoSelecionado.id
        }
        func.CarregarDados('eventos/convidarMateria', post).then(data => 
          this.reCarregarEventos()
        );
      },
      funcaoUploadImage(e){
        func.onFileChange(e).then(data => this.novoEvento.banner = data);
      },
      addEvento(){
        this.novoEvento.data = func.formatarDataPadraoBD(this.novoEvento.data, true);
        func.CarregarDados('eventos/addEvento', this.novoEvento).then(data => 
          this.carregarEventos()
        );
      },
      carregarEventos(){
        this.dialogEvents = false;
        func.CarregarDados('eventos/getEventos').then(data => 
          this.carregarComponentes(data)
        );
      },
      procurarCEP(event){
        func.CarregarCEP(this.novoEvento.endereco.cep)
        .then(data => this.carregarDadosCEP(data));
      },
      carregarDadosCEP(dados) {
        this.novoEvento.endereco.rua = dados.logradouro
        this.novoEvento.endereco.bairro = dados.bairro
        this.novoEvento.endereco.cidade = dados.localidade
        this.novoEvento.endereco.estado = dados.uf;
        document.getElementById("campoNumero").focus();
      },
      
      carregarParticipantesEvento(evento) {
        this.dialogParticipantes = true;
        this.eventoSelecionado = evento;
        
        func.CarregarDados('eventos/getAlunosNaoConvidados', this.eventoSelecionado).then(data => 
          this.DataAlunos = data.corpo
          //console.log(data)
        );

      },


      reCarregarEventos(){
        func.CarregarDados('eventos/getEventos').then(data => 
          this.pegarNovamenteEventoSelecionado(data.corpo)
        );
      },
      pegarNovamenteEventoSelecionado(eventos){
        this.eventos = eventos;
        this.eventoSelecionado = this.eventos.filter(x => x.id == this.eventoSelecionado.id)[0];
        this.carregarParticipantesEvento(this.eventoSelecionado)
      }
    }
  }

</script>
  

  