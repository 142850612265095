import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
//import router from '../router/router'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Bar } from 'vue-chartjs'
import { Pie } from 'vue-chartjs'
import { Doughnut } from 'vue-chartjs'
import money from 'v-money'

// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { mdi } from 'vuetify/iconsets/mdi'


import { VueMaskDirective } from 'v-mask';

const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
};


const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi', // This is already the default value - only for display purposes
  },
})


import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faCubes } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";


library.add(faPhone);
library.add(faUser);
library.add(faBrain);
library.add(faCheck);
library.add(faHome);
library.add(faPeopleGroup);
library.add(faUserPlus);
library.add(faPlus);
library.add(faFilter);
library.add(faCubes);
library.add(faGear);
library.add(faChartBar);
library.add(faBell);
library.add(faSave);
library.add(faGraduationCap);
library.add(faList);
library.add(faMoneyBill);

const firebaseConfig = {
  apiKey: "AIzaSyDcUA7EfZDUnxJHUHAmwetaWn6oEWBxlRo",
  authDomain: "lutas-984a9.firebaseapp.com",
  projectId: "lutas-984a9",
  storageBucket: "lutas-984a9.appspot.com",
  messagingSenderId: "355740279569",
  appId: "1:355740279569:web:eb029974fe77c7033e62b0",
  measurementId: "G-LKJ0X6FNGQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import YouTube from 'vue3-youtube'


// var globalVariable = 'lucas';
// //app.provide('myGlobalVariable', globalVariable)

// app.config.globalProperties.globalVariable = () => {}


const analytics = getAnalytics(app);

//import Home from './Pages/Estatisticas.vue'
import Alunos from './Pages/Alunos.vue'
import Configuracoes from './Pages/Configuracoes.vue'
import Perfil from './Pages/Perfil.vue'
import Eventos from './Pages/Eventos.vue'
import Aulas from './Pages/Aulas.vue'
import Login from './Pages/Login.vue'
import AlunoCheck from './Pages/AlunoCheck.vue'
import Referee from './Pages/Referee.vue'
import Catraca from './Pages/Catraca.vue'
import verComprovante from './Pages/verComprovante.vue'
import CheckIns from './Pages/CheckIns.vue'
import Estatisticas from './Pages/Estatisticas.vue'
import Pagamento from './Pages/Pagamento.vue'
import PagamentoAnalise from './Pages/PagamentoAnalise.vue'
import Mestre from './Pages/Mestre.vue'
import Totem from './Pages/Totem.vue'
import Experimentais from './Pages/Experimentais.vue'
import CheckInsConf from './Pages/CheckInsConf.vue'
import Videos from './Pages/Videos.vue'
import Criar from './Pages/Criar.vue'
import sitemap from './Pages/sitemap.html'
import Loja from './Pages/Loja.vue'
import Contas from './Pages/Contas.vue'
import adicionarPagamento from './Pages/adicionarPagamento.vue'
import Placar from './Pages/Placar.vue'
import placarpublico from './Pages/PlacarPublico.vue'

import {createRouter, createWebHistory} from 'vue-router'  

const routes = [

  {
    path: '/alunos',
    name: 'alunos',
    component: Alunos,
  },
  {
    path: '/configuracoes',
    name: 'configuracoes',
    component: Configuracoes,
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: Perfil,
  },
  {
    path: '/eventos',
    name: 'eventos',
    component: Eventos,
  },
  {
    path: '/aulas',
    name: 'aulas',
    component: Aulas,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/alunocheck',
    name: 'alunocheck',
    component: AlunoCheck,
  },
  {
    path: '/referee',
    name: 'referee',
    component: Referee,
  },
  {
    path: '/catraca',
    name: 'catraca',
    component: Catraca,
  },
  {
    path: '/verComprovante',
    name: 'verComprovante',
    component: verComprovante,
  },
  {
    path: '/checkins',
    name: 'checkins',
    component: CheckIns,
  },
  {
    path: '/estatisticas',
    name: 'estatisticas',
    component: Estatisticas,
  },
  {
    path: '/pagamento',
    name: 'pagamento',
    component: Pagamento,
  },
  {
    path: '/pagamentoanalise',
    name: 'pagamentoanalise',
    component: PagamentoAnalise,
  },
  {
    path: '/mestre',
    name: 'mestre',
    component: Mestre,
  },
  {
    path: '/totem',
    name: 'totem',
    component: Totem,
  },
  {
    path: '/experimentais',
    name: 'experimentais',
    component: Experimentais,
  },
  {
    path: '/checkinsconf',
    name: 'checkinsconf',
    component: CheckInsConf,
  },
  {
    path: '/videos',
    name: 'videos',
    component: Videos,
  },
  {
    path: '/criar',
    name: 'criar',
    component: Criar,
  },
  {
    path: '/loja',
    name: 'loja',
    component: Loja,
  },
  {
    path: '/contas',
    name: 'contas',
    component: Contas,
  },
  {
    path: '/sitemap',
    mode: history,
    name: 'sitemap',
    component: { template: sitemap }
  },
  {
    path: '/adicionarPagamento',
    name: 'adicionarPagamento',
    component: adicionarPagamento
  },
  {
    path: '/placar',
    name: 'placar',
    component: Placar
  },
  {
    path: '/placarpublico',
    name: 'placarpublico',
    component: placarpublico
  }
  
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


createApp(App).directive('mask', vMaskV3).use(router).use(vuetify).use(VCalendar).use(YouTube).use(money).component("font-awesome-icon", FontAwesomeIcon).mount('#app')

//createApp(App).use(router).component("font-awesome-icon", FontAwesomeIcon).mount("#app");
