<template>
 <v-toolbar>
        <v-toolbar-title class="text-grey" style="text-align: left;">
          Aulas
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
  <div  class="App-Conteudo" id="App-Conteudo">
    <div class="App-Conteudo-Comp">
      <div class="App-Conteudo-Comp-Box">
        <v-table style="margin-bottom: 100px">
          <thead>
            <tr>
              <th class="text-left">
                Nome
              </th>
              <th class="text-left">
                Contato
              </th>
              <th class="text-left">
                Materia
              </th>
              <th class="text-left">
                Horario
              </th>
              <th class="text-left">
                Data Check
              </th>
            </tr>
          </thead>
          <tbody>
             <tr v-for="item in experimentais">
                <td>{{item.nome}}</td>
                <td>{{item.contato}}</td>
                <td>{{item.nomeAula}}</td>
                <td>{{item.horario}}</td>
                <td>{{formatarData(item.dataAula)}}</td>
              </tr>
          </tbody>
  </v-table>



   
   
      </div>   
    </div>
  </div>
</template>
  
<script>


import {func} from '../assets/functions.js';

  export default {
    name: 'Aulas',
    components:{ 
      
    },
    props: {
      
    },
    async created(){

    },
    data() {
      return {
        experimentais: [],
      }
    },
    mounted: function () {
        this.carregarTela();
    },
    methods: {
      carregarTela(){
        this.aulas = [];  
        func.CarregarDados('aulas/getAulasExperimentais').then(data => 
           this.experimentais = data.corpo
        );
      },
      formatarData(data){
        return func.formatarDataPadraoBR(data, true)
      }
    }
  }

</script>
  

  