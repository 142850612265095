<template>
  <v-toolbar >
        <v-toolbar-title class="text-grey" style="text-align: left;">
          Mestre
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>

  <div  class="App-Conteudo" id="App-Conteudo">
    <v-snackbar
      v-model="snackVisivel"
    >
      {{snackMensagem}}
      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="snackVisivel = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
    <div class="App-Conteudo-Comp">
      <v-snackbar
      v-model="snackbarPerfilSalvo"
    >
      {{snackbarPerfilSalvoMensagem}}
      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="snackbarPerfilSalvo = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>



      <div class="App-Conteudo-Comp-Box">
        <v-container>
          
           <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
              <div class="logoAcademiaPerfil">
                <img v-bind:src="professor.foto" />
                <input type="file" @change="funcaoUploadImage" />
              </div>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
              > 
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="professor.nome"
                  label="Nome"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="professor.pix"
                    label="PIX"
                    required
                  ></v-text-field>
              </v-col>
               <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="professor.celular"
                    label="Celular"
                    v-mask="'(##) #####-####'"
                    required
                  ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-autocomplete
                  v-model="professor.materias"
                  :items="materiasFull"
                  item-title="nome"
                  item-value="id"
                  label="Matérias"
                  disabled
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  readonly 
                  v-model="professor.linkAlunos"
                  label="Link alunos"
                  required
                ></v-text-field>
              </v-col>
          </v-row>  
            <v-btn
              color="primary"
              class="flutuarDireita"
              append-icon="mdi-content-save-all"
              v-on:click="salvarDados()"
            >
              Salvar dados professor
            </v-btn>
           
    <br />
    <br />
    <h3>Controle Graduação</h3>
    <v-table class="table table-striped table-bordered" v-for="materia in graduacaoAgrupada" :key="materia.materia">
      <thead>
        <tr>
          <th scope="col"><b>{{materia.materia}}</b></th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th scope="col">Nivel</th>
          <th scope="col">Quantidade Presenças</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="nivel in materia.niveis" :key="nivel.id">
          <td><span>{{nivel.nivel}}</span></td>
          <td><v-text-field type="number" class="form-control" v-model="nivel.qtdCheckins" v-on:blur="setAndSortData(nivel)"> </v-text-field></td>
        </tr>
      </tbody>
    </v-table>
         <v-btn
              color="primary"
              class="flutuarDireita"
              append-icon="mdi-content-save-all"
              v-on:click="tabelaGraduacao()"
            >
              Salvar contagem de graduação
            </v-btn>

          </v-container>
      </div>
          
    </div>
  </div>
</template>
  
<script>


import {func} from '../assets/functions.js';

  export default {
    name: 'Perfil',
    components:{ 
      
    },
    props: {
      
    },
    async created(){
      this.initTable();
      this.sortData();
      func.CarregarDados('academias/verificarPagamento').then(data => 
        this.pagamentosFaltantes = data.corpo
      );
    },
    data() {
      return {
        records: [],
        graduacaoAgrupada: [],
        pagamentosFaltantes: [],
        snackVisivel: null,
        snackMensagem: null,
        snackbarPerfilSalvo: false,
        snackbarPerfilSalvoMensagem: "Perfil Salvo",
        materiasFull: [],
        url: null,
        logobase64: null,
        professor: {
          nome: null,
          pix: null,
          foto: null,
          celular: null,
          materias: [],
          linkAlunos: null,
          tokenPublico: null,
          linkAlunos: null
        }
      }
    },
    mounted: function () {      
      func.CarregarDados('professor/getProfessor').then(data => this.carregarDadosProfessor(data.corpo) );
    },
    methods: {
      tabelaGraduacao(){
        func.CarregarDados('professor/atualizarControleGraduacao', this.records).then(data => 
          this.initTable()
        );
      },

      initTable: function () {
        func.CarregarDados('professor/getConfigGraduacao').then(data => 
          this.criardadosTabela(data.corpo) 
        );
      },
      criardadosTabela(data){
        var groubedByTeam = this.groupBy(data, 'nomeMateria');
        var propriedades = Object.getOwnPropertyNames(groubedByTeam);
        var novoArray = [];
        for(var i = 0; i < propriedades.length; i++){
          novoArray.push(
            {
              materia: propriedades[i],
              niveis: groubedByTeam[propriedades[i]]
            }
          )
          
        }
        this.graduacaoAgrupada = novoArray;
        this.records = data;


        //  this.records = [
        //   { id: 1,  nivel: 1, qtdCheckins: 'Frank'},
        //   { id: 2,  nivel: 2, qtdCheckins: 'Vic' },
        //   { id: 3,  nivel: 3, qtdCheckins: 'Gina' },
        //   { id: 4,  nivel: 4, qtdCheckins: 'Jessi'},
        //   { id: 5,  nivel: 5, qtdCheckins: 'Jay' }
        // ];
      },
      groupBy(xs, key){
          return xs.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
      },
      sortData: function () {
        this.records.sort((a, b) => a.id - b.id);
      },
      setAndSortData: function (record) {
        this.sortData();
        const lastRecord = this.records[this.records.length - 1];
        if(record.id == lastRecord.id) {
          this.records.push({id: lastRecord.id + 1})
        }
      },


      funcaoUploadImage(e){
        func.onFileChange(e).then(data => this.professor.foto = data);
      },
      salvarDados(){ 
        var ignorar = ['adm']
        var erros = func.NovoVerificarJSONNull(this.professor, ignorar);
        if(erros.length > 0){
          this.snackMensagem = erros
          this.snackVisivel = true;
          return
        }
        

        func.resizedataURL(this.professor.foto).then(data => {
            this.professor.foto =  data;
            func.CarregarDados('professor/alterProfessor', this.professor).then(data =>
              this.recarregarAcademiaAposSalvar(data)
            );
          }
        ); 
      },
      recarregarAcademiaAposSalvar(dados){
        if(dados.sucesso){
          this.snackbarPerfilSalvoMensagem = "Perfil Salvo";
          this.snackbarPerfilSalvo = true;
          func.CarregarDados('professor/getProfessor').then(data => this.carregarDadosProfessor(data.corpo));
        }
        else{
          this.snackbarPerfilSalvoMensagem = "Falha ao Salvar";
          this.snackbarPerfilSalvo = true;
        }
      },

      carregarDadosProfessor(dados){  
        this.professor = dados;
        this.professor.linkAlunos = window.location.origin + "/alunocheck?t=" +  dados.tokenPublico;
        if(this.professor.foto == null){
          this.professor.foto = 'https://d3c1e34umh9o6d.cloudfront.net/image/filename/789691/md_Screenshot_3.png'
        }
        func.CarregarDados('academias/getMateriasAcademia').then(data => 
            this.carregarMaterias(data.corpo)
        );
      },
      carregarMaterias(dados){
        this.materiasFull = dados;
        func.CarregarDados('professor/getMateriasProfessor').then(data => 
          this.converterMaterias(data.corpo)
        );
      },
      converterMaterias(dados){
        var materiasSelect = [];
        for (var materia of dados) 
        {
          materiasSelect.push(materia.id)
        }
        this.professor.materias = materiasSelect;
      },
      
    }
  }

</script>
  

  