<template>
 <v-toolbar>
        <v-toolbar-title class="text-grey" style="text-align: left;">
          Aulas
        </v-toolbar-title>
        <v-spacer></v-spacer>
        
        <v-btn icon v-on:click="dialogAddAula = true">
          <v-icon class="pulse">mdi-karate</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
  <div  class="App-Conteudo" id="App-Conteudo">
    <div class="App-Conteudo-Comp">
      <div class="App-Conteudo-Comp-Box">
        <v-row style="margin-bottom: 100px;">
          <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Domingo
                <hr />
                <div v-for="(aula, indexAulas) in aulas.length">
                    <v-chip
                    @click="editarAula(aulas[indexAulas])"
                      v-if="aulas[indexAulas].diaSemana == 'Domingo'"
                      class="ma-2 click"
                      color=""
                      label
                      
                    >
                    <v-icon start icon="mdi-label"></v-icon>
                      {{aulas[indexAulas].nomeMateria}} - {{aulas[indexAulas].horario}} <span v-if="aulas[indexAulas].unidade != null">&nbsp; - {{aulas[indexAulas].unidade}}</span>
                    </v-chip>
                </div> 
              </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Segunda-Feira
                <hr />
                <div v-for="(aula, indexAulas) in aulas.length">
                    <v-chip
                    @click="editarAula(aulas[indexAulas])"
                      v-if="aulas[indexAulas].diaSemana == 'Segunda-Feira'"
                      class="ma-2 click"
                      color=""
                      label
                      
                    >
                    <v-icon start icon="mdi-label"></v-icon>
                    {{aulas[indexAulas].nomeMateria}} - {{aulas[indexAulas].horario}} <span v-if="aulas[indexAulas].unidade != null">&nbsp; - {{aulas[indexAulas].unidade}}</span>
                    </v-chip>
                </div>   
              </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Terça-Feira
                <hr />
                <div v-for="(aula, indexAulas) in aulas.length">
                    <v-chip
                      @click="editarAula(aulas[indexAulas])"
                      v-if="aulas[indexAulas].diaSemana == 'Terça-Feira'"
                      class="ma-2 click"
                      color=""
                      label
                      
                    >
                    <v-icon start icon="mdi-label"></v-icon>
                      {{aulas[indexAulas].nomeMateria}} - {{aulas[indexAulas].horario}} <span v-if="aulas[indexAulas].unidade != null">&nbsp; - {{aulas[indexAulas].unidade}}</span>
                    </v-chip>
                </div> 
              </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Quarta-Feira
                <hr />
                <div v-for="(aula, indexAulas) in aulas.length">
                    <v-chip
                    @click="editarAula(aulas[indexAulas])"
                      v-if="aulas[indexAulas].diaSemana == 'Quarta-Feira'"
                      class="ma-2 click"
                      color=""
                      label
                      
                    >
                    <v-icon start icon="mdi-label"></v-icon>
                      {{aulas[indexAulas].nomeMateria}} - {{aulas[indexAulas].horario}} <span v-if="aulas[indexAulas].unidade != null">&nbsp; - {{aulas[indexAulas].unidade}}</span>
                    </v-chip>
                </div> 
              </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Quinta-Feira
                <hr />
                <div v-for="(aula, indexAulas) in aulas.length">
                    <v-chip
                    @click="editarAula(aulas[indexAulas])"
                      v-if="aulas[indexAulas].diaSemana == 'Quinta-Feira'"
                      class="ma-2 click"
                      color=""
                      label
                      
                    >
                    <v-icon start icon="mdi-label"></v-icon>
                      {{aulas[indexAulas].nomeMateria}} - {{aulas[indexAulas].horario}} <span v-if="aulas[indexAulas].unidade != null">&nbsp; - {{aulas[indexAulas].unidade}}</span>
                    </v-chip>
                </div> 
              </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Sexta-Feira
                <hr />
                <div v-for="(aula, indexAulas) in aulas.length">
                    <v-chip
                    @click="editarAula(aulas[indexAulas])"
                      v-if="aulas[indexAulas].diaSemana == 'Sexta-Feira'"
                      class="ma-2 click"
                      color=""
                      label
                      
                    >
                    <v-icon start icon="mdi-label"></v-icon>
                      {{aulas[indexAulas].nomeMateria}} - {{aulas[indexAulas].horario}} <span v-if="aulas[indexAulas].unidade != null">&nbsp; - {{aulas[indexAulas].unidade}}</span>
                    </v-chip>
                </div> 
              </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Sábado
                <hr />
                <div v-for="(aula, indexAulas) in aulas.length">
                    <v-chip
                    @click="editarAula(aulas[indexAulas])"
                      v-if="aulas[indexAulas].diaSemana == 'Sábado'"
                      class="ma-2 click"
                      color=""
                      label
                      
                    >
                    <v-icon start icon="mdi-label"></v-icon>
                      {{aulas[indexAulas].nomeMateria}} - {{aulas[indexAulas].horario}} <span v-if="aulas[indexAulas].unidade != null || item.unidade != ''">&nbsp; - {{aulas[indexAulas].unidade}}</span>
                    </v-chip>
                </div> 
              </v-col>
          </v-row> 



    <v-dialog
      v-model="dialogAddAula"
      width="800"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Adicionar Aula</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-autocomplete
                  :items="materias"
                  item-title="nome"
                  item-value="id"
                  label="Matéria"
                  v-model="aulaAdd.idMateria"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-autocomplete
                  :items="diasSemana"
                  label="Dia da semana"
                  v-model="aulaAdd.diaSemana"
                ></v-autocomplete>
              </v-col>
              
              <v-col
                cols="12"
                sm="12"
              >
              <v-text-field
                label="Horario"
                model-value="12:30:00"
                type="time"
                suffix=""
                v-model="aulaAdd.horario"
              ></v-text-field>
              </v-col>

 <v-col
                cols="12"
                sm="12"
              >
              <v-text-field
                label="Unidade"
                v-model="aulaAdd.unidade"
              ></v-text-field>
              </v-col>

            </v-row>
              
          </v-container>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogAddAula = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="salvar()"
          >
            Cadastrar
          </v-btn>
        </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="dialogEditAula"
      width="800"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Editar Aula</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-autocomplete
                  :items="materias"
                  item-title="nome"
                  item-value="id"
                  label="Matéria"
                  v-model="materiaEdicao.idMateria"
                ></v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <v-autocomplete
                  :items="diasSemana"
                  label="Dia da semana"
                  v-model="materiaEdicao.diaSemana"
                ></v-autocomplete>
              </v-col>
              
              <v-col
                cols="12"
                sm="12"
              >
              <v-text-field
                label="Horario"
                model-value="12:30:00"
                type="time"
                suffix=""
                v-model="materiaEdicao.horario"
              ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
              <v-text-field
                label="Unidade"
                v-model="materiaEdicao.unidade"
              ></v-text-field>
              </v-col>
            </v-row>
              
          </v-container>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogEditAula = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="salvarEdicao()"
          >
            Editar
          </v-btn>
        </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    
      </div>
          
    </div>
  </div>
</template>
  
<script>


import {func} from '../assets/functions.js';

  export default {
    name: 'Aulas',
    components:{ 
      
    },
    props: {
      
    },
    async created(){

    },
    data() {
      return {
        aulaAdd: {diaSemanaId: null, diaSemana: null, idMateria: null, horario: null, unidade: null},
        materias: [],
        aulas: [],
        dialogAddAula: false,
        dialogEditAula: false,
        diasSemana: ['Segunda-Feira', 'Terça-Feira','Quarta-Feira', 'Quinta-Feira','Sexta-Feira', 'Sábado', 'Domingo'],
        materiaEdicao: {id: null, nome: null, diaSemana: null, horario: null, unidade: null}
      }
    },
    mounted: function () {
        this.carregarTela();
    },
    methods: {
      carregarTela(){
        this.aulas = [];  
        func.CarregarDados('aulas/getAulas').then(data => 
            this.aulas = data.corpo
        );

        func.CarregarDados('academias/getMateriasAcademia').then(data => 
          this.materias = data.corpo
        );

        this.dialogEditAula = false;
        this.dialogAddAula = false;
      },
      editarAula(event){
        
        this.dialogEditAula = true;
        this.materiaEdicao = event;
      },
      salvarEdicao(){
        if(this.materiaEdicao.diaSemana == "Segunda-Feira"){
          this.materiaEdicao.idDiaSemana = 0
        }
        if(this.materiaEdicao.diaSemana == "Terça-Feira"){
          this.materiaEdicao.idDiaSemana = 1
        }
        if(this.materiaEdicao.diaSemana == "Quarta-Feira"){
          this.materiaEdicao.idDiaSemana = 2
        }
        if(this.materiaEdicao.diaSemana == "Quinta-Feira"){
          this.materiaEdicao.idDiaSemana = 3
        }
        if(this.materiaEdicao.diaSemana == "Sexta-Feira"){
          this.materiaEdicao.idDiaSemana= 4
        }
        if(this.materiaEdicao.diaSemana == "Sábado"){
          this.materiaEdicao.idDiaSemana = 5
        }
        if(this.materiaEdicao.diaSemana == "Domingo"){
          this.materiaEdicao.idDiaSemana = 6
        }

        func.CarregarDados('aulas/alterAula', this.materiaEdicao).then(data => 
          this.carregarTela()
        );
      },
      salvar(){
        if(this.aulaAdd.diaSemana == "Segunda-Feira"){
          this.aulaAdd.diaSemanaId = 0
        }
        if(this.aulaAdd.diaSemana == "Terça-Feira"){
          this.aulaAdd.diaSemanaId = 1
        }
        if(this.aulaAdd.diaSemana == "Quarta-Feira"){
          this.aulaAdd.diaSemanaId = 2
        }
        if(this.aulaAdd.diaSemana == "Quinta-Feira"){
          this.aulaAdd.diaSemanaId = 3
        }
        if(this.aulaAdd.diaSemana == "Sexta-Feira"){
          this.aulaAdd.diaSemanaId = 4
        }
        if(this.aulaAdd.diaSemana == "Sábado"){
          this.aulaAdd.diaSemanaId = 5
        }
        if(this.aulaAdd.diaSemana == "Domingo"){
          this.aulaAdd.diaSemanaId = 6
        }


        func.CarregarDados('aulas/salvarAula', this.aulaAdd).then(data => 
          this.carregarTela()
        );
      }
    }
  }

</script>
  

  