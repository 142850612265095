<template>
  <v-toolbar>
        <v-toolbar-title class="text-grey" style="text-align: left;">
          Loja
        </v-toolbar-title>
        <v-spacer></v-spacer>
        
        <v-btn icon v-on:click="dialogNovoProduto = true">
          <v-icon  class="pulse">mdi-cart-plus</v-icon>
        </v-btn>
  </v-toolbar>
  <v-divider></v-divider>
  <h5>Cadastre os produtos de sua loja</h5><br/>

<v-snackbar
      v-model="snackVisivel"
    >
      {{snackMensagem}}
      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="snackVisivel = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

<v-dialog
      v-model="dialogNovoProduto"
      width="400"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Cadastrar produto</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <div class="logoAcademiaPerfil" >
                  <img v-bind:src="produto.foto" />
                  <input type="file" @change="funcaoUploadImage" />
                </div>
              </v-col>
            </v-row>
              
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                 <v-text-field
                  clearable
                  label="Nome *"
                  v-model="produto.nome"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                 <v-text-field
                  clearable
                  label="Descrição *"
                  v-model="produto.descricao"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                 <v-text-field
                  clearable
                  label="Preço *"
                  v-model="produto.preco"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  clearable
                  label="Whatsapp contato *"
                  v-model="produto.whats"
                  v-mask="'(##) #####-####'"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogNovoProduto = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="cadastrarProduto()"
          >
            Cadastrar
          </v-btn>
        </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>


<v-dialog
      v-model="dialogEditarProduto"
      width="400"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Editar produto</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <div class="logoAcademiaPerfil" >
                  <img v-bind:src="editar.foto" />
                  <input type="file" @change="funcaoUploadImageEditar" />
                </div>
              </v-col>
            </v-row>
              
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                 <v-text-field
                  clearable
                  label="Nome *"
                  v-model="editar.nome"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                 <v-text-field
                  clearable
                  label="Descrição *"
                  v-model="editar.descricao"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                 <v-text-field
                  clearable
                  label="Preço *"
                  v-model="editar.preco"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  clearable
                  label="Whatsapp contato *"
                  v-model="editar.whats"
                  v-mask="'(##) #####-####'"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogEditarProduto = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="excluirProduto()"
          >
            Excluir
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="editarProduto()"
          >
            Editar
          </v-btn>
        </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>


  <div  class="App-Conteudo" id="App-Conteudo" style="margin-top: 20px !important">
    <div class="App-Conteudo-Comp">
      <div class="App-Conteudo-Comp-Box">
  
<v-table style="margin-bottom: 100px; padding: 20px" class="table table-striped table-bordered" id="tabelaDeAlunos">
    <thead>
      <tr>
        <th class="text-left">
          
        </th>
        <th class="text-left">
          Nome do produto
        </th>
        <th class="text-left">
          Descrição do produto
        </th>
        <th class="text-left">
          WhatsAPP do vendedor 
        </th>
        <th class="text-left">
          Preço
        </th>
        <th class="text-left">
          
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in produtos">
        <td>
             <div :style="{'background-image':'url('+item.foto+')'}" class="divAvatar"></div>
        </td>
         <td>
            {{item.nome}}
        </td>
         <td>
            {{item.descricao}}
        </td>
        <td>
            {{item.whats}}
        </td>
        <td>
            {{item.preco}}
        </td>
        <td>
             <v-btn variant="text" @click="carregarEdicao(item)">
              Editar
            </v-btn>
        </td>
      </tr>
    </tbody>
  </v-table>
  
  
</div>
</div>
</div>



</template>
  
<script>


import {func} from '../assets/functions.js';

  export default {
    name: 'Loja',
    components:{ 
        
    },
    props: {
      
    },
    async created(){

    },
    data() {
      return {
        dialogNovoProduto: false,
        dialogEditarProduto: false,
        snackMensagem: null,
        snackVisivel: false,
        produto: {
          id: '',
          foto: '',
          whats: '',
          nome: '',
          descricao: ''
        },
        editar: {
          id: '',
          foto: '',
          whats: '',
          nome: '',
          descricao: ''
        },
        produtos: []
      }
    },
    mounted: function () {
      this.getProdutos()
    },
    methods: {
      excluirProduto(){
        func.CarregarDados('loja/excluir', this.editar).then(data => 
          this.getProdutos()
        );
      },
      editarProduto(){
        var ignorar = ['foto'];
        var erros = func.NovoVerificarJSONNull(this.editar, ignorar);
        if(erros.length > 0){
          this.snackMensagem = erros
          this.snackVisivel = true;
          return
        }

        func.resizedataURL(this.editar.foto).then(data => {
            this.editar.foto =  data;
            func.CarregarDados('loja/editar', this.editar).then(data => 
              this.getProdutos()
            );
          }
        );
      },
      carregarEdicao(produto){
        this.editar = produto;
        this.dialogEditarProduto = true;
      },
      funcaoUploadImage(e){
        func.onFileChange(e).then(data => this.produto.foto = data);
      },
      funcaoUploadImageEditar(e){
        func.onFileChange(e).then(data => this.editar.foto = data);
      },
      getProdutos(){
          this.dialogEditarProduto = false;
          this.dialogNovoProduto = false;
          func.CarregarDados('loja/getProdutos', this.produto).then(data => 
            this.carregarProdutos(data.corpo)
          );
      },
      carregarProdutos(data){
        this.produtos = data;
      },
      cadastrarProduto(){
        var ignorar = ['id', 'foto'];
        var erros = func.NovoVerificarJSONNull(this.produto, ignorar);
        if(erros.length > 0){
          this.snackMensagem = erros
          this.snackVisivel = true;
          return
        }

        func.resizedataURL(this.produto.foto).then(data => {
            this.produto.foto =  data;
            func.CarregarDados('loja/cadastrar', this.produto).then(data => 
              this.getProdutos()
            );
          }
        );
      }
    }
  }

</script>
  

  