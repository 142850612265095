<template>
  

  
   <v-theme-provider theme="dark" with-background style="height: 100%">
    <div class="d-flex align-center justify-center gradient-login"  
    style="
      background-image: url('https://firebasestorage.googleapis.com/v0/b/lutas-984a9.appspot.com/o/fundoLogin3.png?alt=media&token=ff21162b-00cb-492b-a200-b0371e9c60c1&_gl=1*1htx8d5*_ga*MTI3NDUxNDI3Ny4xNjc3Njg3NzUw*_ga_CW55HF8NVT*MTY4NTY2NTM0MC4xMC4xLjE2ODU2NjU0NTUuMC4wLjA.');
      background-size: 100% 100%;
      margin: auto;
      min-height: 100%;
      max-width: 1366px;
      z-index: 1;
    ">
       <div class="d-flex align-center justify-center" >
          <v-sheet width="600" class="mx-auto" style="padding: 50px; padding-top: 100px; padding-bottom: 100px; margin-bottom: 100px; z-index: 2;">
              <v-form>
                Criar conta <h3>Mestre DOJO</h3>
              </v-form>
              <div class="logo1" style="margin: auto; margin-bottom: 50px;">  
              </div>
              <v-form fast-fail @submit.prevent="login">
                  <v-text-field v-model="infosLogin.email" label="Email"></v-text-field>
                  <v-text-field v-model="infosLogin.senha" label="Senha" type="password"></v-text-field>
                  <a href="#" class="text-body-2 font-weight-regular" v-on:click="dialogEsqueceuSenha = true" >Esqueceu sua senha ?</a>
                  <v-btn color="primary" block class="mt-2" v-on:click="logar()">Login</v-btn>
              </v-form>
          </v-sheet>
    </div>  
  </div>


  <v-snackbar
      v-model="snackbar"
      multi-line
    >
      Login ou senha inválidos
      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

     <v-snackbar
      v-model="snackbarTrocarSenha"
      multi-line
    >
      Email não encontrado
      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackBarRandom"
      multi-line
    >
      {{msgSnackbar}}
      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
    

<v-row justify="center">
    <v-dialog
      v-model="dialogEsqueceuSenha"
      persistent
      width="500"
    >
      <template v-slot:activator="{ props }">
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Esqueci minha senha</span>
        </v-card-title>
        <v-card-text >
          <v-container>
            <v-row>
              <v-col cols="12" v-if="codigoEnviado == false">
                <v-text-field
                  label="Email"
                  required
                  v-model="infosLogin.email"
                ></v-text-field>
              </v-col>

              <v-col cols="12" style="text-align: center" v-if="codigoEnviado == false">
                <v-btn
                  justify="center"
                  color="primary"
                  variant="text"
                  v-bind="props"
                  v-on:click="enviarCodigoEmail()"
                >
                  Enviar código
                </v-btn>
              </v-col>
             
              <v-row v-if="codigoEnviado">
                <v-col cols="12">
                  <v-text-field
                    label="Código de verificação"
                    type="text"
                    required
                    v-model="infosTrocarSenha.codigo"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Senha"
                    type="password"
                    v-model="infosTrocarSenha.senha"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Repetir senha"
                    type="password"
                    v-model="infosTrocarSenha.repetirSenha"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
               
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="codigoEnviado">
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogEsqueceuSenha = false; codigoEnviado = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="trocarSenha()"
          >
            Alterar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  </v-theme-provider>


</template>
  
<script>


import {func} from '../assets/functions.js';
  export default {
    name: 'Exemplo',
    components:{ 
      
    },
    props: {
      
    },
    async created(){
      //alert("login" + localStorage.getItem('DojoMasterToken'))
      // if(localStorage.getItem('DojoMasterToken') != null){
      //   window.location.replace("/alunos");
      // }
    },
    data() {
      return {
        dialogEsqueceuSenha: false,
        codigoEnviado: false,
        snackbar: false,
        snackbarTrocarSenha: false,
        snackBarRandom: false,
        msgSnackbar: null,
        infosLogin: {
          email: null,
          senha: null
        },
        infosTrocarSenha: {
          email: null,
          codigo: null,
          senha: null,
          repetirSenha: null
        },
        snackVisivel: null,
        snackMensagem: null,
        mostrarheader: true,
      }
    },
    
    methods: {
      trocarSenha(){
        if(this.infosTrocarSenha.senha != this.infosTrocarSenha.repetirSenha){
          this.snackBarRandom = true;
          this.msgSnackbar = "Senha e repetir senha com valores diferentes"
          return
        }

        func.CarregarDados('professor/trocarSenha', this.infosTrocarSenha).then(data => 
          this.finalizarTrocaDeSenha(data)
        );
        
      },
      finalizarTrocaDeSenha(data){
        if(data.sucesso){
          this.dialogEsqueceuSenha = false; 
          this.codigoEnviado = false; 
          this.snackBarRandom = true; 
          this.msgSnackbar = "Senha alterada com sucesso"
        }
        else{
          this.snackBarRandom = true; 
          this.msgSnackbar = data.erros
        }
       
      },
      enviarCodigoEmail(){
        func.CarregarDados('professor/enviarCodigoSenha', this.infosLogin).then(data => 
          this.verificarCodigo(data)
        );
      },
      verificarCodigo(data){
        if(data.sucesso){
          this.infosTrocarSenha.email = this.infosLogin.email;
          this.codigoEnviado = true;
        }
        else{
          this.snackbarTrocarSenha = true
        }
      },

      logar(){
        func.CarregarDados('academias/logar', this.infosLogin).then(data => 
          this.logarDefato(data)
        );
      },
      logarDefato(dados){
        if(dados.sucesso){
          localStorage.setItem('DojoMasterToken', dados.corpo.token);
          localStorage.setItem('DojoMasterProfessor', dados.corpo.professor);
          window.location.replace("/alunos");
        }
        else{
          this.snackbar = true
        }
      }
    }
  }

</script>
  

  