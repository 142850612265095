

<template>
    <v-toolbar>
        <v-toolbar-title class="text-grey" style="text-align: left;">
          Home
        </v-toolbar-title>
        <v-spacer></v-spacer>
        
      </v-toolbar>
      <v-divider></v-divider>
  <div  class="App-Conteudo" id="App-Conteudo" style="margin-top: 30px !important">
    <div class="App-Conteudo-Comp">

<v-dialog
      v-model="dialogGraduar"
      width="auto"
    >
      <v-card>
        <v-card-text>
          Deseja mesmo graduar o aluno {{alunoGraduar.nomeAluno}} no {{alunoGraduar.nomeMateria}}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="dialogGraduar = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="graduarDeFato()"
          >
            Graduar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <div style="width: 100%; border: 0px solid black; min-height: 250px; text-align: left;" v-if="aniversariantes.length > 0">
        <div style="margin-top: 20px">
          <h3>Aniversariantes do mês</h3>
        </div>
        <v-card v-for="item in aniversariantes" :key="item.id"
          max-width="150"
          rounded="0"
          style="background-color: black; float: left; margin-right: 10px; box-shadow: 2px 2px 2px grey;"
        >
            <v-avatar
              color="grey"
              size="150"
              rounded="0"
            >
              <v-img cover :src="item.foto"></v-img>
            </v-avatar>
            <v-list-item
              class="text-white text-center"
              :title="item.nome"
              :subtitle="converterData(item.dataNascimento).split('/')[0] + '/' + converterData(item.dataNascimento).split('/')[1]"
            ></v-list-item>
        </v-card>
      </div>
     

      <v-row>
        <h3>Contagem graduação</h3>
      </v-row>
      <v-row>
      
      <v-col
          cols="12"
          sm="12"
          md="12"
        >
         <v-table height="300px" class="tabelaComScrol">
            <thead>
              <tr>
                <th class="text-center">
                  Nome
                </th>
                <th class="text-center">
                  Matéria
                </th>
                <th class="text-center">
                </th>
                <th class="text-center">
                  Próximo nível
                </th>
                <th class="text-center">
                  Graduar
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in alunosNivel"
                :key="item"
              >
                <td class="text-center"><strong>{{ item.nomeAluno }}</strong></td>
                <td class="text-center">{{ item.nomeMateria }}</td>
                <td class="text-center">
                   <v-progress-linear
                      v-model="item.totalPorcentagem"
                      color="blue-grey"
                      height="25"
                     style="width: 100px"
                    >
                      <template v-slot:default="{ value }">
                         <strong>{{item.qtdCheckInsAluno + "/" + (item.qtdCheckinsGraduacao == null ? 0 : item.qtdCheckinsGraduacao)}}</strong>
                        <!-- <strong>{{item.qtdCheckInsAluno + "/" + (item.qtdCheckinsGraduacao == null ? 0 : item.qtdCheckinsGraduacao) }}</strong> -->
                        <!-- <strong>{{ }}%</strong>  -->
                      </template>
                    </v-progress-linear>
                </td>
                <td class="text-center">
                  <v-chip v-if="item.proximoNivel">
                    <div  style="width: 100%" v-show="item.proximoImagemURL != null && item.proximoImagemURL != ''">
                      <img style="width: 40px;height: 20px; vertical-align: middle;" v-bind:src="item.proximoImagemURL" />
                    </div>
                    <span v-show="item.proximoImagemURL == null || item.proximoImagemURL == ''">{{ item.proximoNivel }}</span>
                  </v-chip>
                </td>
                <td class="text-center">
                   <v-btn color="primary" variant="outlined" @click="graduar(item) ">
                    Graduar
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
</v-row>
<v-row>
<v-col
          cols="6"
          sm="6"
          md="6"
        >
        <h3>Pagamentos</h3>
 </v-col>    

 <v-col
          cols="6"
          sm="6"
          md="6"
        >
        <h3>Top 5 presentes no mês</h3>
 </v-col>      
</v-row>
<v-row>
        <v-col
          cols="6"
          sm="6"
          md="6"
        >
          <Pie :data="grafico1.data" :options="grafico1.options"/>
        </v-col>
         <v-col
          cols="6"
          sm="6"
          md="6"
        >
             <v-table density="compact">
              <thead>
                <tr>
                  <th class="text-left">
                    Nome
                  </th>
                  <th class="text-left">
                    Frequências
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in frequencias"
                  :key="item.idAluno"
                >
                  <td> 
                  <v-icon
                    size="large"
                    color="yellow"
                    icon="mdi-star"
                  ></v-icon>&nbsp;
                  {{ item.nome }}</td>
                  <td style="font-weight: bold;">{{ item.qtdCheck }}</td>
                </tr>
              </tbody>
            </v-table>
        </v-col>


      </v-row> 
      <v-row>
        <v-divider></v-divider>
      </v-row> 
      <v-row>
        <v-col
          cols="6"
          sm="6"
          md="6"
          v-for="item in graficosPorMateriaNivel" :key="item"
        >
          <div>
            Matéria X Nivel ({{item.nomeMateria}})
            <Bar 
              :options="item.chartOptions"
              :data="item.chartData"
              /> 
          </div>
        </v-col>
      </v-row>  
      <v-row>
        <v-divider></v-divider>
      </v-row> 
      <v-row>
        <v-col
          cols="6"
          sm="6"
          md="6"
        >
          <Bar
            v-if="grafico2.chartData.datasets.length > 1"
            :options="grafico2.chartOptions"
            :data="grafico2.chartData"
          /> 
        </v-col>
      </v-row>     

    </div>
  </div>


</template>
  <style scoped>
.v-data-table::-webkit-scrollbar {
  width:2px;
}
</style>
<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'

import {
  Title,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'

import { Doughnut } from 'vue-chartjs'

ChartJS.register(ArcElement, Tooltip, Legend)
ChartJS.register(CategoryScale, LinearScale, BarElement, Title)

import {func} from '../assets/functions.js';

  export default {
    name: 'Estatisticas',
    components:{ 
      Pie,
      Bar,
      Doughnut
    },
    props: {
      
    },
    created(){
      this.AniversariantesDoMes();
      this.getAlunos();
      this.getAlunosNivel();
      this.getPresencas()
    },
    data() {
      return {
        dialogGraduar: false,
        alunoGraduar: {
          idAluno: null,
          nomeAluno: null,
          nomeMateria: null, 
          idMateria: null,
          idProximoNivel: null
        },
        alunosNivel: [],
        coresGrafico: ['#E46651','#727cf5', '#5cd36b'],
        aniversariantes: [],
        alunos: [],
        grafico1: {
          data: {
            labels: [],
            datasets: [
              {
                backgroundColor: [],
                data: []
              }
            ]
          },
          options: {
              responsive: true,
              maintainAspectRatio: false
          }
        },
        grafico2: {
          chartData: {
                labels: [],
                datasets: [ { data: [] } ]
            },
            chartOptions: {
                responsive: true
            }
        },
        grafico3: {
          chartData: {
                labels: [],
                datasets: [ { data: [] } ]
            },
            chartOptions: {
                responsive: true
            }
        },
       frequencias: [],

        graficosPorMateriaNivel: []
      }
    },
    methods: {
      AniversariantesDoMes(){
        func.CarregarDados('alunos/getAniversariantesDoMes').then(data => 
          this.aniversariantes = data.corpo
        );
      },
      getPresencas(){
          func.CarregarDados('home/getPresencas').then(data => 
            this.preencherGraficoPresencas(data.corpo)
          );
      },
      
      preencherGraficoPresencas(data){
       
       this.frequencias = data
      },
      converterData(data){
        return func.formatarDataPadraoBR(data, false)
      },
      getAlunosNivel(){
        this.dialogGraduar = false;
        func.CarregarDados('home/getAlunosNivel').then(data => 
          this.colocarALunosNivel(data.corpo)
        );
      },
      colocarALunosNivel(dados){
        for(var key in dados) {
          dados[key]['totalPorcentagem'] = (100 / dados[key].qtdCheckinsGraduacao) * dados[key].qtdCheckInsAluno
        }
        this.alunosNivel = dados
      },
      graduar(aluno){
        this.alunoGraduar.idAluno = aluno.idAluno;
        this.alunoGraduar.nomeAluno = aluno.nomeAluno;
        this.alunoGraduar.nomeMateria = aluno.nomeMateria;
        this.alunoGraduar.idProximoNivel = aluno.idProximoNivel;
        this.alunoGraduar.idMateria = aluno.idMateria;
        this.dialogGraduar = true;
      },
      graduarDeFato(aluno){
        func.CarregarDados('home/graduarAluno', this.alunoGraduar).then(data => 
          this.getAlunosNivel()
        );
      },
      getAlunos(){
        func.CarregarDados('home/getSituacaoAlunos').then(data => 
          this.preencherVariaveisAlunos(data.corpo)
        );
      },

      preencherVariaveisAlunos (dados){
        this.alunos = dados.filter(x => x.tipoAluno == 0);
        //Tipo de pagamento
        var tipos = [];
        for(var key in this.alunos) {
           for(var key2 in this.alunos[key].pagamentos) {
            if(tipos.filter(x => x == this.alunos[key].pagamentos[key2].nome).length == 0 ){
              tipos.push(this.alunos[key].pagamentos[key2].nome)
            }
           }
        }
        
         //materias
        var materias = [];
        for(var key in this.alunos) {
          if(materias.filter(x => x == this.alunos[key].materias.filter(y => y.nome)[0].nome).length == 0 ){
            materias.push( this.alunos[key].materias.filter(y => y.nome)[0].nome)
          }
        }   
       

        //quantidaes Pagamentos
        var qtdTipos = [];
        for(var key in tipos) {
            var qtd = this.alunos.filter(x => x.pagamentos.filter(y => y.nome == tipos[key]).length > 0).length;
            qtdTipos.push(qtd)
        }
       

        //quantidaes materias
        var qtdMaterias = [];
        for(var key in materias) {
          var qtd = this.alunos.filter(x => x.materias.filter(y => y.nome)[0].nome == materias[key]).length
          qtdMaterias.push(qtd)
        }

        for(var key in materias) {
          var niveis = [];
          var qtds = [];
          var cores = [];
          //separa alunos
          var alunosPorMateria = this.alunos.filter(x => x.materias.filter(y => y.nome)[0].nome == materias[key]);

          //separa niveis
          for(var key2 in alunosPorMateria) {
            for(var key3 in alunosPorMateria[key2].materias) {
              if(niveis.filter(x => x == alunosPorMateria[key2].materias[key3].nivel ).length == 0 ){
                niveis.push(alunosPorMateria[key2].materias[key3].nivel)
                cores.push(alunosPorMateria[key2].materias[key3].corFundo)
              }
            }
          }

          for(var key4 in niveis) {
            var tt = alunosPorMateria.filter(x => x.materias.filter(y => y.nivel == niveis[key4]).length > 0).length;
            var aa = alunosPorMateria.filter(x => x.materias.filter(y => y.nivel == niveis[key4]).length > 0);
           
            qtds.push(tt)
          }
          var dadoSimples = {
            nomeMateria: materias[key],
            chartData: {
                labels: niveis,
                datasets: [ {label: 'qtd por faixa', backgroundColor: cores, data: qtds } ]
            },
            chartOptions: {
                responsive: true
            }
          };
          
          this.graficosPorMateriaNivel.push(dadoSimples)
        }



        this.grafico1.data = {
            labels: tipos,
            datasets: [
              {
                backgroundColor: this.coresGrafico,
                data: qtdTipos
              }
            ]
        }
        this.grafico1.data.options = {
            responsive: true,
            maintainAspectRatio: false
        }


        var labels = [];
        for(var key in materias) {
          var label = {
            label: materias[key], 
            backgroundColor: '#727cf5', 
            data: [qtdMaterias[key]]
          };

          labels.push(label)
        }

        
        this.grafico2 = {
            chartData: {
                labels: ['Alunos X Matérias'],
                datasets: labels
            },
            chartOptions: {
                responsive: true
            }
        }

       

        

      }
    }
  }

</script>
  

  