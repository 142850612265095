<template>

  <v-toolbar>

        <v-toolbar-title class="text-grey" style="text-align: left;">
          Faturamento
        </v-toolbar-title>
        <v-spacer></v-spacer>

      </v-toolbar>
      <v-divider></v-divider>
  <div  class="App-Conteudo" id="App-Conteudo">
    <div class="App-Conteudo-Comp">
      <div class="App-Conteudo-Comp-Box">
      <v-snackbar
          v-model="snackbar"
          multi-line
        >
        {{ text }}

        <template v-slot:actions>
          <v-btn
            color="red"
            variant="text"
            @click="snackbar = false"
          >
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
<v-row>
</v-row>
<v-row>
  <div class="filtrosAluno">
 
      <v-chip v-for="item in materiasProfessor" class="click"  @click="selecionarMateria(item.id, item.nome)">
        {{ item.nome }} 
      </v-chip>
       <v-chip class="click"  @click="selecionarMateria(null, 'Todas')">
        Todos
      </v-chip>
    </div>
</v-row>
  <v-row style="padding: 10px">  
  <v-col cols="12"
    sm="12"
    md="12">
  <v-card
    class="text-center"
    color="indigo"
  >
    <v-card-item>
      <div>
        <div class="text-overline mb-1">
          <div><b>FILTRO: {{materiaSelecionadaNome}}</b></div>
          <b>TOTAL</b>
        </div>
        <div class="text-h6 mb-1">
          R$ {{valorAReceber + valorRecebido + valorDiversos}}
        </div>
        <div class="text-caption">Valores a receber + valores já recebidos</div>
      </div>
    </v-card-item>
  </v-card>
  </v-col>

  <v-col cols="12"
    sm="4"
    md="4">
    <v-card
    color="indigo"
     class="text-center"
    
  >
  
    <v-card-item>
      <div>
        <div class="text-overline mb-1">
          <b>A RECEBER (MENSALIDADES)</b>
        </div>
        <div class="text-h6 mb-1">
          R$ {{valorAReceber}}
        </div>
        <div class="text-caption">Valores a receber no mês</div>
      </div>
    </v-card-item>

    <v-card-actions>
      <v-btn variant="outlined mx-auto" @click="verTabelas('receber')">
        VER
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-col> 
  <v-col cols="12"
    sm="4"
    md="4">
  <v-card
    class="text-center"
    
    color="indigo"
  >
    <v-card-item>
      <div>
        <div class="text-overline mb-1">
          <b>RECEBIDO (MENSALIDADES)</b>
        </div>
        <div class="text-h6 mb-1">
          R$ {{valorRecebido}}
        </div>
        <div class="text-caption">Valor recebido no mês</div>
      </div>
    </v-card-item>

    <v-card-actions>
      <v-btn variant="outlined mx-auto" @click="verTabelas('recebido')">
        VER
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-col>

  <v-col cols="12"
    sm="4"
    md="4">
  <v-card
    class="text-center"
    
    color="indigo"
  >
    <v-card-item>
      <div>
        <div class="text-overline mb-1">
          <b>RECEBIDO (DIVERSOS)</b>
        </div>
        <div class="text-h6 mb-1">
          R$ {{valorDiversos}}
        </div>
        <div class="text-caption">Valor recebido no mês</div>
      </div>
    </v-card-item>

    <v-card-actions>
      <v-btn variant="outlined mx-auto" @click="verTabelas('diversos')">
        VER
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-col>
  
  </v-row> 


  <v-row style="padding: 10px">   
  <v-col cols="12"
    sm="4"
    md="4">
    <v-card
     class="text-center"
        color="green"
  >
    <v-card-item>
      <div>
        <div class="text-overline mb-1">
          <b>SEM PLANO</b>
        </div>
        <div class="text-h6 mb-1">
          {{tabelaSemPlano.desserts.length}}
        </div>
        <div class="text-caption">Alunos sem plano atribuido (clique para atribuir)</div>
      </div>
    </v-card-item>

    <v-card-actions @click="verTabelas('semplano')">
      <v-btn variant="outlined mx-auto">
        VER
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-col> 
  <v-col cols="12"
    sm="4"
    md="4">
  <v-card
    class="text-center"
     color="green"
     
  >
    <v-card-item>
      <div>
        <div class="text-overline mb-1">
          <b>ALUNOS/PLANOS</b>
        </div>
        <div class="text-h6 mb-1">
          {{tabelaAlunosPlanos.desserts.length}}
        </div>
        <div class="text-caption">Planos dos alunos clique para mudar</div>
      </div>
    </v-card-item>

    <v-card-actions @click="verTabelas('alunosplanos')">
      <v-btn variant="outlined mx-auto">
        VER
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-col>

  <v-col cols="12"
    sm="4"
    md="4">
  <v-card
    class="text-center"
     color="green"
    
  >
    <v-card-item>
      <div>
        <div class="text-overline mb-1">
         <b>PLANOS DISPONIVEIS</b>
        </div>
        <div class="text-h6 mb-1">
          {{tabelaPlanos.desserts.length}}
        </div>
        <div class="text-caption">Planos oferecidos na academia, clique para criar</div>
      </div>
    </v-card-item>

    <v-card-actions @click="verTabelas('planosdisponiveis')">
      <v-btn variant="outlined mx-auto">
        VER
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-col>
  </v-row> 



<v-row style="margin-bottom: 20px" v-if="vertabelaReceber">
   <v-data-table
    :headers="tabelaReceber.headers"
    :items="tabelaReceber.desserts"
    :sort-by="[{ key: 'nome', order: 'asc' }]"
    multi-sort
    class="elevation-1"
    hide-default-footer
  ></v-data-table>
</v-row>
<v-row style="margin-bottom: 20px" v-if="vertabelaRecebido">
   <v-data-table
    :headers="tabelaRecebido.headers"
    :items="tabelaRecebido.desserts"
    :sort-by="[{ key: 'nome', order: 'asc' }]"
    multi-sort
    class="elevation-1"
     hide-default-footer
  ></v-data-table>
</v-row>
<v-row style="margin-bottom: 20px" v-if="verTabelaDiversos">
  <v-btn density="default" variant="outline" @click="dialogCriarDiverso = true">Adicionar Produto/Serviço</v-btn>
   <v-data-table
    :headers="tabelaDiversos.headers"
    :items="tabelaDiversos.desserts"
    :sort-by="[{ key: 'nome', order: 'asc' }]"
    multi-sort
    class="elevation-1"
     hide-default-footer
  ></v-data-table>
</v-row>
<v-row style="margin-bottom: 20px" v-if="vertabelaSemPlano">
  <v-table class="table table-striped table-bordered" style="width: 100%">
    <thead>
      <tr>
        <th class="text-left">
          Nome
        </th>
        <th class="text-left">
          Matéria
        </th>
        <th class="text-left">
          Dia Pagamento
        </th>
        <th class="text-left">
          Editar
        </th>
      </tr>
   </thead>
   <tbody>
      <tr v-for="item in tabelaSemPlano.desserts" :key="item.id">
        <td>{{item.nomeAluno}}</td>
        <td>{{item.nomeMateria}}</td>
        <td>{{item.diaPagamento}}</td>
        <td><v-btn variant="outline" @click="addPlanodoAluno(item)">Editar</v-btn></td>
      </tr>
    </tbody>
  </v-table>
</v-row>
<v-row style="margin-bottom: 20px" v-if="vertabelaAlunosPlanos">
  <v-table class="table table-striped table-bordered" style="width: 100%">
    <thead>
      <tr>
        <th class="text-left">
          Nome
        </th>
        <th class="text-left">
          Dia Pagamento
        </th>
        <th class="text-left">
          Plano
        </th>
        <th class="text-left">
          Modalidade
        </th>
         <th class="text-left">
          Valor unitário
        </th>
        <th class="text-left">
          Editar
        </th>
      </tr>
   </thead>
   <tbody>
      <tr v-for="item in tabelaAlunosPlanos.desserts" :key="item.id">
        <td>{{item.nomeAluno}}</td>
        <td>{{item.diaPagamento}}</td>
        <td>{{item.nomePlano}}</td>
        <td>{{item.nomeMateria}}</td>
        <td>{{item.valorUnitario}}</td>
        <td><v-btn variant="outline" @click="editarPlanodoAluno(item)">Editar</v-btn></td>
      </tr>
    </tbody>
  </v-table>
</v-row>
<v-row style="margin-bottom: 20px" v-if="vertabelaPlanos">
   <v-btn density="default" variant="outline" @click="dialogCriarPlano = true">Adicionar Plano</v-btn>
  <v-table class="table table-striped table-bordered" style="width: 100%">
    <thead>
      <tr>
        <th class="text-left">
          Nome
        </th>
        <th class="text-left">
          Descricao
        </th>
        <th class="text-left">
          Valor Unitario
        </th>
        <th class="text-left">
          Editar
        </th>
      </tr>
   </thead>
   <tbody>
      <tr v-for="item in tabelaPlanos.desserts" :key="item.id">
        <td>{{item.nome}}</td>
        <td>{{item.descricao}}</td>
        <td>{{item.valorUnitario}}</td>
        <td><v-btn variant="outline" @click="editarPlano(item)">Editar</v-btn></td>
      </tr>
    </tbody>
  </v-table>
</v-row>


<v-row justify="center">
    <v-dialog
      v-model="dialogCriarPlano"
      width="1024"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Adicionar Plano</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nome*"
                  v-model="criarNome"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Descrição"
                  v-model="criarDescricao"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
            
                <v-text-field
                  label="Valor unitario*"
                  hint="o valor que ficará por aluno"
                  
                  v-model="criarValorUnitario" v-money="money"
                  persistent-hint
                  required
                ></v-text-field> 
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogCriarPlano = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="criarPlano()"
          >
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

<v-row justify="center">
    <v-dialog
      v-model="dialogCriarDiverso"
      width="1024"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Adicionar Produto/Serviço</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nome/Serviço*"
                  v-model="criarNomeDiverso"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Descrição"
                  v-model="criarDescricaoDiverso"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
            
                <v-text-field
                  label="Valor*"
                  hint=""
                  
                  v-model="criarValorDiverso" v-money="money"
                  persistent-hint
                  required
                ></v-text-field> 

                <v-text-field
                  label="Data*"
                  hint="A data de entrada do valor"
                  
                  v-model="criarDataDiverso"
                  v-mask="'##/##/####'"
                  persistent-hint
                  required
                ></v-text-field> 
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogCriarDiverso = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="criarDiverso()"
          >
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>


<v-row justify="center">
    <v-dialog
      v-model="dialogEditarPlano"
      width="1024"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Editar Plano 
          <v-btn variant="outline" @click="deletarPlanoDeFato()">Excluir</v-btn>
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nome*"
                  v-model="editarNome"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Descrição"
                  v-model="editarDescricao"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
            
                <v-text-field
                  label="Valor unitario*"
                  hint="o valor que ficará por aluno"
                  
                  v-model="editarValorUnitario" v-money="money"
                  persistent-hint
                  required
                ></v-text-field> 
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogEditarPlano = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="editarPlanoDeFato()"
          >
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>


<v-row justify="center">
    <v-dialog
      v-model="dialogEditarPlanoAluno"
      width="1024"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{editarPlanoAlunoNome}}
          </span>
          <div>na matéria {{editarPlanoAlunoMateria}}</div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete
                  v-model="selecionarAddPlanoAluno"
                  :items="tabelaPlanos.desserts"
                  item-title="nome"
                  item-value="id"
                  label="Selecione o plano"
                  persistent-hint
                  return-object
                  single-line
                ></v-autocomplete>

              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                 v-if="carregarInputs"
                  v-model="visualizarValorPlano"
                  label="Valor unitario*"
                  hint="o valor que ficará por aluno"
                  disabled="true"
                  v-money="money"
                  persistent-hint
                  required
                ></v-text-field> 
              </v-col>

              <v-col
              v-if="carregarInputs"
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                v-model="visualizarDescricao"
                  label="Descricao"
                  disabled="true"
                ></v-text-field> 
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogEditarPlanoAluno = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="addPlanoNoAluno()"
          >
            Editar Plano
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>


    </div>
          
    </div>
  </div>
</template>
  
<script>


import {func} from '../assets/functions.js';
import { VDataTable } from 'vuetify/labs/VDataTable';
import {VMoney} from 'v-money'

  export default {
    name: 'Contas',
    components:{ 
        VDataTable
    },
    props: {
      
    },
    async created(){
    },
    data() {
      return {
        materiasProfessor: [],
        valorAReceber: 0,
        valorRecebido: 0,
        valorTotal: 0,
        valorDiversos: 0,
        alunosGeral: [],
        diversosGeral: [],
        alunosPagaram: [],
        alunosNaoPagaram: [],

        selecionarAddPlanoAluno: null,
        visualizarValorPlano: null,
        visualizarDescricao: null,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        vertabelaReceber: false,
        vertabelaRecebido: false,
        verTabelaDiversos: false,

        vertabelaSemPlano: false,
        vertabelaAlunosPlanos: false,
        vertabelaPlanos: false,
        dialogCriarPlano: false,
        dialogEditarPlano: false,
        dialogEditarPlanoAluno: false,
        dialogCriarDiverso: false,
        criarNome: null,
        criarDescricao: null,
        criarValorUnitario: null,

        criarNomeDiverso: null,
        criarDescricaoDiverso: null,
        criarValorDiverso: null,
        criarDataDiverso:  null,


        editarId: null,
        editarNome: null,
        editarDescricao: null,
        editarValorUnitario: null,

        editarPlanoAlunoId: null,
        editarPlanoMateriaId: null,
        editarPlanoAlunoNome: null,
        editarPlanoAlunoMateria: null,

        adicionandoPlanoNoAluno: false,
        editandoPlanoNoAluno: false,

        carregarInputs: true,
        snackbar: false,
        text: null,
        tabelaReceber: {
          sortBy: [{ key: 'nome', order: 'asc' }],
          headers: [
            {
              title: 'Aluno',
              align: 'start',
              sortable: true,
              key: 'nomeAluno',
            },
            { title: 'Plano', key: 'nomePlano' },
            { title: 'Valor Individual', key: 'valorUnitario' },
            { title: 'Dia Pagamento', key: 'diaPagamento' },
          ],
          desserts: [],
        },
        tabelaRecebido: {
          sortBy: [{ key: 'nome', order: 'asc' }],
          headers: [
            {
              title: 'Aluno',
              align: 'start',
              sortable: true,
              key: 'nomeAluno',
            },
            { title: 'Plano', key: 'nomePlano' },
            { title: 'Valor Individual', key: 'valorUnitario' },
            { title: 'Dia Pagamento', key: 'diaPagamento' },
          ],
          desserts: [],
        },
        tabelaDiversos: {
          sortBy: [{ key: 'data', order: 'asc' }],
          headers: [
            { title: 'Produto/Serviço', key: 'nome' },
            { title: 'Descrição', key: 'descricao' },
            {
              title: 'Valor',
              align: 'start',
              sortable: true,
              key: 'valor',
            },
            { title: 'Data', key: 'data' },
          ],
          desserts: [],
        },

         tabelaSemPlano: {
          desserts: []
        },
        tabelaAlunosPlanos: {
          desserts: [],
        },
         tabelaPlanos: {
          desserts: [],
        },
        materiaSelecionada: null,
        materiaSelecionadaNome: 'Todas'
      }
    },
    mounted: function () {
      this.getPlanos();
      this.getAlunos();
      this.getMateriasProfessor();
    },
     watch: { 
        selecionarAddPlanoAluno: function(value) {
            this.carregarValorDescPlano(value);
        }
    },
    methods: {
      selecionarMateria(id, nome){
        this.materiaSelecionada = id;
        this.materiaSelecionadaNome = nome
        this.getAlunos()
      },
      getMateriasProfessor(){
         func.CarregarDados('professor/getMateriasProfessor').then(data => 
          this.materiasProfessor = data.corpo
        );
      },
      getAlunos(){
        var payload = {
          idMateria: this.materiaSelecionada
        }
        func.CarregarDados('planos/getAlunos', payload).then(data => 
          this.carregarVariaveisAlunos(data)
        );
      },
      getDiversos(){
        func.CarregarDados('planos/getDiversos').then(data => 
          this.carregarVariaveisDiversos(data)
        );
      },
      addPlanoNoAluno(){
        this.dialogEditarPlanoAluno = false;
        if(this.adicionandoPlanoNoAluno){
          var post = {
            idAluno:  this.editarPlanoAlunoId,
            idPlano:  this.selecionarAddPlanoAluno.id,
            idMateria:  this.editarPlanoMateriaId,
          }
          func.CarregarDados('planos/addPlanoNoAluno', post).then(data => 
            this.getAlunos()
          );
        }
        if(this.editandoPlanoNoAluno){
          var post = {
            idAluno:  this.editarPlanoAlunoId,
            idPlano:  this.selecionarAddPlanoAluno.id,
          }
          func.CarregarDados('planos/editarPlanoNoAluno', post).then(data => 
            this.getAlunos()
          );
        }
      },
      carregarValorDescPlano(value){
          this.visualizarValorPlano = value.valorUnitario
          this.visualizarDescricao = value.descricao
      },  
      addPlanodoAluno(aluno){
        this.editarPlanoAlunoId = aluno.id;
        this.editarPlanoMateriaId = aluno.idMaterias;
        
        this.editarPlanoAlunoNome = aluno.nomeAluno;
        this.editarPlanoAlunoMateria = aluno.nomeMateria;

        this.adicionandoPlanoNoAluno = true;
        this.editandoPlanoNoAluno = false;
       
        this.carregarInputs = true;
        this.dialogEditarPlanoAluno = true;
      },
      editarPlanodoAluno(aluno){
        this.editarPlanoAlunoId = aluno.id;
        this.editarPlanoAlunoNome = aluno.nomeAluno;
        
        this.carregarInputs = false
        this.selecionarAddPlanoAluno = {
          id: aluno.idPlano,
          nome: aluno.nomePlano
        };

        this.adicionandoPlanoNoAluno = false;
        
        this.editandoPlanoNoAluno = true;
        this.dialogEditarPlanoAluno = true;
      },
      carregarVariaveisAlunos(data, diversos){
       
        this.alunosGeral = data.corpo;
       
        this.alunosPagaram = this.alunosGeral.filter(x => x.idPlano != null && x.pagou > 0);
        this.tabelaRecebido.desserts = this.alunosPagaram;
        
        this.alunosNaoPagaram = this.alunosGeral.filter(x => x.idPlano != null && x.pagou == 0)
        this.tabelaReceber.desserts  = this.alunosNaoPagaram;
        
        this.tabelaSemPlano.desserts = this.alunosGeral.filter(x => x.idPlano == null);
        this.tabelaAlunosPlanos.desserts = this.alunosGeral.filter(x => x.idPlano != null);

        this.valorAReceber = 0;
        for(var i = 0; i < this.alunosNaoPagaram.length; i++){
          this.valorAReceber = Number(this.valorAReceber) + Number(this.alunosNaoPagaram[i].valorUnitario)
        }
        this.valorRecebido = 0;
        for(var i = 0; i < this.alunosPagaram.length; i++){
          this.valorRecebido = Number(this.valorRecebido) + Number(this.alunosPagaram[i].valorUnitario)
        }

        this.getDiversos();
      },
      carregarVariaveisDiversos(data){
        var valor = data.corpo;
        this.valorDiversos = 0;
        for(var i = 0; i < valor.length; i++){
          this.valorDiversos = Number(this.valorDiversos) + Number(valor[i].valor)
          valor[i].data = valor[i].data.split('T')[0].replace('-','/').replace('-','/')
        }
         this.tabelaDiversos.desserts = valor;
      },
      deletarPlanoDeFato(){
        this.dialogEditarPlano = false;
        var post = {
          id: this.editarId,
        }
        func.CarregarDados('planos/deletePlano', post).then(data => 
          this.verTabelas('planosdisponiveis')
        );
      },
      editarPlanoDeFato(){
        var post = {
          id: this.editarId,
          nome: this.editarNome,
          descricao: this.editarDescricao,
          valorUnitario: this.editarValorUnitario.replace('R$ ','').replace(',', '.')
        }
        var erros = func.NovoVerificarJSONNull(post, []);
        if(erros.length > 0){
           this.text = erros;
           this.snackbar = true;
           return
        }
        this.dialogEditarPlano = false;
        func.CarregarDados('planos/alterplano', post).then(data => 
          this.verTabelas('planosdisponiveis')
        );
      },
      editarPlano(plano){
        var plano = this.tabelaPlanos.desserts.filter(x => x.id == plano.id)[0];
        this.dialogEditarPlano = true;
        this.editarId =  plano.id;
        this.editarNome = plano.nome;
        this.editarDescricao = plano.descricao
        this.editarValorUnitario = plano.valorUnitario
      },
      getPlanos(){
        func.CarregarDados('planos/getPlanos').then(data => 
          this.carregarVariaveisPlanos(data.corpo)
        );
      },
      carregarVariaveisPlanos(data){
        this.tabelaPlanos.desserts = data
      },
      criarPlano(){
        var post = {
          nome: this.criarNome,
          descricao: this.criarDescricao,
          valorUnitario: this.criarValorUnitario.replace('R$ ','').replace(',', '.')
        }
        var erros = func.NovoVerificarJSONNull(post, []);
        if(erros.length > 0){
           this.text = erros;
           this.snackbar = true;
           return
        }
        func.CarregarDados('planos/addPlano', post).then(data => 
          this.verTabelas('planosdisponiveis')
        );
      },
      criarDiverso(){
          var post = {
            nome: this.criarNomeDiverso,
            descricao: this.criarDescricaoDiverso,
            valor: this.criarValorDiverso.replace('R$ ','').replace(',', '.'),
            data: func.formatarDataPadraoBD(this.criarDataDiverso, false)
          }
          var erros = func.NovoVerificarJSONNull(post, ['nome', 'valor', 'data']);
          if(erros.length > 0){
            this.text = erros;
            this.snackbar = true;
            return
          }
          func.CarregarDados('planos/addDiverso', post).then(data => 
            this.verTabelas('diversos')
          );
      },
      verTabelas(tabela){
        this.dialogCriarPlano = false;
        this.getAlunos();
        
        this.vertabelaReceber = false;
        this.vertabelaRecebido = false;
        this.verTabelaDiversos = false;
        this.vertabelaSemPlano = false;
        this.vertabelaAlunosPlanos = false;
        this.vertabelaPlanos = false;

        if(tabela == 'receber'){
          this.vertabelaReceber = true;
        }
        if(tabela == 'recebido'){
          this.vertabelaRecebido = true;
        }
        if(tabela == 'diversos'){
          this.getDiversos();
          this.verTabelaDiversos = true;
          this.dialogCriarDiverso = false;
        }
        if(tabela == 'semplano'){
          this.vertabelaSemPlano = true;
          
        }
        if(tabela == 'alunosplanos'){
          this.vertabelaAlunosPlanos = true;
        }
        if(tabela == 'planosdisponiveis'){
          this.vertabelaPlanos = true;
          this.getPlanos()
        }

      }
    }
  }

</script>


  