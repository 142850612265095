<template>
  <v-toolbar>
        <v-toolbar-title class="text-grey" style="text-align: left;">
          CheckIns
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
  <div  class="App-Conteudo" id="App-Conteudo" style="margin-top: 0px !important">
    <div class="App-Conteudo-Comp">
      <div class="App-Conteudo-Comp-Box">

         <v-btn class="font-weight-bold atlas-cta-wide cta-green my-3" color="primary" style="width: 100%; margin-top: 50px !important" v-on:click="this.$router.push('checkinsconf')">Adicionar CheckIN / Relatório</v-btn>

        <div class="filtrosMateria">
          <v-chip class="click" @click="filtrarMateria(0)">
            Todos ({{ checksFull.length }})
          </v-chip>&nbsp;&nbsp;

          <v-chip v-for="item in materiasAcademia" :key="item.id" class="click"  @click="filtrarMateria(item.id)">
            {{item.nome}} ({{ checksFull.filter(x => x.idMateria == item.id).length }})
          </v-chip>&nbsp;&nbsp;
        </div>
        <h5>Clique no dia para carregar o checkIns correspondente</h5><br/>
        <VCalendar expanded :columns="3"
          :initial-page="{ month: new Date().getMonth(), year: new Date().getFullYear() }"
          :attributes="attributes"
          @dayclick="clicouData"
        />

        <v-table style="margin-bottom: 100px">
          <thead>
            <tr>
              <th class="text-left">
                
              </th>
              <th class="text-left">
                Matricula
              </th>
              <th class="text-left">
                Nome
              </th>
              <th class="text-left">
                Aula
              </th>
              <th class="text-left">
                Dia
              </th>
              <th class="text-left">
                Horario
              </th>
              <th class="text-left">
                Data do Check
              </th>
              <th class="text-left">
                
              </th>
            </tr>
          </thead>
          <tbody>
            <tr  v-for="item in checks" :key="item.id">
              <td><div :style="{'background-image':'url('+item.foto+')'}" class="divAvatar"></div></td>
              <td>{{item.matricula}}</td>
              <td>{{item.nomeAluno}}</td>
              <td>{{item.nomeMateria}}</td>
              <td>{{item.diaSemana}}</td>
              <td>{{item.horario}}</td>
              <td>{{formatarData(item.dataCheck, true)}}</td>
              <td><v-btn @click="excluirCheck(item)" color="primary">Excluir</v-btn></td>
            </tr>
          </tbody>
        </v-table>
      </div>
          
    </div>
  </div>
</template>
  
<script>


import {func} from '../assets/functions.js';
  export default {
    name: 'CheckIns',
    components:{ 
      
    },
    props: {
      
    },
    async created(){

    },
    data() {
      return {
        diaClicado: null,
        materiasAcademia: [],
        checksFull: [],
        checks: [],
        attributes: []
      }
    },
    mounted: function () {
        this.carregarTela()
    },
    methods: {
      excluirCheck(idCheck){
        var post = {
            idCheck: idCheck.id
        }
        func.CarregarDados('checkIns/deleteCheckIN', post).then(data => 
          this.clicouData(this.diaClicado)
        );

      },
      clicouData(event){
        this.diaClicado = event;
        var post = {
          dia: event.id
        }
        func.CarregarDados('checkins/getCheckInsDia', post).then(data => 
          this.carregarComponentes(data)
        );
      },
      filtrarMateria(materia){
        if(materia > 0){
          this.checks = this.checksFull.filter(x => x.idMateria == materia);
        }
        else{
          this.checks = this.checksFull
        }
        this.mostrarComponentes(this.materiasAcademia)
      },
      carregarTela(){
        
      },
      formatarData(data, comHora){
        return func.formatarDataPadraoBR(data, comHora);
      },
      congruente(dados){
        this.materiasAcademia = dados;
      },
      carregarComponentes(dados){
        this.checksFull = dados.corpo;
        this.checks = dados.corpo;
        console.log(this.checks )
        var novasdatas = [];
        
        func.CarregarDados('professor/getMateriasProfessor').then(data => 
          this.mostrarComponentes(data.corpo)
        );
        
      },

      mostrarComponentes(materiasAcademia){
        this.materiasAcademia = materiasAcademia
        //agrupar datas
        var datas = [];
        for(var key in this.checks) {
          var dataFormatada = func.formatarDataPadraoBR(this.checks[key].dataCheck, false);
          if(datas.filter(x => x == dataFormatada).length == 0 ){
            datas.push(dataFormatada)
          }
        }

        var dadosFinais = [];
        
        for(var data in datas) {
          var qtdTotalPorData = this.checks.filter(x => func.formatarDataPadraoBR(x.dataCheck, false) == datas[data]).length;

          for(var materia in materiasAcademia) {
            var dataMateria = datas[data];
            var idMateria = materiasAcademia[materia].id;
            var nomeMateria = materiasAcademia[materia].nome;
            var qtdPorMateriaNaData = this.checks.filter(x => x.idMateria == idMateria && func.formatarDataPadraoBR(x.dataCheck, false) == datas[data]).length

            var ano = dataMateria.substring(10, 6)
            var mes = dataMateria.substring(3, 5)
            var dia = dataMateria.substring(0, 2)
            
            var dadoInserir = {
              dates: new Date(ano, mes - 1, dia),
              highlight: true,
              popover: {
                label: nomeMateria + " - " + qtdPorMateriaNaData,
              },
            }
            dadosFinais.push(dadoInserir)
          }
        }

        this.attributes = dadosFinais;
        
        // for(var key in materiasAcademia) {
        //   var idMateria = materiasAcademia[key].id;
        //   var qtdChecks = 0;
        //   var nomeMateria = materiasAcademia[key].nome
          
        //   for(var key in this.checks.filter(x => x.idMateria == idMateria)) {
        //     qtdChecks = qtdChecks + 1;
        //     //var textoCheck = this.checks.filter(x => x.idMateria == idMateria)[key].nome + " " + qtdChecks;
        //   }

        //   var dado = {
        //       dates: new Date(ano, mes, dia),
        //       highlight: true,
        //       popover: {
        //         label: textoCheck,
        //       },
        //   }
        // }


        // for(var key in this.checks) {
        //   var ano = new Date(this.checks[key].dataCheck).getFullYear();
        //   var mes = new Date(this.checks[key].dataCheck).getMonth();
        //   var dia = new Date(this.checks[key].dataCheck).getDate();

        //   var dado = {
        //     dates: new Date(ano, mes, dia),
        //     highlight: true,
        //     popover: {
        //       label: this.checks[key].nome,
        //     },
        //   }
        //   novasdatas.push(dado)
        // }
        // this.attributes = novasdatas;

      }
    }
  }

</script>
  

  